import { HttpClient, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiService, Appointment, FormFastConfig, FormFastConfigService, Patient, TokenService } from "@next/shared/common";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
  })
export class PatientService extends ApiService {

    constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenService: TokenService, private http: HttpClient) {
        super(config, tokenService);
    }

    sendVerificationCode(encryptedEmail: string): Observable<any> {
        const req = new HttpRequest('POST', `${this.config.apiUrl}patient/token`, {email: encryptedEmail}, this.getHeaders());
        return this.protectedEndpoint<any>(this.http.request(req));
      }

    verifyCode(encryptedEmail: string, verificationCode: string): Observable<any> {
      return this.protectedEndpoint<any>(
        this.http.get<any>(`${this.config.apiUrl}patient/verificationcode/${verificationCode}?email=${encryptedEmail}`, this.getHeaders()));
    }

    verifypatient(encryptedEmail: string, lastFourSSN: string, birthDate: string): Observable<any> {
      return this.protectedEndpoint<any>(
        this.http.get<any>(`${this.config.apiUrl}patient/verify?email=${encryptedEmail}&lastFourSSN=${lastFourSSN}&birthDate=${birthDate}`, this.getHeaders()));
    }

    getPatientAppointments(patientId: string): Observable<Appointment[]> {
      return this.protectedEndpoint<Appointment[]>(
        this.http.get<Appointment[]>(`${this.config.apiUrl}appointment/patient/${patientId}`, this.getHeaders()));
    }

    getPatient(): Observable<Patient> {
      return this.protectedEndpoint<Patient>(
        this.http.get<Patient>(`${this.config.apiUrl}patient/token`, this.getHeaders()));
    }
}
