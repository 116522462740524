import { Inject, Injectable } from '@angular/core';
import {
  ApiService, CollectionDTO, CollectionJunctionDTO,
  FormFastConfig,
  FormFastConfigService,
  GuidedExperienceDTO,
  NextGuidedExperienceMetaData,
  TokenService
} from '@next/shared/common';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NextExperienceService extends ApiService {

  constructor(@Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService, private http: HttpClient) { super(config, tokenService); }

  getExperiences(): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any[]>(`${this.config.apiUrl}experience/draft`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: result.formId || '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          pdftemplateid: result.pdftemplateid,
          embedAttachments: false,
          sendToSuccessPage: false,
          signAllStaff: false,
          publishedon: result.publishedon,
          status: result.status,
          tags: result.tags,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: '',
          onSave: '',
          onSubmit: '',
          pages: [],
          pdfPages: []
        } as GuidedExperienceDTO
      }))
    );
  }

  getExperiencesPublished(): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any[]>(`${this.config.apiUrl}experience/active`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: result.formId || '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          pdftemplateid: result.pdftemplateid,
          embedAttachments: false,
          sendToSuccessPage: false,
          signAllStaff: false,
          publishedon: result.publishedon,
          status: result.status,
          tags: result.tags,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: result.onLoad || '',
          onSave: result.onSave || '',
          onSubmit: result.onSubmit || '',
          pages: [],
          pdfPages: []
        } as GuidedExperienceDTO
      }))
    );
  }

  getExperience(experienceId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/active?$expand=pdftemplate`, this.getHeaders())
    ).pipe(
      map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          embedAttachments: result.data?.settings?.embedAttachments || false,
          sendToSuccessPage: result.data?.settings?.sendToSuccessPage || false,
          signAllStaff: result.data?.settings?.signAllStaff || false,
          onLoad: (result.data?.calculations) ? result.data.calculations.onLoad : '',
          onSave: (result.data?.calculations) ? result.data.calculations.onSave : '',
          onSubmit: (result.data?.calculations) ? result.data.calculations.onSubmit : '',
          pages: result.data?.pages,
          pdfPages: result.data?.pdfPages
        } as GuidedExperienceDTO
      })
    );
  }

  getAllExperienceVersions(experienceId: string): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/version`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          embedAttachments: false,
          publishedon: result.publishedon,
          status: result.status,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: '',
          onSave: '',
          onSubmit: '',
          pages: [],
          pdfPages: []
        } as GuidedExperienceDTO
      }))
    )
  }

  getExperienceVersion(experienceId: string, versionId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/version/${versionId}?$expand=pdftemplate`, this.getHeaders())
    ).pipe(
      map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          logoUrl: result.data.header.logo.url,
          logoHeight: result.data.header.logo.height,
          logoAlignment: result.data.header.logo.alignment,
          embedAttachments: result.data.settings?.embedAttachments || false,
          sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
          signAllStaff: result.data.settings?.signAllStaff || false,
          onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
          onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
          onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
          pages: result.data.pages,
          pdfPages: result.data.pdfPages,
          pdftemplate: result.pdftemplate
        } as GuidedExperienceDTO
      })
    );
  }

  getExperienceWithMetadata(experienceId: string, versionId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/version/${versionId}/metadata`, this.getHeaders())
    ).pipe(
      map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          logoUrl: result.data.header.logo.url,
          logoHeight: result.data.header.logo.height,
          logoAlignment: result.data.header.logo.alignment,
          embedAttachments: result.data.settings?.embedAttachments || false,
          sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
          signAllStaff: result.data.settings?.signAllStaff || false,
          onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
          onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
          onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
          pages: result.data.pages,
          pdfPages: result.data.pdfPages,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadata: result.metadata
        } as GuidedExperienceDTO
      })
    );
  }

  saveExperience(experience: GuidedExperienceDTO, metadata: NextGuidedExperienceMetaData) {
    const pdfTemplateId = (metadata.sources && metadata.sources.length > 0) ? metadata.sources[0].id : null;
    const convertedExperience = {
      id: experience.id,
      versionid: experience.vid,
      designerversionid: experience.designerversionid,
      configversion: experience.configversion,
      name: experience.name,
      description: experience.description,
      pdftemplateid: pdfTemplateId,
      data: {
        header: {
          logo: {
            url: experience.logoUrl,
            height: experience.logoHeight,
            alignment: experience.logoAlignment
          }
        },
        calculations: {
          onLoad: experience.onLoad,
          onSave: experience.onSave,
          onSubmit: experience.onSubmit
        },
        settings: {
          embedAttachments: experience.embedAttachments,
          sendToSuccessPage: experience.sendToSuccessPage,
          signAllStaff: experience.signAllStaff
        },
        pages: experience.pages,
        pdfPages: experience.pdfPages
      },
      metadata
    };

    if (experience.id && experience.id !== '') {
      return this.protectedEndpoint<any>(
        this.http.put<any>(
          `${this.config.apiUrl}experience/${experience.id}/version/${experience.vid}`, convertedExperience, this.getHeaders())
      ).pipe(
        map(result => {
          return {
            annotations: [],
            description: result.description,
            formId: '',
            id: result.id,
            vid: result.versionid,
            configversion: result.configversion,
            name: result.name,
            embedAttachments: result.data.settings?.embedAttachments || false,
            sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
            signAllStaff: result.data.settings?.signAllStaff || false,
            onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
            onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
            onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
            pages: result.data.pages,
            pdfPages: result.data.pdfPages
          } as GuidedExperienceDTO
        })
      );
    } else {
      return this.protectedEndpoint<any>(
        this.http.post<any>(`${this.config.apiUrl}experience`, convertedExperience, this.getHeaders())
      ).pipe(
        map(result => {
          return {
            annotations: [],
            description: result.description,
            formId: '',
            id: result.id,
            vid: result.versionid,
            configversion: result.configversion,
            name: result.name,
            embedAttachments: result.data.settings?.embedAttachments || false,
            sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
            signAllStaff: result.data.settings?.signAllStaff || false,
            onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
            onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
            onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
            pages: result.data.pages,
            pdfPages: result.data.pdfPages
          } as GuidedExperienceDTO
        })
      );
    }
  }

  publishExperience(experienceId: string, experienceVersionId: string) : Observable<GuidedExperienceDTO>{

    // first, publish the experience version
    const publish = this.protectedEndpoint<any>(this.http.post<any>(`${this.config.apiUrl}experience/${experienceId}/version/${experienceVersionId}/activate`, this.getHeaders()));

    // then copy into a new draft experience
    const copy = this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}experience/${experienceId}/version/${experienceVersionId}/copy`, this.getHeaders())
    ).pipe(
      map(resultCopy => {
        return {
          annotations: [],
          description: resultCopy.description,
          formId: '',
          id: resultCopy.id,
          vid: resultCopy.versionid,
          configversion: resultCopy.configversion,
          name: resultCopy.name,
          embedAttachments: resultCopy.data.settings?.embedAttachments || false,
          sendToSuccessPage: resultCopy.data.settings?.sendToSuccessPage || false,
          signAllStaff: resultCopy.data.settings?.signAllStaff || false,
          onLoad: (resultCopy.data.calculations) ? resultCopy.data.calculations.onLoad : '',
          onSave: (resultCopy.data.calculations) ? resultCopy.data.calculations.onSave : '',
          onSubmit: (resultCopy.data.calculations) ? resultCopy.data.calculations.onSubmit : '',
          pages: resultCopy.data.pages,
          pdfPages: resultCopy.data.pdfPages
        } as GuidedExperienceDTO
      })
    );

    return forkJoin([publish, copy]).pipe(
      map((result) => {
        return result[1];
      })
    );
  }

  deleteExperience(expId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<GuidedExperienceDTO>(
      this.http.delete<GuidedExperienceDTO>(`${this.config.apiUrl}experience/${expId}`, this.getHeaders())
    );
  }

  buildFolders(treeNode: any[]) {
    return this.protectedEndpoint(
      this.http.post(`${this.config.apiUrl}collection/folders/`, treeNode, this.getHeaders())
    );
  }

  buildPackets(treeNode: any[]) {
    return this.protectedEndpoint(
      this.http.post(`${this.config.apiUrl}collection/packets/`, treeNode, this.getHeaders())
    );
  }

  getCollectionBundle(): Observable<[CollectionDTO[], CollectionJunctionDTO[], GuidedExperienceDTO[]]> {
    return this.protectedEndpoint<[CollectionDTO[], CollectionJunctionDTO[], GuidedExperienceDTO[]]>(
      this.http.get<[CollectionDTO[], CollectionJunctionDTO[], GuidedExperienceDTO[]]>(`${this.config.apiUrl}collection/bundle`, this.getHeaders())
    );
  }

  getCategories(): Observable<CollectionDTO[]> {
    return this.protectedEndpoint<CollectionDTO[]>(
      this.http.get<CollectionDTO[]>(`${this.config.apiUrl}collection/`, this.getHeaders())
    );
  }

  deleteJunctionsByExperienceId(eid: string): Observable<any>{
    return this.protectedEndpoint<any>(
      this.http.delete<any>(`${this.config.apiUrl}collection/assignments/experience/${eid}`, this.getHeaders())
    );
  }

  assignCategory(junctions): Observable<CollectionJunctionDTO[]>{
    return this.protectedEndpoint<CollectionJunctionDTO[]>(
      this.http.post<CollectionJunctionDTO[]>(`${this.config.apiUrl}collection/assignments/experience`, junctions, this.getHeaders())
    );
  }

  assignToCollection(junctions): Observable<any>{
    return this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}collection/assignments/collection`, junctions, this.getHeaders())
    );
  }
}
