import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '@next/shared/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'next-tag-pill',
  templateUrl: './tag-pill.component.html',
  styleUrls: ['./tag-pill.component.css'],
  animations: [ trigger('fadeInOut',[
    transition(':enter',
      [ style({opacity: 0, height: 0 }), animate('200ms ease-in-out', style({opacity: '*', height: '*' }))]),
    transition(':leave',
      [ style({opacity: '*', height: '*' }), animate('200ms ease-in-out', style({opacity: 0, height: 0 }))])
  ])
  ],
})

export class TagPillComponent {

  @Input() tags: Tag[];
  @Output() remove: EventEmitter<Tag> = new EventEmitter<Tag>();

  removeTag(tag: Tag): void {
    this.remove.emit(tag);
  }
}
