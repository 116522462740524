import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Appointment, Patient, EnterpriseSettings, NotificationSettings, Facility } from '@next/shared/common';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  /**
   * Patients State
   * @property patientFilter - filter string in the search input
   * @property patients - array of patients retrieved from search
   * @private
   */
  private readonly _patientFilter = new BehaviorSubject<string>('');
  readonly patientFilter$ = this._patientFilter.asObservable();

  private readonly  _selectedFacility = new BehaviorSubject<Facility>({isactive:false});
  readonly selectedFacility$ = this._selectedFacility.asObservable();

  private readonly _patients = new BehaviorSubject<any[]>([]);
  readonly patients$ = this._patients.asObservable();

  private readonly _appointments = new BehaviorSubject<Appointment[]>([]);
  private readonly _appointmentsFilteredByDate = new BehaviorSubject<Appointment[]>([]);

  /**
   * Enterprise setting state
   * @property enterpriseSettings - enterprise settings that can be changed within the admin settings
   */
  private readonly _enterpriseSettings = new BehaviorSubject<EnterpriseSettings>({} as EnterpriseSettings);
  readonly enterpriseSettings$ = this._enterpriseSettings.asObservable();

 /**
   * Notification Setting state
   * @property notificationSetting - notificationSetting settings that can be changed within the notificationSetting
   */
  private readonly _notificationSetting = new BehaviorSubject<NotificationSettings>({} as NotificationSettings);
  readonly notificationSetting$ = this._notificationSetting.asObservable();

  /**
   * Forms Library State
   * @property selectedExperiences - experiences selected in the form library
   */
  private readonly _selectedExperiences = new BehaviorSubject<any[]>([]);
  readonly selectedExperiences$ = this._selectedExperiences.asObservable();

  private readonly _filteredExperiences = new BehaviorSubject<any[]>([]);

  get patientFilter(): string {
    return this._patientFilter.getValue();
  }

  set patientFilter(val: string) {
    this._patientFilter.next(val);
  }

  get patients(): Patient[] {
    return this._patients.getValue();
  }

  set patients(val: Patient[]) {
    this._patients.next(val);
  }

  get appointments(): Appointment[] {
    return this._appointments.getValue();
  }

  set appointments(val: Appointment[]) {
    this._appointments.next(val);
  }

  get appointmentsFilteredByDate(): Appointment[] {
    return this._appointmentsFilteredByDate.getValue();
  }

  set appointmentsFilteredByDate(val: Appointment[]) {
    this._appointmentsFilteredByDate.next(val);
  }

  clearPatientStore() {
    this.patientFilter = '';
    this.patients = [];
    this.appointments = [];
    this.appointmentsFilteredByDate = [];
  }

  get selectedExperiences(): any[] {
    return this._selectedExperiences.getValue();
  }

  set selectedExperiences(val: any[]) {
    this._selectedExperiences.next(val);
  }

  get filteredExperiences(): any[] {
    return this._filteredExperiences.getValue();
  }

  set filteredExperiences(val: any[]) {
    this._filteredExperiences.next(val);
  }

  clearExperiencesStore() {
    this.selectedExperiences = [];
  }

  get enterpriseSettings(): EnterpriseSettings {
    return this._enterpriseSettings.getValue();
  }

  set enterpriseSettings(settings : EnterpriseSettings) {
    this._enterpriseSettings.next(settings);
  }

  get notificationSetting(): NotificationSettings {
    return this._notificationSetting.getValue();
  }

  set notificationSetting(settings : NotificationSettings) {
    this._notificationSetting.next(settings);
  }

  get selectedFacility(){
    return this._selectedFacility.getValue();
  }

  set selectedFacility(facilityId : Facility) {
    this._selectedFacility.next(facilityId);
  }
}
