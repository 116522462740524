import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { StateViewerService } from '../state/state-viewer.service';
import { NextClinicalService } from '@next/shared/next-services';

@Injectable({
  providedIn: 'root',
})
export class IHOPPrefillResolverService {

  constructor (
    private clinicalSvc: NextClinicalService,
    private stateSvc: StateViewerService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Observable<never> {

    const experienceId = route.paramMap.get('experienceId');
    const assignToId = this.stateSvc.selectedTasks.find(task => task.assignto);

    if (assignToId) {
      const data = { };
      data['assignto'] = assignToId.assignto;

      return this.clinicalSvc.getPrefill(experienceId, route.queryParamMap, data).pipe(
        catchError(err => {
          // Swallow all errors.  Prefill is not that important, as to fail or alert anyone.
          if (err.status === 500) {
            console.log('Prefill failure: ' + err.message);
          }

          return of(null);
        })
      );
    }
    return of(null);
  }
}

