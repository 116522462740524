import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TaskDTO } from '@next/shared/common';
import { NextFormService } from '@next/shared/next-services';
@Component({
  selector: 'next-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.css']
})
export class FormViewComponent implements OnInit, OnDestroy {

  @ViewChild('iframe') iframe: ElementRef;
  @Input() target: TaskDTO;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  title = '';

  constructor(private formSvc: NextFormService) { }

  ngOnInit(): void {
    (<any>window).isEmbedded = true;

    this.title = this.target.name;
    const templateId = this.target.pdftemplateid;
    this.formSvc.getPdf(templateId).subscribe({
      next: (response) => {
        this.iframe.nativeElement.src = `assets/pdfjs/web/viewer.html?file=${encodeURIComponent(response.url)}`;
      },
      error: (err) => {
        console.dir(err);
      }
    });
  }

  ngOnDestroy(): void {
    (<any>window).isEmbedded = undefined;
  }

}
