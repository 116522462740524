<p-table
    [value]="dataset"
    [columns]="columnHeaders"
    [scrollable]="true"
    [scrollHeight]="'flex'">
    <ng-template pTemplate="header" let-columns>
      <tr>        
        <th *ngFor="let col of columns" [id]="col.field" class='text-overflow'>
          <div class="d-flex justify-content-between align-items-center">
            {{ col.header }}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
      <ng-container >
        <tr>
          <td *ngFor="let col of columns" class='text-overflow align-middle'>
            <span *ngIf="col.field !== 'rundate'">
              {{rowData[col.field]}}
            </span>
            <span *ngIf="col.field === 'rundate'">
              
              {{(rowData["rundate"] | date:'M/d/yy h:mm a') || rowData["rundate"] | date:'M/d/yy h:mm a'}}
            </span>

          </td> 
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptymessage" >
      <tr>
        <td class='text-overflow align-middle'></td>
      </tr>
    </ng-template>
  </p-table>