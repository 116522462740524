<div class="container-fluid">
    <div class="row chip-row align-items-center justify-content-start">
        <div *ngFor="let tag of tags; index as i" class="chip my-1 mx-1" [@fadeInOut]>
            <fa-icon class="tag-icon" [icon]="['fas', 'tags']" size='sm'></fa-icon>
            {{ tag.tag }}&nbsp;&nbsp;&nbsp;
            <fa-icon [icon]="['far', 'times-circle']" (click)="removeTag(tag)" class='cursor-pointer'></fa-icon>
        </div>
    </div>
</div>


