import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { FormFastConfig, FormFastConfigService, TokenService } from '@next/shared/common';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject(FormFastConfigService) private config: FormFastConfig, private authSvc: TokenService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const token = this.getAccessToken();

      if (token) {
        request = request.clone({
          setHeaders: {
            'x-access-token': token
          }
        });
      }

    } catch (err) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (token) {
        request = request.clone({
          setHeaders: {
            'x-access-token': token
          }
        });
      }
    }
    return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
  }

  getAccessToken(): string {
    return this.authSvc.getAccessToken();
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {

    switch(error.status) {
      case 403:
        // Wipe the token before we redirect
        this.authSvc.clear();

        this.router.navigateByUrl('/error');
        break;
      case 401:
        // TODO: Refactor this logic.  It mirrors auth.guard.ts::redirect(...)
        // eslint-disable-next-line no-case-declarations
        const params = new URLSearchParams(window.location.search);

        // Since we are redirecting them to log in, flush out any legacy token.
        this.authSvc.clear();

        // eslint-disable-next-line no-case-declarations
        const conn = params.get('connection');

        // Remove the token param from the url, since we may be adding one back
        params.delete('token');
        params.delete('connection');

        // Build our OAuth state, which contains a redirect url
        // eslint-disable-next-line no-case-declarations
        const state = {
          redirect: `${window.location.origin}${window.location.pathname}`,
        };

        // eslint-disable-next-line no-case-declarations
        const urlParams = params.toString();
        if (urlParams) {
          state.redirect += `?${urlParams}`;
        }

        // eslint-disable-next-line no-case-declarations
        const base64State = btoa(JSON.stringify(state));

        // Redirect to Login page
        window.location.href = `${this.config.apiUrl}/auth/oauth/authorize?connection=${conn}&state=${base64State}`;
        break;
    }

    return throwError(error);
  }
}
