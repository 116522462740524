<span *ngIf='src === "ih-table"'>
  <svg width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <clipPath id="clip-Icon-FlatFormListsView">
        <rect width="100%" height="100%"/>
      </clipPath>
    </defs>
    <g id="Icon-FlatFormListsView" clip-path="url(#clip-Icon-FlatFormListsView)">
      <g id="Icon_CategoryFormListView" data-name="Icon/CategoryFormListView" transform="translate(9 9.5)">
        <rect id="Rectangle_477" data-name="Rectangle 477" width="23" height="20" transform="translate(0 2.5)" fill="currentColor"/>
        <line id="Line_55" data-name="Line 55" x2="6" transform="translate(17)" fill="none" stroke="currentColor" stroke-width="2"/>
        <line id="Line_56" data-name="Line 56" x2="6" transform="translate(8.5)" fill="none" stroke="currentColor" stroke-width="2"/>
        <line id="Line_57" data-name="Line 57" x2="6" fill="none" stroke="currentColor" stroke-width="2"/>
      </g>
    </g>
  </svg>
</span>

<span *ngIf='src === "ih-menu"'>
  <svg width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <clipPath id="clip-Icon-CategoryFormListsView">
        <rect width="100%" height="100%"/>
      </clipPath>
    </defs>
    <g id="Icon-CategoryFormListsView" clip-path="url(#clip-Icon-CategoryFormListsView)">
      <g id="Icon_CategoryFormListView" data-name="Icon/CategoryFormListView" transform="translate(9 9)">
        <g id="Rectangle_466" data-name="Rectangle 466" fill="currentColor" stroke="currentColor" stroke-linejoin="round" stroke-width="2">
          <rect width="9" height="5" stroke="none"/>
          <rect x="1" y="1" width="7" height="3" fill="none"/>
        </g>
        <g id="Rectangle_444" data-name="Rectangle 444" transform="translate(0 6)" fill="currentColor" stroke="currentColor" stroke-linejoin="round" stroke-width="2">
          <rect width="9" height="5" stroke="none"/>
          <rect x="1" y="1" width="7" height="3" fill="none"/>
        </g>
        <g id="Rectangle_462" data-name="Rectangle 462" transform="translate(0 12)" fill="currentColor" stroke="currentColor" stroke-linejoin="round" stroke-width="2">
          <rect width="9" height="5" stroke="none"/>
          <rect x="1" y="1" width="7" height="3" fill="none"/>
        </g>
        <g id="Rectangle_463" data-name="Rectangle 463" transform="translate(0 18)" fill="currentColor" stroke="currentColor" stroke-linejoin="round" stroke-width="2">
          <rect width="9" height="5" stroke="none"/>
          <rect x="1" y="1" width="7" height="3" fill="none"/>
        </g>
        <g id="Rectangle_477" data-name="Rectangle 477" transform="translate(10)" fill="currentColor" stroke="currentColor" stroke-linejoin="round" stroke-width="2">
          <rect width="13" height="23" stroke="none"/>
          <rect x="1" y="1" width="11" height="21" fill="none"/>
        </g>
      </g>
    </g>
  </svg>
</span>
