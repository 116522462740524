<div class="container-fluid h-100" [formGroup]="manageTag">
  <div class="row d-flex px-0 pb-2 align-items-center modal-header justify-content-between">
    <div class='col-4'>
      <button id='manage-tags-cancel' class="btn btn-unstyled" (click)="onManageTagsExit()" role="button" type='reset'>
        {{ 'TAGS.MANAGE.CANCEL' | translate }}
      </button>
    </div>
    <div class="col-4 text-center">
      <h5>{{'TAGS.MANAGE.TITLE' | translate }}</h5>
    </div>
    <div class="col-4"></div>
    <div class='col-12 mt-4'>
      <div class="input-group search">
        <div class="input-group-prepend">
          <div class="input-group-text prepend-input">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </div>
        </div>
        <label for="tag-search"></label>
        <input
          id="tag-search"
          type="text"
          class="form-control"
          placeholder="{{'TAGS.MANAGE.PLACE_HOLDER' | translate}}"
          [formControlName]='"search"'/>
        <div class="input-group-append">
          <span class="input-group-text append-input">
            <fa-icon id='search-reset' role='button' *ngIf="manageTag.controls['search'].value"  [icon]="['far', 'times-circle']" (click)="manageTag.controls['search'].reset()" size='lg' class='cursor-pointer'></fa-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class='d-block row manage-tags-body'>
    <div class='container-fluid m-0 p-0 h-100'>
      <div class='row mx-2 mt-2 mb-0' [ngClass]='{"invisible" : mode !== TagMode.search}'>
        <div class='col-2'></div>
        <h5 class='col-7 align-self-end mb-1 pl-4'>{{ 'TAGS.HEADER.TAG' | translate }}</h5>
        <h5 class='col-3 align-self-end text-center mb-1'>{{ 'TAGS.HEADER.REFERENCE' | translate }}</h5>
      </div>

      <div *ngIf='mode === TagMode.search' class='mx-3 border-top border-bottom rounded scroll-area bg-light'>
        <div *ngIf='!formArray.controls.length' class='container justify-content-center align-items-center h-100'>
          <div class='row h-25 justify-content-center align-items-end'>
            <fa-icon [icon]='["fas", "exclamation-circle"]' [size]='"2x"' class='text-muted'></fa-icon>
          </div>
          <div class='row justify-content-center text-muted'>
            {{ 'TAGS.MANAGE.NONE' | translate }}
          </div>
        </div>
        <div *ngFor='let tag of formArray.controls | filter: "value.name":manageTag.controls["search"].value | sortBy: "asc":"tag"; let i = index' class='row m-2 align-items-center'>
          <div class='col-2 align-content-center'>
            <button id='{{ "manage-tag-" + i }}' class='btn btn-link' (click)='toggleActiveTag(tag.value)'>
              <fa-icon [icon]='tag.value.active ? ["fas", "check-circle"] : ["far", "circle"]' size='lg'></fa-icon>
            </button>
          </div>
          <div class='col-7 text-truncate align-content-center'>
            <fa-icon class='text-muted mr-2' [icon]='["fas", "tag"]'></fa-icon>{{ tag.value.name }}
          </div>
          <div class='col-3 text-right align-content-center'>
            {{ tag.value.count | number }}
          </div>
        </div>
      </div>

      <div *ngIf="mode === TagMode.edit" class='mx-3 border-top border-bottom rounded scroll-area bg-light'>
        <div *ngIf='!formArray.controls.length' class='container justify-content-center align-items-center h-100'>
          <div class='row h-25 justify-content-center align-items-end'>
            <fa-icon [icon]='["fas", "exclamation-circle"]' [size]='"2x"' class='text-muted'></fa-icon>
          </div>
          <div class='row justify-content-center text-muted'>
            {{ 'TAGS.MANAGE.NONE' | translate }}
          </div>
        </div>
        <div *ngFor='let tag of formArray.controls | filter: "value.name":manageTag.controls["search"].value; let i = index' [formGroup]='tag' class='row m-2 align-items-center'>
          <div class='col-3 d-flex justify-content-center align-content-center'>
            <button id='{{ "delete-tag-" + i }}' class='btn btn-link' type='button' (click)='deleteTag(tag.value, i)'>
              <fa-icon [icon]='["far", "trash-alt"]' size='lg'></fa-icon>
            </button>
            <button id='{{ "save-edit-" + i }}' class='btn btn-link' type='submit' (click)='saveTag(tag)' [disabled]='tag.invalid || tag.pristine'>
              <fa-icon [icon]='["far", "save"]' size='lg'></fa-icon>
            </button>
          </div>
          <div class='col-9 px-0 text-truncate'>
            <input
              id='{{ "edit-tag-name-" + i }}'
              type='text'
              class='form-control'
              value='{{ tag.value.name }}'
              [mask]='"Z{25}"'
              [pattern]='pattern["Z"].pattern'
              [patterns]='pattern'
              [maxLength]='25'
              [formControlName]='"name"'>
          </div>
        </div>
      </div>

      <div *ngIf="mode === TagMode.search" class="row mx-2 my-0 align-items-center footer-row">
        <div class="col-12 pb-1 px-0">
          <button id='submit' type="submit" class="btn primaryButton w-100" (click)="submit()">
            {{'TAGS.MANAGE.APPLY_TAG_TO_SELECTED_FORMS' | translate | uppercase }}
          </button>
        </div>
        <div class='col-12 pb-1 px-0'>
          <button id='edit-mode-enable' type="button" class="btn primaryButton w-100" (click)="mode = TagMode.edit; addTagContext = false; loadData()">
            {{'TAGS.MANAGE.EDIT_TAGS' | translate | uppercase }}
          </button>
        </div>
      </div>
      <div *ngIf="mode === TagMode.edit" class="row mx-2 my-0 align-items-center footer-row">
        <div *ngIf='addTagContext' class="col-8 pb-1 pl-2 pr-0">
          <input
            id="new-tag-name"
            type="text"
            class="form-control"
            [mask]='"Z{25}"'
            [pattern]='pattern["Z"].pattern'
            [patterns]='pattern'
            [maxLength]='25'
            [formControlName]='"newtag"'/>
        </div>
        <div *ngIf='addTagContext' class="col-4 pb-1 px-0 text-right">
          <button id='create-tag-btn' type="button" class="btn primaryButton w-75" (click)="addTag(); addTagContext = false" [attr.disabled]='manageTag.value["newtag"] ? null : true'>
            {{'TAGS.MANAGE.ADD2' | translate }}
          </button>
        </div>
        <div *ngIf='!addTagContext' class='col-12 pb-1 px-0'>
          <button id='add-tag-toggle-btn' type='button' class='btn primaryButton w-100' (click)='addTagContext = true'>
            {{'TAGS.MANAGE.ADD' | translate }}
          </button>
        </div>
        <div class='col-12 pb-1 px-0'>
          <button id='end-edit-mode' type="button" class="btn primaryButton w-100" (click)="mode = TagMode.search; addTagContext = false; loadData()">
            {{'TAGS.MANAGE.DONE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
