  <div class="container">
    <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <img class="w-25 mx-auto" src="../../assets/images/interlace.png" alt="interlacehealth_logo" />
          </div>
    </div>

    <div class="row text-center mx-auto max-width455">
        <div class="col-sm-12 mt-5 p-0">
          <h2>{{'BEFORE_LOGIN_NOTIFICATION.TITLE' | translate }}</h2>
        </div>    
    </div>

    <div class="row mx-auto notification max-width455">
      <div class="col-sm-12 d-flex align-items-center p-0"> 
        <p [innerHTML]="notification | replaceLineBreaks"></p>
      </div>
    </div>

    <div class="row mx-auto max-width455">
      <div class="col-sm-12 d-flex align-items-center justify-content-between p-0"> 
        <button class="btn btn-primary" (click)="close()" id="cancelbutton"> {{ 'BEFORE_LOGIN_NOTIFICATION.CANCEL' | translate }}</button>
        <button class="btn btn-primary" (click)="continue()" id="continuebutton"> {{ 'BEFORE_LOGIN_NOTIFICATION.CONTINUE' | translate }}</button>
      </div>
    </div>
  </div>