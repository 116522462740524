import { Component } from '@angular/core';
import { TextboxComponent } from '../../textbox.component';
import { CurrencyPipe, DatePipe } from '@angular/common';

export enum DisplaySSN {
  SHOW = 'SHOW',
  HIDE = 'HIDE'
}

@Component({
  selector: 'next-ssn',
  templateUrl: './ssn.component.html',
  styleUrls: ['./../../textbox.component.css']
})
export class SsnComponent extends TextboxComponent {
  constructor (currencyPipe: CurrencyPipe, datePipe: DatePipe) {
    super(currencyPipe, datePipe);
  }

  DisplaySSN: typeof DisplaySSN = DisplaySSN;
  hiddenText: boolean = true;

  // Regex match:
  //  any 3 digits then optional space or hyphen, then
  //  any 2 digits then optional space or hyphen, then
  //  any 4 digits
  SSNPattern: RegExp = new RegExp(/^\d{3}([ -]?)\d{2}([ -]?)\d{4}$/);

  toggleHiddenText(): void {
    this.hiddenText = !this.hiddenText;
    // setTimeout() is a workaround for known issue in ngx-mask, resolved in version 14.0.2 (requires angular 14)
    // https://github.com/JsDaddy/ngx-mask/issues/996
    const val: string = this.valueFormGroup.value.Text;
    setTimeout(() => this.valueFormGroup.controls['Text'].setValue(val, { emitEvent: false }));
  }
}
