import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GridSetting } from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-grid-setting-modal',
  templateUrl: './grid-setting-modal.component.html',
  styleUrls: ['./grid-setting-modal.component.scss']
})
export class GridSettingModalComponent implements OnInit {

  @Input() sortby: Observable<[string]>;
  @Input() filterby: Observable<any>;
  @Input() displaycolumn: Observable<any>;
  @Input() userid: string;
  @Input() screen: string;


  onClose: Subject<boolean> = new Subject<boolean>();
  notification = '';
  formArray: FormArray = new FormArray([]);
  gridview: FormGroup = new FormGroup({
    newgridsetting: new FormControl(''),
  });

  constructor(
    public stateSvc: StoreService,
    public modalRef: BsModalRef,
    private adminSvc: NextAdminService,
    private formBuilder: FormBuilder
  ) {  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.adminSvc.getAllGridSettings(this.screen, this.userid).subscribe(data => {
      this.formArray = new FormArray([]);
      for (const setting of data) {
        const record: FormGroup = this.formBuilder.group({
          id: setting.id,
          settingname: setting.settingname,
        });
        this.formArray.push(record);
      }
    });
  }

  deleteSetting(id) {
    this.adminSvc.deleteSetting(id).subscribe(() => {
      this.close(false);
    });
  }

  createSetting() {
    const config = this.gridview.value;
     const data: GridSetting = {
      userid: this.userid,
      settingname: config.newgridsetting,
      displaycolumn: JSON.stringify(this.displaycolumn),
      sortby: this.sortby,
      filterby: this.filterby,
      screen: this.screen,
     };

    this.adminSvc.saveSetting(data).subscribe(result => {
      this.close(result);
    });
  }

  close(setting) {
    this.onClose.next(setting);
    this.modalRef.hide();
  }

}
