import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TagService } from '@next/shared/next-services'
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { Tag, TagJunction } from '@next/shared/common';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'next-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.scss'],
})
export class TagSearchComponent {
  @ViewChild('dropdown') dropdown: BsDropdownDirective

  @Input() appendTo: string; // The element to bind dropdown menu to, useful when dropdown is in a modal
  @Input() activeTags: Tag[] = []; // active tags provided by parent
  @Output() tagSearch: EventEmitter<{ activeTags: Tag[], experienceIds: Set<string> }> = new EventEmitter<{ activeTags: Tag[], experienceIds: Set<string> }>();

  searchFilter = ''; // The search filter 2-way data-bind
  allTags: Tag[] = []; // All tags in the system
  selectedTags: Tag[] = []; // Active tags scoped to only while dropdown is open

  constructor (
    private tagSvc: TagService,
    private toastrSvc: ToastrService) { }

  /**
   * Dropdown event handler, called when
   * the dropdown element is opened.  Retrieves
   * all tags and marks those that are active
   *
   */
  dropdownOpen(): void {
    this.selectedTags = [];

    this.tagSvc.getAllTags().subscribe(tags => {
      this.allTags = tags;
      for (const tag of this.activeTags) {
        this.selectedTags.push(tag);
      }
    });
  }

  isActive(t: Tag): boolean {
    return this.selectedTags.some(s => s.id === t.id);
  }

  /**
   * Add or remove tag from selected tags
   *
   * @param t {Tag} - The tag that was clicked
   */
  onTagClick(t: Tag): void {
    if (this.selectedTags.find(s => s.id === t.id)) {
      this.selectedTags = this.selectedTags.filter(s => s.id !== t.id);
    }
    else {
      this.selectedTags.push(t);
    }
  }

  /**
   * Submit() next-tag-search two values
   * It emits the ids of the referenced items to view
   * and it emits the tags that are active
   *
   * @fires updatedActiveTags: {Object} - Tags applied and experience ids to show
   */
  async submit(): Promise<void> {
    try {
      this.activeTags = this.selectedTags;

      const experiences = [];
      const obs: Observable<TagJunction[]>[] = [];
      for (const t of this.activeTags) {
        obs.push(this.tagSvc.getTagToElement(t.id));
      }

      const junctions = await forkJoin(obs).toPromise() || [[]];
      for (const tags of junctions) {
        for (const junction of tags) {
          experiences.push(junction.elementid)
        }
      }
      const distinct: Set<string> = new Set(experiences);
      this.tagSearch.emit({ activeTags: this.activeTags, experienceIds: distinct })
    }
    catch (e) {
      this.toastrSvc.error(e.message);
    }
    finally {
      this.dropdown.hide();
    }
  }

  close(): void {
    this.dropdown.hide();
  }
}
