import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { Calculation, CalculationResult } from '../models/calculation.model';
import {
  AnnotationDTO,
  CheckboxFieldDTO,
  DropdownFieldDTO,
  FieldDTO,
  GuidedExperienceDTO,
  TextboxFieldDTO,
  WrittenSigDTO,
} from '../models/guided-experience.model';
import { SubmissionDataType } from '../models/submit.model';
import { TextboxInputType, ViewerFieldType } from '../models/viewer.model';
import { IDService } from './id.service';
import * as platform from 'platform';

import {JSONPath} from 'jsonpath-plus';

declare let formCalculations: any;
declare let fhirpath: any;

@Injectable({
  providedIn: 'root'
})
export class GxProcessing {

  calculationSubject: BehaviorSubject<Calculation[]> = new BehaviorSubject<any[]>([]);

  constructor(

    private idSvc: IDService,
    private http: HttpClient) {

    try {
      this.calculationSubject.next(
        Object.getOwnPropertyNames(formCalculations).filter(prop => typeof formCalculations[prop] === 'function').map(calc => {
          return {
            id: idSvc.generate(),
            name: calc
          }
        })
      );
    }
    catch (e) {
      console.warn('No Calculations Defined.');
    }

  }

  doEvaluate(data: any, expression: string) {
    return (JSONPath({path: expression, json: data}) ?? [])[0];
  }


  async getViewerDataAndConfig(experience: GuidedExperienceDTO, savedState: any, prefill: any): Promise<{experience: GuidedExperienceDTO, data: any}> {
    let data = savedState || { };

    // Run through all fields setting up the initial data
    // Filter the results to only fillable fields (has .name property)
    const fields = this.getAllFields(experience).filter(f => f.name) as any;
    for (const field of fields) {
      // Is there a saved state for this field, use it
      if (Object.prototype.hasOwnProperty.call(data, field.name)) {
        continue;
      }
      // Else check to see if the field has a FHIR path configured
      else if (Object.prototype.hasOwnProperty.call(field, 'FHIRPath') && field['FHIRPath']) {
        const value: string = this.doEvaluate(prefill, field['FHIRPath']);

        if (value) {
          data[field.name] = Object.assign({}, this.generateEmptyFieldValue(field), {
            Type: SubmissionDataType.Text,
            Value: {
              Text: value
            }
          });
        }
        else {
          data[field.name] = this.generateEmptyFieldValue(field);
        }
      }
      else {
        data[field.name] = this.generateEmptyFieldValue(field);
      }

      // add timestamp for sig if needed
      if (field.signatureTimeStampFieldName) {
        data[field.signatureTimeStampFieldName] = {
          Type: SubmissionDataType.Text,
          Value: {
            Text: '',
            Format: TextboxInputType.ALPHANUM
          }
        }
      }

    }

    // Build out a list of calculations that need to be run
    const calculationsToRun = [];
    if (experience.onLoad) {
      calculationsToRun.push({
        name: experience.onLoad,
      });
    }

    // Add all field calculations
    for (const field of fields) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(field.name) && field.calculations && field.calculations.onChange) {
        calculationsToRun.push(
          {
            name:  field.calculations.onChange,
            fieldName: field.name
          }
        );
      }
    }

    // process calculations sequentially
    for (const calculation of calculationsToRun) {
      const calculationResults = await this.runCalculation(calculation, experience, data, prefill).toPromise();
      data = Object.assign({}, data, calculationResults.fields);

      experience = this.getUpdatedConfig(experience, calculationResults.configs)
    }

    return {experience, data};
  }

  async processFields(experience: GuidedExperienceDTO, formId: string, selectedPage: number, submissionData: any, submissionSvc): Promise<unknown> {
    const data: any = submissionData;
    const fields: FieldDTO[] = this.getAllFields(experience);
    for (const fieldDTO of fields) {
      if (!Object.prototype.hasOwnProperty.call(data, fieldDTO.name)) continue;
      switch (fieldDTO.type) {
        // Process Dropdown
        case ViewerFieldType.DROPDOWN: {
          // Convert dropdown value to delimited concatenated string when multiselect is enabled
          // TODO: Add picklist field type to PDF/GX Viewer so this multiselect dropdown is no longer required
          const dropDownField = fieldDTO as DropdownFieldDTO;
          if (dropDownField.dropDownMultiSelect && dropDownField.dropDownDelimiter !== '' && Array.isArray(data[fieldDTO.name].Value.Text)) {
            data[fieldDTO.name].Value.Text = data[fieldDTO.name].Value.Text.join(dropDownField.dropDownDelimiter);
          }
          break;
        }
        // Process Checkbox
        case ViewerFieldType.CHECKBOX: {
          // Convert checkbox if truthy to the export value configured by the pdf, otherwise empty string
          const checkboxField = fieldDTO as CheckboxFieldDTO;
          if (data[checkboxField.name]) {
            data[checkboxField.name].Value.Text = data[checkboxField.name].Value.Text ? checkboxField.onValue : '';
          }
          break;
        }
        // Process Written Signature
        case ViewerFieldType.WRITTENSIG: {
          const signatureDTO = fieldDTO as WrittenSigDTO;
          // Remove extraneous Show-Typed-Signature WebForm Control (may no longer be necessary)
          delete data[`${signatureDTO.name}_ShowTypedSigSection`];
          // Remove extraneous Text or Strokes property based on SubmissionDataType
          if (data[fieldDTO.name].Type === SubmissionDataType.Signature) {
            delete data[signatureDTO.name].Value.Text;
          } else {
            delete data[signatureDTO.name].Value.Strokes;
          }
          // Setup Timestamp properties
          data[signatureDTO.name].Value.Timestamp = {
            Location: signatureDTO.signatureTimeStampLocation,
            Value: this.strftime(signatureDTO.signatureTimeStampFormat || '', data[signatureDTO.name].Value.SignedDate)
          };
          break;
        }
        // Process Drawing Annotation
        case ViewerFieldType.ANNOTATION: {
          const typedField: AnnotationDTO = fieldDTO as AnnotationDTO;
          // Convert HTMLImageElement into File for Embedded Annotations
          if (typedField.pdfBacked && data[typedField.name].Value.ImageSource.src) {
            fetch(data[typedField.name].Value.ImageSource.src).then(res => res.blob()).then(blob => {
              data[typedField.name].ImageSource = new File([blob], `${typedField.name}.jpeg`, blob);
            });
          }
          // Upload Attachment if this Annotation Drawing is an Attachment type
          else if (!typedField.pdfBacked && data[typedField.name].Value.ImageSource.length) {
            const uri: string = data[typedField.name].Value.ImageSource;
            const header: string = uri.split(',')[0];
            const mime: string = header.substring(header.indexOf(':') + 1, header.length + 1).split(';')[0];
            const f: Response = await fetch(uri);
            const a: ArrayBuffer = await f.arrayBuffer();
            const file: File = new File([a], typedField.name + '.jpeg', { type: mime });
            await submissionSvc.uploadAttachment(formId, typedField.name, experience.vid, {
                file: file,
                meta: { platform: platform, page: selectedPage }}
            ).toPromise();
          }
          break;
        }
      }
    }
    return data;
  }

  getCalculations(): Observable<Calculation[]> {
    return this.calculationSubject.asObservable();
  }

  runCalculation(calculation: any, config: GuidedExperienceDTO, state: any, prefill: any): AsyncSubject<CalculationResult> {
    const results = new AsyncSubject<CalculationResult>();

    const calculationLookup = this.calculationSubject.getValue().find(c => c.name === calculation.name);
    const sdk = this.getFormSDK(results, config, state, prefill);
    if (calculationLookup) {
      formCalculations[calculation.name](sdk, calculation.fieldName);

      // if no async calls were made, go ahead and save.
      if (!sdk.async) {
        sdk.save(false);
      }
    }
    else {
      if (calculation.Name !== '') {
        console.warn(`Calculation '${calculation.name}' not found.`);
      }

      sdk.save(false);
    }

    return results;
  }


  generateEmptyFieldValue(field: FieldDTO): any {
    switch (field.type) {
      case ViewerFieldType.TEXTBOX:
      case ViewerFieldType.CHECKBOX:
      case ViewerFieldType.DROPDOWN:
      case ViewerFieldType.RADIOGROUP:
        return {
          Type: SubmissionDataType.Text,
          Value: {
            Text: '',
            Format: (field.type === ViewerFieldType.TEXTBOX) ? (field as TextboxFieldDTO).inputType : TextboxInputType.ALPHANUM
          }
        };

      case ViewerFieldType.WRITTENSIG: {
        const writtenSignatureFieldConfig = field as WrittenSigDTO;
        const isTypedSignatureField = writtenSignatureFieldConfig.signatureType === 'typedSignature' || writtenSignatureFieldConfig.signatureType === 'initialsSignature';
        return {
          Type: isTypedSignatureField ? SubmissionDataType.TypedSignature : SubmissionDataType.Signature,
          Value: {
            SignedDate: '',
            Strokes: []
          }
        };
      }
      case ViewerFieldType.PHOTO:
        return {
          Type: SubmissionDataType.File,
          Value: {
            FileID: '',
            FormID: '',
          }
        }

      case ViewerFieldType.ANNOTATION:
        // eslint-disable-next-line no-case-declarations
        const annotationField: AnnotationDTO = field as AnnotationDTO;
        // eslint-disable-next-line no-case-declarations
        const value: any = {
          Strokes: [],
          ImageSource: annotationField.imageSource
        };
        return {
          Type: SubmissionDataType.Drawing,
          Value: value,
        };
      default:
        return null;
    }
  }


  containsInputFields(fields: FieldDTO[]) {
    return fields.some(f => f.name);
  }

  getAllFields(experience: GuidedExperienceDTO): FieldDTO[] {
    return experience.pages.reduce((acc, val) => acc.concat(this.getAllSubFields(val.fields)), []).filter(e => e) as FieldDTO[];
  }

  getAllSubFields(fields: any[]): any[] {
    if (!fields) return [];

    let ret = [...fields];
    for (const f of fields) {
      switch (f.type) {
        case ViewerFieldType.RADIOGROUP:
        case ViewerFieldType.DROPDOWN:
          f.switch.forEach(s => ret = ret.concat(this.getAllSubFields(s.fields)));
          break;
        case ViewerFieldType.CHECKBOX:
          ret = ret.concat(this.getAllSubFields(f.trueFields));
          ret = ret.concat(this.getAllSubFields(f.falseFields));
          break;
        case ViewerFieldType.COLUMNLAYOUT:
          for (const column of f.columns) {
            ret = ret.concat(this.getAllSubFields(column.fields));
          }
          break;
        default:
          ret = ret.concat(this.getAllSubFields(f.fields || (f.popupField && f.popupField.fields)));
          break;
      }
    }
    return ret;
  }

  getUpdatedConfig(oldConfig: GuidedExperienceDTO, updatedConfigs): GuidedExperienceDTO {
    // If no configs were updated, return the old one
    if (Object.keys(updatedConfigs).length === 0) {
      return oldConfig;
    }

    const newConfig = Object.assign({}, oldConfig);

    // Go through all the pages
    for (const page of newConfig.pages) {
      this.updateConfig(page.fields, updatedConfigs);
    }

    return newConfig;
  }

  updateConfig(fieldConfigs: any[], updatedConfigs) {
    for (let i = 0; i < fieldConfigs.length; i++) {
      if (updatedConfigs[fieldConfigs[i].name]) {
        fieldConfigs[i] = Object.assign({}, updatedConfigs[fieldConfigs[i].name]);
      }

      // we have to check for nested controls as well
    }
  }


  private getFormSDK(resultStream: AsyncSubject<CalculationResult>, config: GuidedExperienceDTO, currentState: any, prefill: any = null) {

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // Have to do a deep copy of fields here so calculations can't modify them directly
    // This let's us control when we update the config -- at the end of the calculation
    const fields: FieldDTO[] = [];
    (config.pages.reduce((acc, val) => acc.concat(this.getAllSubFields(val.fields)), []).filter(e => e) as FieldDTO[]).forEach(field => {
      fields.push(Object.assign({}, field));
    });

    return {
      resultStream: resultStream,
      http: this.http,
      fieldConfigs: fields,
      prefill: prefill,
      updatedFields: { },
      updatedConfigs: { },
      async: false,
      saved: false,

      /**
       * Directly sets a field's value.  If the value is in an invalid format, it is not set.
       * @param fieldName
       * @param fieldValue
       */
      setFieldValue: function(fieldName, fieldValue) {
        const field = this.getField(fieldName);

        if (field) {
          // Process Text Fields - This keeps existing Formatting
          if (field.Type === SubmissionDataType.Text) {
            // TODO: Do not set if the field.Value.Format and field.Value.Text are not compatible.
            field.Value = {
              Format: field.Format || 'alphanumeric',
              Text: fieldValue
            };

            this.updatedFields[fieldName] = field;
          }
        }
        else {
          // When a field does not exist, create one dynamically.
          // This allows for two opportunities:
          //   1. Someone can add "hidden" field values w/o having to create an actual field.
          //   2. Not all fields are always known.  Ex: PDF with more fields than experience.
          this.updatedFields[fieldName] = {
            Type: SubmissionDataType.Text,
            Value: {
              Format: 'alphanumeric',
              Text: fieldValue
            }
          }
        } // If field exists or not
      },

      /**
       * Returns the value of the field.  The response type will be determined by the field type and format.
       * For a Text type, if you always need a raw string, call getField(...).Value.Text instead.
       * @param fieldName
       */
      getFieldValue: function(fieldName) {
        const field = this.getField(fieldName);

        if (field) {
          return field.Value;
        }

        console.warn('Unknown field ' + fieldName);
        return '';
      },

      /**
       * Returns the specified field object containing Type and Value.  The structure of Value will vary based on Type.
       * @param fieldName
       */
      getField: function(fieldName) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.updatedFields.hasOwnProperty(fieldName)) {
          return this.updatedFields[fieldName];
        }

        if (currentState[fieldName]) {
          return currentState[fieldName];
        }

        console.warn('Unknown field ' + fieldName);
        return '';
      },

      /**
       * Set the specified fieldName's GX config
       * @param fieldName
       * @param fieldConfig
       */
      setFieldConfig: function(fieldName, fieldConfig) {
        // Note, this lets you essentially 'create' fields, because we don't know if the field config already exists
        this.updatedConfigs[fieldName] = fieldConfig;
      },

      /**
       * Returns the specified field config by fieldName.  If the GX config hasn't had the field added, it returns null.
       * @param fieldName
       */
      getFieldConfig: function(fieldName) {
        const field = this.fieldConfigs.find(f => f.name === fieldName);

        if (field) {
          return field;
        }

        console.warn('Unknown field ' + fieldName);
        return null;
      },

      /**
       * Returns a value from the pre fill service.  The response type will be a string
       * @param expression expression ( fhir )
       */
      getPrefill: function(expression: string) {
        const exp = expression || '';

        return that.doEvaluate(prefill, exp) || '';
      },

      /**
       * Calls the specified url with GET.  Then executes the provided callback with the result
       * @param url
       * @param callback
       */
      getHttp: function(url, callback) {
        this.async = true;

        this.http.get(url).subscribe(result => {
          callback(result);
        });
      },

      /**
       *  Saves the current updated form state.  This is needed if you have any async (http) calls.
       *  This can only be called once, and if not done by a calculation, the form will not load
       */
      save: function(displayWarnings: boolean = true) {
        if (!this.async && displayWarnings) {
          console.warn('Call to formSdk.save() on a synchronous calculation detected.');
        }

        if (this.saved && displayWarnings) {
          console.warn('Second call to formSdk.save() detected.');
        }

        this.resultStream.next({ fields: this.updatedFields, configs: this.updatedConfigs });
        this.resultStream.complete();

        this.saved = true;
      }
    }
  }

  /**
   * https://stackoverflow.com/questions/8847109/formatting-the-date-time-with-javascript
   * @param sFormat {string} - Preferred format
   * @param date {any}      - JS Date Object or Date string to convert to string
   * @returns {*}
   */
  strftime(sFormat, date: any) {
    if (!(date instanceof Date)) date = new Date(date);                   // If a String date is provided instead of a Date object, attempt conversion
    if (!(date instanceof Date && !isNaN(<any>date))) date = new Date();  // If String date conversion failed, new Date object
    const nDay = date.getDay(),
      nDate = date.getDate(),
      nMonth = date.getMonth(),
      nYear = date.getFullYear(),
      nHour = date.getHours(),
      aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
      isLeapYear = function() {
        return (nYear%4===0 && nYear%100!==0) || nYear%400===0;
      },
      getThursday = function() {
        const target = new Date(date);
        target.setDate(nDate - ((nDay+6)%7) + 3);
        return target;
      },
      zeroPad = function(nNum, nPad) {
        return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
      };
    return sFormat.replace(/%[a-z]/gi, function(sMatch) {
      return {
        '%a': aDays[nDay].slice(0,3),
        '%A': aDays[nDay],
        '%b': aMonths[nMonth].slice(0,3),
        '%B': aMonths[nMonth],
        '%c': date.toUTCString(),
        '%C': Math.floor(nYear/100),
        '%d': zeroPad(nDate, 2),
        '%e': nDate,
        '%F': date.toISOString().slice(0,10),
        '%G': getThursday().getFullYear(),
        '%g': ('' + getThursday().getFullYear()).slice(2),
        '%H': zeroPad(nHour, 2),
        '%I': zeroPad((nHour+11)%12 + 1, 2),
        '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
        '%k': '' + nHour,
        '%l': (nHour+11)%12 + 1,
        '%m': zeroPad(nMonth + 1, 2),
        '%M': zeroPad(date.getMinutes(), 2),
        '%p': (nHour<12) ? 'AM' : 'PM',
        '%P': (nHour<12) ? 'am' : 'pm',
        '%s': Math.round(date.getTime()/1000),
        '%S': zeroPad(date.getSeconds(), 2),
        '%u': nDay || 7,
        '%V': (() => {
          const target = getThursday(),
            n1stThu = target.valueOf();
          target.setMonth(0, 1);
          const nJan1 = target.getDay();
          if (nJan1!==4) {
            target.setMonth(0, 1 + ((4-nJan1)+7)%7);
          }
          return zeroPad(1 + Math.ceil((n1stThu - target.valueOf())/604800000), 2);
        })(),
        '%w': '' + nDay,
        '%x': date.toLocaleDateString(),
        '%X': date.toLocaleTimeString(),
        '%y': ('' + nYear).slice(2),
        '%Y': nYear,
        '%z': date.toTimeString().replace(new RegExp(/.+GMT([+-]\d+).+/), '$1'),
        '%Z': date.toTimeString().replace(new RegExp(/.+\((.+?)\)$/), '$1')
      }[sMatch] || sMatch;
    });
  }

}
