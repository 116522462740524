import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthProviderName, AuthToken, IhPermissions, TokenService, User} from '@next/shared/common';
import {NextAuthService} from "../auth.service";

@Injectable({
  providedIn: 'root',
})
export class UserResolverService {

  jwt: any;
  permissions: any[];
  jwtHelper = new JwtHelperService();
  _usesPatients = true;
  _usesAuthorization = true;

  constructor(private tokenSvc: TokenService, private authSvc: NextAuthService) { }

  getCustomPermission(permission: typeof IhPermissions[keyof typeof IhPermissions]): boolean {
    return this.permissions[permission];
  }

  get hasDemonstrationLicense():boolean{
    const licenses= JSON.parse(sessionStorage.getItem('licenses'));
    return licenses && licenses.includes('demonstration');
  }

  get usesPatients(): boolean {
    return this._usesPatients;
  }

  set usesPatients(value: boolean) {
    this._usesPatients = value;
  }

  get usesAuthorization(): boolean {
    return this._usesAuthorization;
  }

  set usesAuthorization(value: boolean) {
    this._usesAuthorization = value;
  }

  // TODO: There could be a User-Service for this resolver
  resolve(): Observable<User> | Observable<never> {
    const fallback: Observable<User> = of({ firstName: null, lastName: null, oid: null });
    try {
      const jwt = this.tokenSvc.getAccessToken();
      if (!jwt) return fallback;

      const authToken: AuthToken = this.jwtHelper.decodeToken(jwt);
      this.permissions = authToken.permissions;
      switch (authToken.connection) {
        case (AuthProviderName.SALESFORCE) : {
          this.usesAuthorization = false;
          this.usesPatients = false; /** TODO:
                                          *************
                                          * 1.  _Verbose property names matching _NextClinical_ modules_
                                          * 2.  _break _usePatients_ and _usesAuthorization_ into more offering-centric controls_ */
          return this.authSvc.getUserInfo();
        }
        case (AuthProviderName.AZURE):
        case (AuthProviderName.AZURE_DEV):
        case (AuthProviderName.SMART): {
          // Process integration result Access Token
          if (authToken.integration?.accessToken) {
            const accessToken = this.jwtHelper.decodeToken(authToken.integration.accessToken);
            return of({ firstName: accessToken.given_name, lastName: accessToken.family_name, oid: accessToken.oid } as User);
          }
          break;
        }
        default: {
          return fallback;
        }
      }
    }
    catch {
      // swallow exception for SF
    }
  }
}
