import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'next-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  constructor(translate: TranslateService, faLib: FaIconLibrary) {
    translate.use('en');

    faLib.addIconPacks(far, fas);
  }
}
