import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

/* eslint-disable no-var */
declare var mwbScanner: any;

@Component({
  selector: 'next-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {

  @ViewChild('container', { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  @Output() scanResult: EventEmitter<string> = new EventEmitter<string>();


  // i18n translation strings
  cameraNotFoundMessage = this.translate.instant('BARCODE.CAMERAS_NOT_FOUND_MESSAGE');
  cameraNotFoundSubject = this.translate.instant('BARCODE.CAMERAS_NOT_FOUND_SUBJECT');
  cameraNoPermission = this.translate.instant('BARCODE.ERROR_PERMISSION');
  cameraErrorMessage = this.translate.instant('BARCODE.ERROR_MESSAGE');
  cameraErrorSubject = this.translate.instant('BARCODE.ERROR_SUBJECT');

  constructor (
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}


  ngOnInit(): void {
    // To modify the settings go the ProjectY.Angular/tools/cognex folder and read the Interlace_Dev_Instructions.txt file
    // ie. barcode types to scan, timeout.  A list of settings are here: https://cmbdn.cognex.com/knowledge/cognex-mobile-barcode-sdk-for-web/installation-with-plain-js
    // For reference, the active turned on barcodes are 39, 128, and Aztec
      mwbScanner.startScanning(this.scanHandler.bind(this), 5,15,90,80);
  }

  ngOnDestroy(): void {
    mwbScanner.closeScanner();
  }

  scanHandler(scanResult) {
    if (scanResult.type === 'Error') {
      if (scanResult.errorDetails.name === 'NotAllowedError') {
        this.toastr.error(`${this.cameraNoPermission}`, this.cameraErrorSubject);
      } else if (scanResult.errorDetails.name === 'NotFoundError') {
        this.toastr.error(`${this.cameraNotFoundMessage}`, this.cameraNotFoundSubject);
      }
       else {
        this.toastr.error(`${this.cameraErrorMessage} ${scanResult.code}`, this.cameraErrorSubject);
      }
    } else if (scanResult.type !== 'Cancel') {
      this.scanResult.emit(scanResult.code);
    }
  }

  exit(): void {
    this.modalRef.hide();
  }

}
