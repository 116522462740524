/**
 * FormSubmission model contains properties for both the Form and FormHistory table in our DB
 * 
 * taskId is not in either tables, but if passed when creating or updating a form it will 
 * update the Task table as well.
 */
export interface FormSubmission {
  id: string,
  experienceversionid: string;
  submissiontype: SubmissionType;
  updatedby: string;
  fileid: string;
  data: any;
  metadata: SubmissionMetadata;
  taskId?: string;
  lastupdated: any;
}

export interface SubmissionMetadata {
  page: number;
}

export interface Attachment {
  attachmentUrl: string;
  contenttype: string;
  fieldid: string;
  name: string;
  signedUrl: string;
}

export enum SubmissionType {
  Saved = 'saved',
  Submitted = 'submitted',
  Initialized = 'initialized'
}

export enum SubmissionDataType {
  Text = 'Text',
  Signature = 'Signature',
  TypedSignature = 'TypedSignature',
  Drawing = 'Drawing',
  File = 'File'
}
