import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UrlService {
    private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    getPreviousUrl() {
      return this.previousUrl.asObservable();
    }

    setPreviousUrl(previousUrl: string) {
      this.previousUrl.next(previousUrl);
  }
}
