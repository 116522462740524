<div class="container-fluid h-100" [formGroup]="gridview">
    <div class="row d-flex px-0 pb-2 align-items-center modal-header justify-content-between title">
      <div class="col-2">
        <button id='appointment-save-button' class='btn-plain pointer-on-hover text_button' (click)='close(false)'>
          {{ 'APPOINTMENTS.GRID_SETTINGS.CLOSE_BUTTON' | translate }}
        </button>
      </div>
      <div class="col-8 text-center">
        <h6>{{'APPOINTMENTS.GRID_SETTINGS.TITLE' | translate }}</h6>
      </div>
      <div class="col-2">
      </div>
    </div>
    <div class='d-block row'>
      <div class='container-fluid m-10 p-10 h-100'>
        <div class="mid-section">
          <div *ngFor='let setting of formArray.controls' class='row m-2 align-items-center'>
            <div class='col-8 m-1' (click)="deleteSetting(setting.value.id)">
              <fa-icon class='delete-button' [icon]='["fas", "trash-alt"]' size="lg"></fa-icon>
              &nbsp;
              {{ setting.value.settingname }}
            </div>
            <div class='col-4 text-center'>
            </div>
          </div>
        </div>

        <div class="row footer">
          <input id="new-grid-setting" class="w-75" type="text" maxlength="50" formControlName="newgridsetting" placeholder="New Saved View" />
          &nbsp;&nbsp;
          <button id='appointment-save-button' class='btn primaryButton w-20' [attr.disabled]='gridview.value["newgridsetting"] ? null : true' (click)="createSetting()">
            {{ 'APPOINTMENTS.GRID_SETTINGS.SAVE_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  