import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NextClinicalService } from '../clinical.service';

@Injectable({
  providedIn: 'root',
})
export class NextPrefillResolverService {
  constructor(private clinicalSvc: NextClinicalService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> | Observable<never> {
    const experienceId = route.paramMap.get('experienceId');

    if (route.queryParamMap.keys.includes('embeddedPrefill')) {
      return of(null);
    }

    if (route.queryParamMap.keys.length > 0) {
      return this.clinicalSvc.getPrefill(experienceId, route.queryParamMap).pipe(
        catchError(err => {
          // Swallow all errors.  Prefill is not that important, as to fail or alert anyone.
          if (err.status === 500) {
            console.log('Prefill failure: ' + err.message);
          }

          return of(null);
        })
      );
    }
    return of(null);
  }
}

