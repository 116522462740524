import { Injectable } from '@angular/core';
import { IDService } from './id.service';
import { FieldType } from '../models/form.model';
import { GuidedExperienceDTO } from '../models/guided-experience.model';


@Injectable({
  providedIn: 'root'
})
export class ImportHelperService {
  constructor(protected idSvc: IDService) {
  }

  convertFields(fields) {
    const pageFields = [];
    fields.pages.map(page => pageFields.push(...page.annotations));
    return this.filterFieldTypes(pageFields.map(annot => this.nextAnnotationToLegacy(annot)));
  }

  filterFieldTypes(fields) {
    return fields.filter(
      field => field.fieldType !== 'BUTTON' && field.fieldType !== 'HYPERLINK'
    );
  }

  nextAnnotationToLegacy(annot) {
    return {
      id: this.idSvc.generate(),
      name: annot.name,
      groupName: '',
      fieldType: this.nextAnnotationTypetoLegacy(annot.type),
      width: 0,
      height: 0,
      isChecked: false,
      isNoExport: false,
      isReadOnly: false,
      isRequired: annot.required,
      isVisible: true,
      pageIndex: 1,
      tabOrder: 1,
      toolTip: '',
      offValue: 'On',
      onValue: 'Off',
      selectOptions: this.nextSelectOptionsToLegacy(annot),
      groupMembers: []
    };
  }

  nextAnnotationTypetoLegacy(type) {
    switch (type) {
      case 'textbox':
        return FieldType.TextBox;
      case 'checkbox':
        return FieldType.CheckBox;
      case 'radio':
        return FieldType.Radio;
      case 'combobox':
        return FieldType.DropDown;
      case 'button':
        return FieldType.Button;
      case 'signature':
        return FieldType.Signature;
    }
  }

  nextSelectOptionsToLegacy(annot) {
    switch (annot.type) {
      case 'radio':
        return annot.options.map(option => {
          return {
            id: this.idSvc.generate(),
            text: option.value,
            value: option.value,
            selected: false
          };
        });

      case 'combobox':
        return annot.options.map(option => {
          return {
            id: this.idSvc.generate(),
            text: option,
            value: option,
            selected: false
          };
        });
    }

    return [];
  }

  generateMetaData(gx: GuidedExperienceDTO) {
    const pages = (gx.pages === undefined ? '' : JSON.stringify(gx.pages));
    const text = `{
        "name": "${gx.name}",
        "description": "${gx.description}",
        "version": 1,
        "configversion": 1,
        "status": "draft",
        "pdftemplateid": "@pdftemplateid",
        "metadata": { "sources": [{ "id":"@metadataid", "type":"pdf", "fields": @sources }] },
        "data": {
          "header": {
            "logo": {
              "url": "${gx.logoUrl}",
              "height": ${gx.logoHeight},
              "alignment": "${gx.logoAlignment}"
            }
          },
          "calculations": {
            "onLoad": "${gx.onLoad}",
            "onSave": "${gx.onSave}",
            "onSubmit": "${gx.onSubmit}"
          },
          "pages": ${(pages)}
      }}`;
    return text;
  }

  formatFileName(name: string, id: string) {
    if (name && id) {
      return (id + '_' + name.replace('/', '_').replace(/[^a-z0-9]/gi, '_')).substr(0, 79);
    }
    return '';
  }

}
