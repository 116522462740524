<div class="input-group calendar invisible">
  <input
    #ngxDatePicker="bsDatepicker"
    class="form-control"
    bsDatepicker
    id="{{ field.name + '_datePicker' }}"
    name="{{ field.name + '_datePicker' }}"
    (onShown)="pickerOpened = true"
    [bsValue]="datePickerValue"
    [bsConfig]="datePickerConfig"
    (bsValueChange)="datePickerSetDate($event)">
</div>

<div class="form-group rounded" [ngClass]="{ 'd-none' : field.hidden }" [formGroup]="valueFormGroup">
  <label for="{{ field.name }}" class="m-0 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required bold">&nbsp;*</span>
  </label>

  <!--DATE-->
  <div class="input-group">
    <div class="input-group-prepend cursor-pointer" role="button" (click)="ngxDatePicker.show()" [style.pointerEvents]="field.readonly ? 'none' : 'auto'">
      <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="margin-center"></fa-icon></span>
    </div>
    <input
      class="form-control"
      type="text"
      autocomplete="off"
      [(ngModel)]="displayDate"
      (change)="textInputSetDate($event)"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="field.readonly"
      [placeholder]="field.placeholder || (field.dateFormat | uppercase)"
      [mask]="dateMask || null"
      [leadZeroDateTime]="true"
      [specialCharacters]="['/']"
      [ngClass]="{ 'invalid' : valueFormGroup.get('Text')?.invalid  && !newField && needsValidation }">
    <input
      class="form-control d-none"
      type="text"
      formControlName="Text"
      id="{{ field.name + '_text' }}"
      name="{{ field.name + '_text' }}"
      autocomplete="off"
      [readonly]="field.readonly"
      [placeholder]="field.placeholder || (field.dateFormat | uppercase)"
      [ngClass]="{ 'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}">
  </div>
  <ng-container *ngIf="valueFormGroup?.get('Text')">
    <span *ngIf="valueFormGroup?.get('Text').invalid && !newField && needsValidation" class="float-right text-sizing color-danger">
      {{ getFormattingError(valueFormGroup?.get('Text')) | translate:getFormattingErrorParam(valueFormGroup?.get('Text')) }}
    </span>
  </ng-container>
</div>
