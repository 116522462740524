import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//totalAttempts is hardcoded atm until we add this as a setting
const TOTAL_ATTEMPTS = 10;
@Component({
  selector: 'next-view-batch-sign',
  templateUrl: './view-batch-sign.component.html',
  styleUrls: ['./view-batch-sign.component.css']
})
export class ViewBatchSignComponent implements OnInit, OnDestroy {
  cleanup: Subject<void> = new Subject();

  @Input() codeFG: FormGroup;
  @Input() invalid: boolean;
  @Input() accessCodeDisabled: boolean;

  @Output() submitModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() exitModal: EventEmitter<void> = new EventEmitter<void>();

  remainingAttempts: number = TOTAL_ATTEMPTS;
  hideCode = true;
  masking = 'ZZZZZZZZ';
  pattern: any = {
    'Z' : { pattern : /\w/, symbol : '•' },
    'Y' : { pattern : /\w/, }
  };

  ngOnInit(): void {
    this.codeFG.valueChanges.pipe(
      takeUntil(this.cleanup)
    ).subscribe(code => {
      this.masking =  'Z'.repeat(Math.max(code.code.length - 1, 0)) + 'YY';
    });
  }

  ngOnDestroy(): void {
    this.cleanup.next();
    this.cleanup.complete();
  }

  get attemptsObject(): any {
    return { attempts: this.remainingAttempts, totalAttempts: TOTAL_ATTEMPTS }
  }

  submitSign(): void {
    this.remainingAttempts -= 1;
    this.submitModal.emit(this.codeFG.value.code);
  }

  toggleHideCode() {
    this.hideCode = !this.hideCode;
    this.masking = 'ZZZZZZZZ';
    if (this.hideCode) {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : '•' },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
    else {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : null },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
  }
}
