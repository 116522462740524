import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad } from '@angular/router';
import { ConfigType, FormFastConfig, FormFastConfigService, TokenService } from '@next/shared/common';
import { Observable, of } from 'rxjs';
import { NextAuthService } from '../auth.service';
import { NextAdminService } from '../admin.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  jwtHelper = new JwtHelperService();
  token: any;
  notification: string;

  constructor(private tokenService: TokenService, @Inject(FormFastConfigService)
  private config: FormFastConfig,
  private router: Router,
  private nextAdminService: NextAdminService,
  private nextAuthSvc: NextAuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
     return this.processAuthentication();
  }

  canLoad(): Observable<boolean> {
    return this.processAuthentication()
  }

  processAuthentication(): Observable<boolean> {
    if (sessionStorage.getItem('lockedMode')) {
      sessionStorage.removeItem('lockedMode');
      this.router.navigate(['logout/auto'], { queryParams: { currentUrl: window.location.pathname, logoutInactivity: false, isMainComponent: false } });
    }
      // Get current token or app_token, if available
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('token');

    // If token exists then automatically save it
    if (this.token) {
      //try saving to our storage, if it cannot it means we don't have access (possibly due to incognito mode) so return true;
      try {
        this.tokenService.saveAccessToken(this.token);
      } catch {
        return of(true);
      }
    }

    // If there exists a token in localstorage and that token is not expired then user can have access
    if (this.tokenService.hasAccessToken() && !this.jwtHelper.isTokenExpired(this.tokenService.getAccessToken())) {
      return of(true);
    }

    // If there is no token, then redirect to SSO
    this.redirect(urlParams);
  }

  redirect(params) {
    // Since we are redirecting them to log in, flush out any legacy token.
    this.tokenService.clear();

    const conn = params.get('connection');

    // Remove the token param from the url, since we may be adding one back
    params.delete('token');
    params.delete('connection');

    // Build our OAuth state, which contains a redirect url
    const state = {
      redirect: `${window.location.origin}${window.location.pathname}`,
    };

    const urlParams = params.toString();
    if (urlParams) {
      state.redirect += `?${urlParams}`;
    }

    const base64State = btoa(JSON.stringify(state));
    this.checkForNotificationsOnLoad(conn, base64State);
  }

  checkForNotificationsOnLoad(conn, base64State) {
    this.nextAdminService.checkBeforeLoginNotification(ConfigType.Notification).subscribe(result => {
      if (result.length > 0 && result[0].data.loginscreentext?.length > 0 &&
        this.checkForActiveTimePeriod(result[0].data.loginscreentext, result[0].data.loginscreenstart, result[0].data.loginscreenend)) {

        this.router.navigate(['beforelogin'],
         { queryParams: { currentUrl: `${this.config.apiUrl}/auth/oauth/authorize?connection=${conn}&state=${base64State}` }});

      } else {
        window.location.href = `${this.config.apiUrl}/auth/oauth/authorize?connection=${conn}&state=${base64State}`;
      }
    });
  }

  checkForActiveTimePeriod(text, start, end) {
    if (text === null || text === "" ) {
      return false
    }

    if (start === null || end === null) {
      return false
    }

    const startDate = new Date(start);
    const endDate = new Date(end);
    const currentDate = new Date();
    return startDate <= currentDate && currentDate <= endDate;


  }

}
