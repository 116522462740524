import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DateFormat,
  FieldDTO,
  SubmissionDataType,
  TextboxFieldDTO,
  TextboxInputType,
  ViewerFieldType
} from '@next/shared/common';
import { DateRangeValidatorFn } from '../../utilities';

@Component({
  selector: 'next-field-base',
  template: ``
})
export class FieldBaseComponent {
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<FieldDTO> = new EventEmitter<FieldDTO>();

  protected form: FormGroup;
  protected field: any;
  protected initialState: any;
  protected animationsOn = false;
  animateState = '';
  valueFormGroup: FormGroup;

  protected processOnInit(f: any): void {

    this.setTempValueControlSwitching(this.field.name);
    // If any nested controls were filled out before by the user before switching back,
    // this will recall that filled out data
    const initData: any = this.initialState[f.name] || { };
    switch(f.type) {
      case ViewerFieldType.DROPDOWN:
      case ViewerFieldType.RADIOGROUP:
      case ViewerFieldType.CHECKBOX:
        if (!this.form.contains(this.field.name)) {
          const options: any = this.field.required ? { validators: [Validators.required] } : { };
          this.valueFormGroup = new FormGroup({
            Text: new FormControl(initData.Value?.Text || '', options),
            Format: new FormControl(TextboxInputType.ALPHANUM)
          });

          this.form.addControl(this.field.name, new FormGroup({
            Type: new FormControl(SubmissionDataType.Text),
            Value: this.valueFormGroup
          }));
          this.animationsOn = true;
        }
        else {
          this.valueFormGroup = this.form.get(`${this.field.name}.Value`) as FormGroup;
          this.animationsOn = false;
        }
        break;
    }
  }

  protected processAfterViewInit(f: any): void {
    switch(f.type) {
      case ViewerFieldType.DROPDOWN:
      case ViewerFieldType.CHECKBOX:
      case ViewerFieldType.RADIOGROUP:
        // eslint-disable-next-line no-case-declarations
        const fControl = this.form.get(this.field.name);
        fControl.statusChanges.subscribe((ctrlState: string) => {
          this.updateStatus(ctrlState);
        });
        setTimeout(() => this.animateState = this.animationsOn ? 'in' : '');
        break;
    }
  }

  protected setTempValueControlSwitching(fieldName: string) {
    if ((this.form as any).tempNestedSwitchControls &&
    (this.form as any).tempNestedSwitchControls[fieldName]) {
        if (this.form.contains(fieldName)) {
          this.form.removeControl(fieldName);
        }
        this.form.addControl(fieldName, (this.form as any).tempNestedSwitchControls[fieldName]);
      }
  }

  protected resetDynamicChildren(field: any): void {
    //Get all nested fields
    const ctrls = this.getAllSubFields([field]);
    //First element in the array will be the parent element. This can be discarded because we do not need to reset it.
    ctrls.shift();
    const ctrlToDiscard: string[] = ctrls.map(ctrl => ctrl.name);

    //Reset all nested fields
    for (const ctrl of ctrlToDiscard) {
      this.form.removeControl(ctrl);
    }
  }

  turnAnimationsOn() {
    this.animationsOn = true;
  }

  protected updateStatus(state: string): void {
    this.statusChange.emit(state);
  }

  valueChangeSub(): void {
    this.valueFormGroup.valueChanges.subscribe(() => {
      this.emitValueChanged(this.field);
    });
  }

  emitValueChanged(field: FieldDTO) {
    this.valueChanged.emit(field);
  }

  protected getAllSubFields(fields: any[]): any[] {
    if (!fields) return [];

    let ret = [...fields];
    for (const f of fields) {
      switch (f.type) {
        case ViewerFieldType.RADIOGROUP:
        case ViewerFieldType.DROPDOWN:
          f.switch.forEach(s => ret = ret.concat(this.getAllSubFields(s.fields)));
          break;
        case ViewerFieldType.CHECKBOX:
          ret = ret.concat(this.getAllSubFields(f.trueFields));
          ret = ret.concat(this.getAllSubFields(f.falseFields));
          break;
        default:
          ret = ret.concat(this.getAllSubFields(f.fields || (f.popupField && f.popupField.fields)));
          break;
      }
    }
    return ret;
  }

  recordValuesForSwitching(field: FieldDTO) {
        // If there are nested values here, save them to the experience JSON blob
        // for the purposes of "memorizing" nested values when a user switches from one option to another
        // Radio groups, dropdowns, checkboxes have nested field

        // get nested fields
        const nestedFields = this.getAllSubFields([field]);
        if (!(this.form as any).tempNestedSwitchControls) {
          (this.form as any).tempNestedSwitchControls = {};
        }
        nestedFields.splice(0, 1);  // removing the first element because it's the parent element
        nestedFields.forEach(nestedField => {
          switch (nestedField.type) {
            case ViewerFieldType.RADIOGROUP:
            case ViewerFieldType.DROPDOWN:
            case ViewerFieldType.CHECKBOX:
            case ViewerFieldType.TEXTBOX:
              if (!this.form.get(nestedField.name)?.value?.Value?.Text) {
                return;
              }
              (this.form as any).tempNestedSwitchControls[nestedField.name] = this.form.get(nestedField.name);
              break;

            case ViewerFieldType.WRITTENSIG:
            case ViewerFieldType.ANNOTATION:
              if (!this.form.get(nestedField.name)?.value?.Value ||
                this.form.get(nestedField.name)?.value?.Value?.Strokes.length === 0) {
                return;
              }
              (this.form as any).tempNestedSwitchControls[nestedField.name] = this.form.get(nestedField.name);
              break;

            case ViewerFieldType.PHOTO:
              if (!this.form.get(nestedField.name) ||
                this.form.get(nestedField.name).value.size === 0) {
                return;
              }
              (this.form as any).tempNestedSwitchControls[nestedField.name] = this.form.get(nestedField.name);
              break;

          }
        });
  }

  getFieldValue(field: FieldDTO): any {

    switch (field.type) {

      case ViewerFieldType.RADIOGROUP:
      case ViewerFieldType.DROPDOWN:
      case ViewerFieldType.CHECKBOX:
        this.recordValuesForSwitching(field);
        return this.form.get(field.name)?.value?.Value?.Text || '';
      case ViewerFieldType.TEXTBOX:
        return this.form.get(field.name)?.value?.Value?.Text || '';

      case ViewerFieldType.WRITTENSIG:
        if (this.form.get(field.name)?.value?.Type === 'Signature') {
          return (this.form.get(field.name).get('Value').get('Strokes')?.value.length > 0) ? this.form.get(field.name).get('Value').get('Strokes').value : '';
        }
        else {
          return this.form.get(field.name).get('Value').get('Text').value || '';
        }

      case ViewerFieldType.ANNOTATION:
        if (this.form.get(field.name)?.value?.Value?.Strokes && this.form.get(field.name).value.Value.Strokes.length > 0) {
          return this.form.get(field.name).value.Value.Strokes;
        }
        else {
          return '';
        }

      case ViewerFieldType.PHOTO:
        return this.form.get(field.name).value || '';
    }
  }

  protected hasAtLeastOneValidField(fields: any[]): boolean {
    const hasAValidField: boolean = fields.some(f => this.form.contains(f.name) && this.form.get(f.name).valid && this.getFieldValue(f));
    const hasAnInvalidField: boolean = fields.some(f => this.form.contains(f.name) && this.form.get(f.name).invalid);
    return hasAValidField && !hasAnInvalidField;
  }

  protected setCSSUserSelect(val: string) : void {
    window.document.body.style.userSelect = val;
  }

  // Used by Pop Up component
  setupInitialForm(initData: any, fields: any[]): void {
    fields.forEach(f => {
      if (!this.form.contains(f.name)) {
        let valueFormGroup: FormGroup;
        const initValue: any = initData[f.name];
        const options: any = f.required ? { validators: [Validators.required] } : { validators: [] };

        switch (f.type) {
          case ViewerFieldType.TEXTBOX:

            options.updateOn = 'blur';
            if ((f as TextboxFieldDTO).inputType === TextboxInputType.DATE || (f as TextboxFieldDTO).inputType === TextboxInputType.SHORTDATE2 || (f as TextboxFieldDTO).inputType === TextboxInputType.SHORTDATE4) {
              options.validators.push(DateRangeValidatorFn(f));
            }

            else if ((f as TextboxFieldDTO).inputType === TextboxInputType.NUM) {
              if (f.minValue) {
                options.validators.push(Validators.min(f.minValue));
              }

              if (f.maxValue) {
                options.validators.push(Validators.max(f.maxValue));
              }
            }

            valueFormGroup = new FormGroup({
              Text: new FormControl(initValue.Value?.Text || '', options),
              Format: f.type === ViewerFieldType.TEXTBOX ? new FormControl(f.inputType) : new FormControl(TextboxInputType.ALPHANUM)
            });

            /* Date input-type requires a control for preferred format */
            if ((f as TextboxFieldDTO).inputType === TextboxInputType.DATE) {
              valueFormGroup.addControl('DateFormat', new FormControl(f.dateFormat || DateFormat.MMDDYYYY));
            }

            break;

          case ViewerFieldType.CHECKBOX:
          case ViewerFieldType.DROPDOWN:
          case ViewerFieldType.RADIOGROUP:
            valueFormGroup = new FormGroup({
              Text: new FormControl(initValue.Value?.Text || '', options),
              Format: f.type === ViewerFieldType.TEXTBOX ? new FormControl(f.inputType) : new FormControl(TextboxInputType.ALPHANUM)
            });
            break;

          case ViewerFieldType.ANNOTATION:
            valueFormGroup = new FormGroup({
              Strokes: new FormControl(initValue?.Value?.Strokes || '', options),
              ImageSource: new FormControl(initValue?.Value?.ImageSource || f.imageSource || '')
            });
            break;

          case ViewerFieldType.WRITTENSIG:
            valueFormGroup = new FormGroup({
              SignedDate: new FormControl(initValue?.Value?.SignedDate || ''),
              Strokes: new FormControl(initValue?.Value?.Strokes || '', options),
              Text: new FormControl(initValue?.Value?.Text || '', options),
            });
            break;
        }

        if (valueFormGroup) {
          this.form.addControl(f.name, new FormGroup({
            Type: new FormControl(initValue.Type),
            Value: valueFormGroup,
          }));
        }
      }
    });
  }

  /**
   * Convert bytes to mb
   *
   * @param {number} b - bytes as number
   * @returns {number} - converted to mb
   * @private
   */
  protected bytesToMB(b: number): number {
    return parseFloat((b / 1048576).toFixed(2));
  }

  /**
   * From mimetype options return file extension
   *
   * @param {string} mimeType - Ex 'image/bmp' or 'image/jpeg'
   * @returns {string} - mimeType's File extension or
   *                     empty string if there is no match
   * @private
   */
  protected getFileExtension(mimeType: string): string {
    switch (mimeType) {
      case 'image/bmp':
        return '.bmp';
      case 'image/jpeg':
        return '.jpg';
      case 'image/jpg':
        return '.jpg';
      case 'image/gif':
        return '.gif';
      case 'image/png':
        return '.png';
      case 'image/tiff':
        return '.tiff'
      default:
        return '';
    }
  }

}
