import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigType } from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';

@Component({
  selector: 'next-before-login-notification',
  templateUrl: './before-login-notification.component.html',
  styleUrls: ['./before-login-notification.component.scss']
})
export class BeforeLoginNotificationComponent implements OnInit {

  redirectLink = '';
  notification = '';

  constructor(
    private nextAdminService: NextAdminService,
    private router: Router,
  ) { }

   ngOnInit() {
     this.redirectLink = this.router.routerState.snapshot.root.queryParams['currentUrl'];
     this.checkForNotificationsOnLoad();
   }

  checkForNotificationsOnLoad() {
    this.nextAdminService.checkBeforeLoginNotification(ConfigType.Notification).subscribe(result => {
      if (result.length > 0 && result[0]) {
        this.notification = result[0].data?.loginscreentext;
      }
    });
  }

  continue() {
    window.location.href = this.redirectLink;
  }

  close() {
     window.close();
  }

}
