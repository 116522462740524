import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AnnotationDTO, SubmissionDataType } from '@next/shared/common';
import SignaturePad from 'signature_pad';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { NextImageService } from '@next/shared/next-services';

@Component({
  selector: 'next-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.css']
})
export class AnnotationComponent extends FieldBaseComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() field: AnnotationDTO;
  @Input() initialState: any;
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  secureAnnotationUrl: string;

  annoValue: any = ''; // sig_pad.js draw data from canvas
  annoPad: SignaturePad; // viewer sig_pad.js for draw

  @ViewChild('annoImage') annoImage: ElementRef;
  @ViewChild('annoCanvas') annoCanvas: ElementRef;
  @ViewChild('annoContainer') annoContainer: ElementRef;


  @HostListener("window:resize") orientation(): void {
    this.annoPad.off();
    this.setupAnnotationCanvas();
  }

  constructor(private nextImageSvc: NextImageService) {
    super();
  }

  ngOnInit() {

    this.setTempValueControlSwitching(this.field.name);

    this.nextImageSvc.getImages().subscribe(images => {
      const image = images.find(img=> img.url === this.field.imageUrl);
      if (image) {
        this.secureAnnotationUrl = image.secureUrl;
      }
    });

    if (!this.form.contains(this.field.name)) {
      const initValue: any = this.initialState[this.field.name];
      const options: any = this.field.required ? { validators: [Validators.required] } : { };

      this.valueFormGroup = new FormGroup({
        Strokes: new FormControl(initValue.Strokes || [], options),
        ImageSource: new FormControl(initValue.ImageSource || this.field.imageSource),
      });
      this.form.addControl(this.field.name, new FormGroup({
        Type: new FormControl(SubmissionDataType.Drawing),
        Value: this.valueFormGroup,
      }));
    } else {
      this.valueFormGroup = this.form.get(`${this.field.name}.Value`) as FormGroup;
    }
  }

  // setup for the canvas sig_pad.js usable in the viewer
  // param resize: controls whether canvas needs to be scaled
  setupAnnotationCanvas(): void {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>this.annoCanvas.nativeElement;
    const naturalWidth = this.annoImage.nativeElement.naturalWidth;
    const naturalHeight = this.annoImage.nativeElement.naturalHeight;
    canvas.width = this.annoImage.nativeElement.offsetWidth;
    canvas.height = this.annoImage.nativeElement.offsetHeight;
    this.annoPad = new SignaturePad(canvas, {
      penColor: '#671e75',
      minWidth: 1,
      maxWidth: 3,
      onEnd: event => {
        this.saveAnnotation();
        this.setCSSUserSelect('auto');
        this.valueChanged.emit(this.field);
      },
      onBegin: event => {
        this.setCSSUserSelect('none');
      }
    });

    const naturalStrokes: any = this.annoValue = this.form.get(this.field.name).value.Value.Strokes;
    if (naturalStrokes?.length) {
      const visibleStrokes = JSON.parse(JSON.stringify(naturalStrokes));
      const xRatio: number = canvas.width / naturalWidth;
      const yRatio: number = canvas.height / naturalHeight;
      this.resizeData(visibleStrokes, xRatio, yRatio);
      this.annoPad.fromData(visibleStrokes);
      this.saveAnnotation();
    }
  }

  // store viewer canvas data and composite image in form
  // strokes are scaled to fit natural image dimensions first
  saveAnnotation(): void {
    this.annoValue = this.annoPad.toData();
    const naturalStrokes: any = JSON.parse(JSON.stringify(this.annoValue));
    const imgSrc: any = !this.field.pdfBacked ? this.createImage() : this.field.imageSource;
    const xRatio = this.annoImage.nativeElement.naturalWidth / this.annoCanvas.nativeElement.width;
    const yRatio = this.annoImage.nativeElement.naturalHeight / this.annoCanvas.nativeElement.height;
    this.resizeData(naturalStrokes, xRatio, yRatio);

    this.form.get(this.field.name).get('Value').patchValue({['Strokes']: naturalStrokes });
    this.form.get(this.field.name).get('Value').patchValue({['ImageSource']: imgSrc });
    this.updateStatus(this.form.get(this.field.name).status);
  }

  // clear canvas and wipe controls
  clearAnnotation(): void {
    this.form.get(this.field.name).get('Value').patchValue({['Strokes']: '' });
    this.annoPad.clear();
    this.annoValue = '';
    super.updateStatus(this.form.get(this.field.name).status);
    this.valueChanged.emit(this.field);
  }

  // create a composite image of draw data and backing image
  private createImage(): any {

    // setup canvas one for scaled draw data and one for final composite image
    const drawCanvas: HTMLCanvasElement = document.createElement('canvas');
    const compositeCanvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: any = compositeCanvas.getContext('2d');
    const natWidth: number = compositeCanvas.width = drawCanvas.width = this.annoImage.nativeElement.naturalWidth;
    const natHeight: number = compositeCanvas.height = drawCanvas.height = this.annoImage.nativeElement.naturalHeight;

    // scale the draw data to natural dimensions and drop it to draw-canvas
    const strokePad = new SignaturePad(drawCanvas);
    const naturalStroke: any = JSON.parse(JSON.stringify(this.annoValue));
    const xRatio: number = natWidth / this.annoCanvas.nativeElement.offsetWidth;
    const yRatio: number = natHeight / this.annoCanvas.nativeElement.offsetHeight;
    this.resizeData(naturalStroke, xRatio, yRatio);
    strokePad.fromData(naturalStroke);

    // drop backing image and draw-canvas content onto the composite canvas
    ctx.drawImage(this.annoImage.nativeElement, 0, 0, natWidth, natHeight);
    ctx.drawImage(drawCanvas, 0, 0, natWidth, natHeight);

    return compositeCanvas.toDataURL('image/jpeg', 1.0);
  }

  // scale draw data by ratio
  private resizeData(data: any, ratioX: number, ratioY: number): void {
    if (data) {
      data.forEach(function (stroke) {
        stroke.points.forEach(function (point: any) {
          point.x *= ratioX;
          point.y *= ratioY;
        });
      });
    }
  }
}
