import { Component, Input, OnInit } from '@angular/core';
import { ActivityLog } from '@next/shared/common';
import { ActivityLogService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'next-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {  
  @Input() set patientId(value: string) { 
     this.setActivityLogData(value);  
  }
  dataset: Array<ActivityLog> = []; 
  columnHeaders: any[] = [];   // primeNG Table config
  constructor(private activitylogSvc: ActivityLogService,private translateSvc:TranslateService){}
  ngOnInit() {
    this.translateSvc.get(['ACTIVITYLOG.COLUMN_HEADERS','ACTIVITYLOG.COLUMN_FIELDS']).subscribe(val => {
      const headers = val['ACTIVITYLOG.COLUMN_HEADERS'];
      this.columnHeaders = [
        { field: 'activityname', header: headers.ACTIVITYNAME },
        { field: 'status', header: headers.STATUS },
        { field: 'rundate', header: headers.RUNDATE },
        { field: 'results', header: headers.RESULTS } 
      ];
    });
    
  }
  setActivityLogData(pid){
    if(pid){    
      this.activitylogSvc.getActivityLogByPatientId(pid).subscribe((data: ActivityLog[]) => {
        this.dataset = data;
      });
    }
  }

}
