export enum MyPreferencesItemList {
  MyProfile = 'myprofile',
  MySignatureSetup = 'mysignaturesetup',
  PatientSignatureSetup = 'patientsignaturesetup',
  PrinterSetup = 'printersetup',
  DashboardDisplaySetup = 'dashboarddisplaysetup',
  SecuritySetting = 'securitysetting'
}

export enum SetupSignatureOptions {
  Id = 'ID',
  Text = 'TEXT',
  Strokes = 'STROKES',
  Initials = 'INITIALS',
  AccessCode = 'CODE',
  NPI = 'NPI'
}

export enum PreferenceTypes {
  DefaultSignatures = 'DEFAULTSIGNATURES',
  AccessCode = 'ACCESSCODE',
  Facility = 'FACILITY',
  NPI = 'NPI'
}
