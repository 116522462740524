import { Injectable } from '@angular/core';
import { v4 } from 'uuid';

// The purpose of this service is to hand out unique ids every time it's asked.  Handy for trackBy in ngFor's
@Injectable({
  providedIn: 'root'
})
export class IDService {
  public generate(): string {
    return v4();
  }
}
