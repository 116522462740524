import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {FormSubmission, SubmissionType, TaskDTO} from '@next/shared/common';
import { NextFormService, NextSubmissionService } from '@next/shared/next-services'
import { ConfirmationDialogComponent } from '@next/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { StateViewerService } from '../state/state-viewer.service';

@Injectable({
  providedIn: 'root'
})
export class FormSubmissionService {
  modalRef: BsModalRef;

  constructor(
    private submissionSvc: NextSubmissionService,
    private formSvc: NextFormService,
    private translateSvc: TranslateService,
    private modalSvc: BsModalService,
    private toastrSvc: ToastrService,
    private router: Router,
    private stateViewerSvc: StateViewerService
    ) { }

  saveForm(errorPrompt: boolean = true) {
    return new Promise((res) => {
      const form = this.stateViewerSvc.renderedForm;
      if (!form) {
        return res(false);
      }
      const metadata = {
        client: 0,
        page: 0
      };

      const payload: FormSubmission = {
        id: form.currentTask.formid,
        experienceversionid: form.experience.vid,
        submissiontype: form.submission ? form.submission.submissiontype : SubmissionType.Saved,
        updatedby: "",
        data: form.formData,
        metadata: metadata,
        taskId: form.currentTask.id,
        fileid: '',
        lastupdated: form?.submission?.lastupdated ?? null
      };

      const upsert = form.submission ?
        this.submissionSvc.update(payload) :
        this.submissionSvc.create(payload);

      return upsert.subscribe(result => {
        if (result.type === HttpEventType.Response) {
          this.latestTask(form.currentTask.id, res);
        }
      },
      error => {
        if (error.status === 409) {
          if (errorPrompt) {
            this.updateErrorPrompt(form.currentTask.id, res);
          } else {
            this.latestTask(form.currentTask.id);
          }
        }
      });
    });
  }

  latestTask(taskId, res: (value: unknown) => void = null) {
    this.formSvc.getPatientForm(taskId).subscribe(async (result) => {

      const i: number = this.stateViewerSvc.selectedTasks.findIndex(task => task.id === result.id);
      const tasks: TaskDTO[] = this.stateViewerSvc.selectedTasks;
      tasks[i] = result;
      this.stateViewerSvc._selectedTasks.next(tasks);
      this.stateViewerSvc.selectedTaskCompleted.next(true);
      this.stateViewerSvc._renderedForm.next(null);

      return res ? res(true) : true;
    });
  }

  updateErrorPrompt(taskId, res: (value: unknown) => void = null) {
    const config  = {
      initialState: {
        title: this.translateSvc.get('CONFIRMATION_FORM_CONFLICT.TITLE'),
        message: this.translateSvc.get('CONFIRMATION_FORM_CONFLICT.MESSAGE'),
        cancelButton: this.translateSvc.get('CONFIRMATION_FORM_CONFLICT.CANCEL'),
        confirmButton: this.translateSvc.get('CONFIRMATION_FORM_CONFLICT.CONFIRM')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    };
    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
    return this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.latestTask(taskId)
        this.stateViewerSvc.renderedForm = null;
        this.router.navigateByUrl(`${window.location.pathname}${window.location.search}/`, { skipLocationChange: true });
        this.toastrSvc.success(this.translateSvc.instant('CONFIRMATION_FORM_CONFLICT.MESSAGE_SUCCESS'));
        return res ? res(true): true;
      }
    }, (err) => { this.toastrSvc.error(err.message) });
  }
}
