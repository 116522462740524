<div
  [ngClass]="{
    'underline' : field.underline,
    'bold' : field.bold,
    'italics' : field.italics,
    'text-left' : field.alignment === 'left',
    'text-right' : field.alignment === 'right',
    'text-center' : field.alignment === 'center',
    'display-1' : field.headerTag === 'h1',
    'display-2' : field.headerTag === 'h2',
    'display-3' : field.headerTag === 'h3',
    'display-4' : field.headerTag ==='h4'
  }" >
  <span>{{field.text}}</span>
</div>
