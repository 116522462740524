export enum ViewerFieldType {
  TEXTBOX = 'textboxField',
  CHECKBOX = 'checkboxField',
  DROPDOWN = 'dropDownField',
  RADIOGROUP = 'radioField',
  FIELDDIVIDER = 'fieldDivider',
  FIELDSPACER = 'fieldSpacer',
  LINK = 'hyperlinkField',
  HEADER = 'fieldHeader',
  TEXTLABEL = 'labelField',
  WRITTENSIG = 'writtenSignatureField',
  POPUP = 'buttonField',
  SECTION = 'sectionField',
  PHOTO = 'pictureField',
  ANNOTATION = 'annotationField',
  RICHTEXT = 'richTextField',
  PAYMENTGATEWAY = 'paymentGateway',
  COLUMNLAYOUT = 'columnLayout'
}

export enum TextboxInputType {
  ALPHA = 'alpha',
  NUM = 'numeric',
  ALPHANUM = 'alphanumeric',
  DATE = 'date',
  CURRENCY = 'currency',
  PHONE = 'phone',
  EMAIL = 'email',
  POSTALCODE = 'postalcode',
  SOCIAL = 'ssn',
  CREDITCARDDIGITS = 'ccdigits',
  REGULAREXPRESSION = 'regularexpression',
  SHORTDATE2 = 'shortdate2',
  SHORTDATE4 = 'shortdate4'
}

export enum SignatureType {
  Default = "",
  DrawnSignature = "drawnSignature",
  TypedSignature = "typedSignature",
  Initials = "initialsSignature"
}

export enum SignatureFor {
  Default = "",
  Patient = "patient",
  Staff = "staff"
}

export enum SignatureForSource {
  None = '',
  Appointment = 'appointment',
  Patient = 'patient',
  UserGroup = 'usergroup'
}

export enum DisplayStatus {
  OPENED = 'open',
  CLOSED = 'closed'
}

export enum SignatureTimeStampLocation {
  None = '',
  TextField = 'textfield',
  InlineRight = 'inlineright',
  BottomRight = 'bottomright'
}
