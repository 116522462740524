import { Annotation, FieldType, SelectOption } from './form.model';
import { InputType } from './guided-experience.model';
import { IDService } from '../services/id.service';

export interface NextAnnotationResponse {
  pages: NextAnnotationPage[];
}

export interface NextAnnotationPage {
  annotations: PDFAnnotation[];
}

export interface PDFAnnotation {
  alignment: string;
  coordinates: any;
  fieldLocking: AnnotationFieldLocking;
  label: string;
  maxLength: string;
  multiLine: boolean;
  name: string;
  options: AnnotationOption[];
  readOnly: boolean;
  required: boolean;
  textFormat: any;
  tooltip: string;
  type: string;
  value: string;
  viewable: boolean;
}

export interface AnnotationOption {
  name: string;
  value: string;
}

export interface AnnotationFieldLocking {
  type: string;
  fields: string[];
}

export function annotationToNextAnnotation(idSvc: IDService, annot: PDFAnnotation): Annotation {
  return {
    alignment: annot.alignment || '',
    coordinates: annot.coordinates || { },
    id: idSvc.generate(),
    fieldLocking: getSignatureFieldLocking(annot),
    fieldType: annotationTypeToNextAnnotationType(annot.type),
    groupMembers: [],
    groupName: '',
    label: annot.label || '',
    maxLength: annot.maxLength || '',
    multiLine: annot.multiLine || false,
    name: annot.name,
    readOnly: annot.readOnly || false,
    required: annot.required || false,
    viewable: annot.viewable || false,
    value: annot.value || 'True',
    textFormat: getAnnotationFormat(annot),
    tooltip: annot.tooltip || '',
    selectOptions: setSelectOptions(idSvc, annot)
  }
}

export function annotationTypeToNextAnnotationType(type: string) {
  switch(type) {
    case 'textbox':
      return FieldType.TextBox;
    case 'checkbox':
      return FieldType.CheckBox;
    case 'radio':
      return FieldType.Radio;
    case 'combobox':
      return FieldType.DropDown;
    case 'button':
      return FieldType.Button;
    case 'signature':
      return FieldType.Signature;
    case 'hyperlink':
      return FieldType.Hyperlink;
  }
}

export function getSignatureFieldLocking(anno: PDFAnnotation): AnnotationFieldLocking {
  if (anno.type === 'signature' && anno.fieldLocking) {
    return {
      type: anno.fieldLocking.type || '',
      fields: anno.fieldLocking.fields,
    } as AnnotationFieldLocking;
  }
  return null;
}

export function getAnnotationFormat(anno: PDFAnnotation) {
  if (anno.type === 'textbox' && anno.textFormat) {
    if (Object.values(InputType).includes(anno.textFormat.type as InputType)) {
      return anno.textFormat.type;
    }
  } else {
    return InputType.AlphaNumeric;
  }
  return null;
}

export function setSelectOptions(idSvc: IDService, annot: PDFAnnotation): SelectOption[] {
  switch (annot.type) {
    case 'radio':
      return annot.options.map(option => { return {
        id: idSvc.generate(),
        text: option.value,
        value: option.value,
        selected: false
      }});

    case 'combobox':
      return annot.options.map(option => { return {
        id: idSvc.generate(),
        text: <any>option,
        value: <any>option,
        selected: false
      }});
  }

  return [];
}
