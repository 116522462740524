import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { GuidedExperienceDTO } from '@next/shared/common';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FormService } from '../form.service';
import { ExperienceService } from '../experience.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformExperienceResolverService {
  constructor(private formSvc: FormService, private experienceSvc: ExperienceService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<GuidedExperienceDTO> | Observable<never> {
    const formId = route.paramMap.get('formId');
    const experienceId = +route.paramMap.get('experienceId');

    let exp: Observable<GuidedExperienceDTO>;
    if (experienceId) {
      exp = this.experienceSvc.getExperience(formId, experienceId);
    } else {
      const expByFormId = this.experienceSvc.getExperiencesForForm(formId);
      const newExp = this.formSvc.getForm(formId).pipe(
        map(form => ({
          id: '',
          name: form.name,
          description: form.description,
          formId,
          logoUrl: '',
          logoHeight: 0,
          logoAlignment: "left",
          pages: [],
          pdfPages: [],
          annotations: null,
          pdftemplateid: null,
          metadata: null
        } as GuidedExperienceDTO))
      );

      exp = forkJoin([expByFormId, newExp]).pipe(
        map((result) => {
          // if there is an experience for this form, use it
          if ((result[0] as GuidedExperienceDTO[]).length > 0) {
            return (result[0] as GuidedExperienceDTO[])[0];
          }

          return result[1];
        })
      );
    }

    return exp;
  }
}
