<div>
  <div *ngIf="page.title" class="pt-2">
    <span [ngClass]="{
      'h1' : page.titleTag === 'h1',
      'h2' : page.titleTag === 'h2',
      'h3' : page.titleTag === 'h3'
    }">{{page.title}}</span>
    <hr class="mt-0">
  </div>
  <div *ngFor="let field of page.fields">
    <next-viewer-element
      [form]="form"
      [formId]='formId'
      [field]="field"
      [initialState]="initialState"
      [attachments]="attachments"
      [needsValidation]="needsValidation"
      (valueChanged)="emitValueChanged($event)">
    </next-viewer-element>
  </div>
  <div class="page-tail"></div>
</div>
