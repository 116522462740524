import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { User } from '../../signature-prompt.component';
import { TaskDTO, TaskStatus, TaskType } from '@next/shared/common';

@Component({
  selector: 'next-view-assign-to',
  templateUrl: './view-assign-to.component.html',
  styleUrls: ['./view-assign-to.component.css']
})
export class ViewAssignToComponent implements OnInit, OnDestroy {
  cleanup: Subject<void> = new Subject<void>();

  @Input() task: TaskDTO;
  @Input() users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  @Input() assignSource: string;
  @Output() closeAssignTo: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitAssignTo: EventEmitter<TaskDTO> = new EventEmitter<TaskDTO>();

  usersList: User[] = [];
  assignTarget: string;

  ngOnInit(): void {
    this.users.pipe(
      takeUntil(this.cleanup),
      tap((list: User[]) => {
        this.usersList = list;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.cleanup.next(null);
    this.cleanup.complete();
  }

  /**
   * Create a TaskDTO for the selected User
   */
  assignToSubmit(): any {
    const t: TaskDTO = this.task;
    const source = this.assignSource;
    const target = this.assignTarget;

    const staffTask: TaskDTO = {
      assignto: target,
      name: t.name,
      formid: t.formid,
      experienceid: t.experienceid,
      experienceversionid: t.experienceversionid,
      pdftemplateid: t.pdftemplateid,
      issuedon: (new Date()).toDateString(),
      completedon: null,
      issuedby: source,
      type: TaskType.StaffUser,
      status: TaskStatus.NotStarted
    };

    this.submitAssignTo.emit(staffTask);
  }

}
