import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import {  filter, map } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    if(req.url.toLowerCase().includes("/api/collection/bundle")){
        const etag = localStorage.getItem("collectionBundleEtag")?? '0';
        req =  req.clone({setHeaders:{ "If-None-Match": etag}})
    }

    return next.handle(req).pipe(
      filter(event=> event instanceof  HttpResponse),map((event: HttpResponse<any>)=>{

      if(event.url.toLowerCase().includes("/api/collection/bundle")){

        const data  = JSON.parse( localStorage.getItem("collectionBundleData"));
        const etag =event.headers.get("etag") ?? '0';

        if(etag.toString() === localStorage.getItem("collectionBundleEtag") ?? '' ){
          return event.clone({body:data});
        }

        localStorage.setItem("collectionBundleEtag", etag);
        localStorage.setItem("collectionBundleData",  JSON.stringify( event.body));
      }
      return event;
    }));

  }
}
