import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedUiModule } from '@next/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagSearchComponent } from './tag-components/tag-search/tag-search.component';
import { TagPillComponent } from './tag-components/tag-pill/tag-pill.component';
import { ProcessesTabComponent } from './processes-tab/processes-tab.component';
import { AddFormsModalComponent } from './add-forms-modal/add-forms-modal.component';
import { FormsTableComponent } from './forms-table/forms-table.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccessCodePromptComponent } from './access-code-prompt/access-code-prompt.component';
import { SignaturePromptComponent } from './signature-prompt/signature-prompt.component';
import { SelectAppointmentComponent } from './select-appointment-modal/select-appointment-modal.component';
import { PdfFormViewerComponent } from './pdf-form-viewer/pdf-form-viewer.component';
import { ManageTagsComponent } from './tag-components/manage-tags/manage-tags.component';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { NoSelectionComponent } from './no-selection/no-selection.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { BeforeLoginNotificationComponent } from './before-login-notification/before-login-notification.component';
import { PickListModule } from 'primeng/picklist';
import { GridColumnModalComponent } from './grid-column-modal/grid-column-modal.component';
import { GridSettingModalComponent } from './grid-setting-modal/grid-setting-modal.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { FormSubmissionService } from './services/form-submission.service';
import { GridConfigurationComponent } from './grid-configuration/grid-configuration.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ViewAssignToComponent } from './signature-prompt/components/view-assign-to/view-assign-to.component';
import { ViewBatchSignComponent } from './signature-prompt/components/view-batch-sign/view-batch-sign.component';
import { FormViewComponent } from './forms-table/components/form-view/form-view.component';
const components = [
  ManageTagsComponent,
  TagSearchComponent,
  TagPillComponent,
  ProcessesTabComponent,
  AddFormsModalComponent,
  FormsTableComponent,
  SelectAppointmentComponent,
  SignaturePromptComponent,
  AccessCodePromptComponent,
  PdfFormViewerComponent,
  BarcodeScannerComponent,
  DashboardMenuComponent,
  NoSelectionComponent,
  NotificationModalComponent,
  BeforeLoginNotificationComponent,
  GridColumnModalComponent,
  GridSettingModalComponent,
  ActivityLogComponent,
  GridConfigurationComponent,
  ViewAssignToComponent,
  ViewBatchSignComponent,
  FormViewComponent
];
const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  imports: [
    MenuModule,
    InputTextModule,
    NgSelectModule,
    DialogModule,
    TableModule,
    CommonModule,
    SharedUiModule,
    TranslateModule,
    AlertModule.forRoot(),
    FontAwesomeModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule,
    TooltipModule.forRoot(),
    PickListModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [...components ],
  exports: [...components ],
  entryComponents: [SelectAppointmentComponent, BarcodeScannerComponent, ManageTagsComponent, AddFormsModalComponent],
  providers: [FormSubmissionService]
})

export class NextClinicalFeatureSharedModule {}
