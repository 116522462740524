import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(value: any[], order = '', column: string = ''): any[] {
    // Copy value so sort can maniuplate it in 'strict' mode
    value = [...value];

    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') {
      if(order==='asc'){return value.sort()}
      else{return value.sort().reverse();}
    } // sort 1d array

    const desc = order?.toLowerCase() === 'desc';

    return value.sort((a, b) =>
      this.getValue(a, column) < this.getValue(b, column)
        ? (desc ? 1 : -1)
        : (desc ? -1 : 1));
  }

  // example:  column = 'a.b' => item.a.b
  getValue(value: any, column: string) {
    return column.split('.').reduce((a, b) => a[b], value)?.toLowerCase();
  }
}
