export interface AuthToken {
  connection: string;
  exp: string;
  iat: string;
  integration?: any;
  iss?: string;
  permissions?: any;
  scope: string;
  sub: string;
}

export interface AuthProvider {
  name: AuthProviderName;
  type: string;
  authorization_endpoint: string;
  token_endpoint: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  scopes: string;
}

export interface AuthProvider_SF extends AuthProvider {
  instance_url: string;
  version: string;
  logout_uri: string;
  userinfo_endpoint: string;
}

export interface AuthProvider_AZ_PRO extends AuthProvider {
  authority: string;
  logout_uri: string;
}

export interface AuthProvider_AZ_DEV extends AuthProvider {
  authority: string;
  logout_uri: string;
}

export interface AuthProvider_SMART extends AuthProvider {
  instance_url: string;
  version: string;
  userinfo_endpoint: string;

}

export enum AuthProviderName {
  SALESFORCE = 'salesforceConnection',
  AZURE = 'azureConnection',
  AZURE_DEV = 'azureConnection',
  SMART = 'smartConnection'
}

export interface User {
  firstName: string;
  lastName: string;
  oid?: string;
  integration?: string;
}

export interface UserSF extends User {
  active?: boolean;
  address?: any;
  custom_permissions?: any;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  is_app_installed?: boolean;
  language?: string;
  locale?: string;
  name?: string;
  nickname?: string;
  organization_id?: string;
  photos?: { picture, thumbnail };
  picture?: string;
  preferred_username?: string;
  profile?: string;
  sub?: string;
  updated_at?: string;
  urls?: { custom_domain, enterprise, feed_elements, feed_items, feeds, groups, metadata, partner, profile, query, recent, rest, search, sobjects, tooling_rest, tooling_soap, users }
  user_id?: string;
  user_type?: string;
  utcOffset?: number;
  zoneinfo?: string;

}

/**___Integration___
 * ***************************
 * Enum for integration values.
 * @readonly
 * @enum {string}
 */
export enum Integration {
  SALESFORCE_RPI = 'integration:salesforce'
}
