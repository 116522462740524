import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ViewerService } from '../state/viewer.service';
import {
  NextAdminService,
  NextSubmissionService,
  SignatureService,
} from '@next/shared/next-services';
import {
  FieldDTO,
  FormSubmission,
  GuidedExperienceDTO,
  SubmissionType,
  WindowMessageEventName,
  SignatureType,
  SubmissionMetadata,
  GxProcessing,
  TokenService,
  GuidedExperienceInstanceDTO,
  Attachment,
  TaskDTO,
  WrittenSigDTO,
  ViewerFieldType,
} from '@next/shared/common';
import * as platform from 'platform';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'next-pdf-viewer',
  templateUrl: './viewer-pdf.component.html',
  styleUrls: ['./viewer-pdf.component.css'],
  /* This will cause a viewer service to instantiate every time this Component is created.
   * We do this to reset the internal state fresh each time you navigate to the tool */
  providers: [ViewerService]
})

export class ViewerPdfComponent implements OnInit, OnDestroy {
  @ViewChild('iframe', {static: true}) iframe: ElementRef;
  obsCleanup: Subject<void> = new Subject<void>();

  @Output() pdfViewerEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() instance$: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);

  @Input() isEmbedded: boolean = false;
  @Input() isPatientView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @Input() isFormValidForCurrentView: boolean = true;
  @Input() isFormValidForAll: boolean = true;
  RequiredClinicianSignatures: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  taskId: string;
  userId: string = ''
  loading: boolean = true;

  formData: unknown;
  experience: GuidedExperienceDTO;
  fields: FieldDTO[];
  task: TaskDTO;
  prefill: unknown = { };
  submission: FormSubmission;
  attachments: Attachment[];

  constructor (
    private adminSvc: NextAdminService,
    private viewerSvc: ViewerService,
    private submissionSvc: NextSubmissionService,
    private zone: NgZone,
    private translateSvc: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private gxProcessing: GxProcessing,
    private signatureService: SignatureService,
    private tokenService: TokenService,
    private toastSvc: ToastrService
  ) { }

  ngOnInit(): void {
    (<any>window).loadAnnotationState = this.loadAnnotationState.bind(this);
    (<any>window).loadGXData = this.loadGXData.bind(this);

    this.instance$.pipe(
      filter(instance => !!instance),
      filter(instance => !!instance.experience),
      tap(instance => {
        this.experience = instance.experience;
        this.fields = this.gxProcessing.getAllFields(instance.experience);
        this.task = instance.task;
        this.taskId = instance.task?.id;
        this.prefill = instance.prefill;
        this.submission = instance.submission;
        this.attachments = instance.attachments;
      }),
      tap(instance => {
        this.gxProcessing.getViewerDataAndConfig(instance.experience, instance.submission?.data || { }, instance.prefill || '')
          .then(
            (result) => {
              this.formData = result.data;
              if (this.isEmbedded) this.novaLoadPdf();
              else this.loadPdf();
            }, (reason) => this.toastSvc.error(reason, this.translateSvc.instant('ERRORS.LOAD_VIEWER_ERR')))
      }),
      takeUntil(this.obsCleanup)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.formData = undefined;
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  /**
   * Triggered by the Viewer's webviewerloaded event.
   */
  public async onViewerLoad(): Promise<void> {
    const viewerApp = this.iframe.nativeElement.contentWindow?.PDFViewerApplication;

    // Wait for initialization to complete so that we can access the Event Bus
    viewerApp?.initializedPromise.then(() => {
      this.zone.run(() => this.loading = false);

      // Hook Event Listeners
      if (this.isEmbedded) {
        let pagesRendered = [];

        viewerApp.eventBus?.on('pagerendered', (e) => {
          this.zone.run(() => {
            pagesRendered.push(e);
            if (pagesRendered.length) {
              viewerApp.eventBus.dispatch('firstpage');
              this.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.RenderedPDF, viewerApp: viewerApp });
              pagesRendered = [];
            }
          });
        });

        viewerApp.eventBus?.on('fieldchange', () => {
          this.zone.run(() => {
            this.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.FieldChanged, experience: this.experience, formData: this.formData });
          });
        });

        viewerApp.eventBus.on('signature-signed', () => {
          this.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.FieldChanged, experience: this.experience, formData: this.formData });
        });

        viewerApp.eventBus.on('sign', (e) => {
          this.zone.run(() => {
            const signatureDTO = e.data.GXField || { };
            this.pdfViewerEmitter.emit({
              eventName: WindowMessageEventName.Signature,
              signatureProperties: {
                signatureFor: signatureDTO.signatureFor || 'patient',
                signatureType: signatureDTO.signatureType || 'drawn',
                signatureName: signatureDTO.name || e.data.fieldName
              }
            });
          });
        });
      }
      viewerApp.eventBus.on('submitform', this.submit.bind(this, SubmissionType.Submitted));
      viewerApp.eventBus.on('fieldchanged', this.onFieldChange.bind(this));
      viewerApp.eventBus.on('staff-signature', this.onStaffSignature.bind(this));
      viewerApp.eventBus.on('validationchanged', this.onValidationChanged.bind(this));
      viewerApp.eventBus.on('allrequiredfieldschanged', this.onAllRequiredFieldsChanged.bind(this));
    });
  }

  /**
   * Loads the HTML Viewer in our iframe, specifying the location of the PDF to load
   */
  private loadPdf() {
    this.iframe.nativeElement.src = `assets/pdfjs/web/viewer.html?file=${encodeURIComponent(this.experience.pdftemplate.url)}`;
  }

  novaLoadPdf(): void {
    const isCurrentUserRequiredToSign = (fieldName, assignedSignatures): boolean => {
      const assignedSignature = assignedSignatures.find(a => a.fieldname === fieldName);
      return assignedSignature
        ? assignedSignature.assigntoid === this.tokenService.getCurrentUserId() || this.tokenService.isIdInCurrentUsersGroups(assignedSignature.assigntoid)
        : false;
    };

    (<any>window).isEmbedded = this.isEmbedded;
    this.isPatientView.pipe(
      takeUntil(this.obsCleanup)
    ).subscribe((result) => (<any>window).isPatientView = result);
    this.RequiredClinicianSignatures.pipe(
      takeUntil(this.obsCleanup)
    ).subscribe((result) => (<any>window).RequiredClinicianSignatures = result);
    this.activatedRoute.data.pipe(
      takeUntil(this.obsCleanup)
    ).subscribe(data => {
      if (data.task) {
        this.taskId = data.task.id;
      }
    });

    if (!this.isPatientView.value) {
      this.signatureService.getAssignedSignatures(this.experience.vid, this.viewerSvc.formID).subscribe(assignedSignatures => {
        const signatures: string[] = [];
        for (const field of this.fields.filter(f => f.type === ViewerFieldType.WRITTENSIG)) {
          const typedField: WrittenSigDTO = field as WrittenSigDTO;
          if (typedField.signatureFor === 'staff' && typedField.required && isCurrentUserRequiredToSign(typedField.name, assignedSignatures)) {
            signatures.push(typedField.name);
          }
        }
        this.RequiredClinicianSignatures.next(signatures);
        this.loadPdf();
      });
    }
  }

  /**
   * Sets a global annotation variable that the Viewer will access for loading the initial state.
   * This is called directly by the Viewer and will block until complete.
   * This required a modification to the viewer.
   */
  public loadAnnotationState(): unknown {
    let data = {};

    if (this.viewerSvc.form) {
      return this.viewerSvc.form.value;
    }
    else if (this.formData) {
      data = this.formData;
    }
    return data;
  }

  public loadGXData(): FieldDTO[] {
    return this.gxProcessing.getAllFields(this.experience);
  }

  /**
   * Triggered from the Viewer toolbar, this will submit the form to our API
   */
  public async submit(submissionType: SubmissionType, postMessage: boolean = true): Promise<void> {
    const processedData: any = await this.gxProcessing.processFields(this.experience, this.viewerSvc.formID, 0, this.formData, this.submissionSvc);
    const submitData: any = await this.viewerSvc.processCalculations(this.experience, submissionType, this.submission?.data || { }, processedData,this.prefill || '');

    // Append prefill data to the form data if it exists
    if (this.prefill && Object.keys(this.prefill).length) {
      Object.assign(submitData, {
        _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill
      });
    }

    const payload: FormSubmission = {
      id: this.submission?.id || this.viewerSvc.formID || null,
      experienceversionid: this.experience.vid,
      submissiontype: submissionType,
      updatedby: '',
      fileid: null,
      data: submitData,
      metadata: {
        client: platform,
        page: 0
      } as SubmissionMetadata,
      taskId: this.task?.id || this.taskId || null,
      lastupdated: this.submission?.lastupdated || null
    };


    // If there is an existing submission, update it
    // Else, create a new form.  A formId may or may not have been provided.
    const upsert = this.submission
      ? this.submissionSvc.update(payload)
      : this.submissionSvc.create(payload);

    upsert.subscribe(result => {
      if (result.type === HttpEventType.Response) {
        this.submission = result.body; // Store last submission
        this.location.go(this.router.createUrlTree([], {
          relativeTo: this.activatedRoute, queryParams: { formId: result.body.id }, queryParamsHandling: 'merge' }
        ).toString());

        if (this.isEmbedded && this.pdfViewerEmitter) {
          this.pdfViewerEmitter.emit({
            eventName: (submissionType === SubmissionType.Saved) ? WindowMessageEventName.ExperienceSave : WindowMessageEventName.ExperienceSubmit,
            formId: this.submission?.id,
            taskId: this.task?.id || this.taskId
          });
        }
        if (postMessage && window.parent) {
          window.parent.postMessage({
            eventName: (submissionType === SubmissionType.Saved) ? WindowMessageEventName.ExperienceSave : WindowMessageEventName.ExperienceSubmit,
            formId: this.submission?.id,
            taskId: this.task?.id || this.taskId
          },'*');
        }
        if (postMessage && window.opener) {
          window.opener.postMessage({
            eventName: (submissionType === SubmissionType.Saved) ? WindowMessageEventName.ExperienceSave : WindowMessageEventName.ExperienceSubmit,
            formId: this.submission?.id,
            taskId: this.task?.id || this.taskId
          }, '*');
        }
      }
    },
    error => {
        if (this.isEmbedded && error.status === 409) {
          this.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.SubmitError, formId: payload.id });
        }
    });
  }

  private onFieldChange(evt) {
    // The input element that triggered the change
    const viewerDocument = this.iframe.nativeElement.contentWindow.document;
    const inputEls = viewerDocument.getElementsByName(evt.source.data.fieldName);

    const inputEl = inputEls.length === 1
      ? inputEls[0]                                                 // The element that was updated
      : [].find.call(inputEls, el => el.checked === true);  // Get the one that is checked.  Should work on ES5

    // The current state of our Form Data - This will be updated
    const state = this.formData;
    this.updateStateFromElement(state, inputEl);

    // The field object that contains an optional Calculation function
    // This may be null if a field exists in the PDF that does not in the Exp.
    const field = this.fields.find(obj => obj.name === evt.source.data.fieldName );

    // If the field exists, we want to run its calc function and update all affected fields in the Viewer
    if (field) {
      this.runFieldCalculation(field, state);
    }

    if (this.isEmbedded) {
      this.pdfViewerEmitter.emit({
        eventName: WindowMessageEventName.FieldChanged,
        experience: this.experience,
        formData: this.formData,
        submission: this.submission
      });
    }
  }

  /**
   * The viewer requests for staff signature,
   * if valid access code, fill the signature
   * with the saved signature for this staff.
   * Dispatch the filled signature back to
   * the viewer.
   * @param event
   * @private
   */
  public async onStaffSignature(event) {
    let value = null;
    try {
      if (event.applySignature) {
        value = {
          Type: event.Type,
          Value: event.Value
        }
      } else {
        const uuid = event.id;
        const signatureType = event.signatureType;

        const serverAccessCode = await this.adminSvc.getPreference(uuid, 'ACCESSCODE').pipe(map(res => res[0].data.ACCESSCODE)).toPromise();
        if (serverAccessCode === event.code) {
          const signatureDefaults = await this.adminSvc.getPreference(uuid, 'DEFAULTSIGNATURES').pipe(map(res => res[0].data)).toPromise();
          switch (signatureType) {
            case SignatureType.Initials:
              value = signatureDefaults.INITIALS;
              break;
            case SignatureType.TypedSignature:
              value = signatureDefaults.TEXT;
              break;
            case SignatureType.DrawnSignature:
              value = signatureDefaults.STROKES;
              break;
          }
        }

        if (value && value.length) {
          const valueProperty = Array.isArray(value) ? { Strokes: value, SignedDate: new Date() } : { Text : value, SignedDate: new Date() };
          const typeProperty = Array.isArray(value) ? 'Signature' : 'TypedSignature';
          value = {
            Type: typeProperty,
            Value: valueProperty
          }
        }
        else {
          const res = await this.translateSvc.get('ERRORS.EVENT_ERROR', { event: event.id || 'null', code: event.code || 'null'  }).toPromise();
          value = { err: res }
        }
      }
    }
    catch (err) {
      value = { err: JSON.stringify(err) };
    }
    finally {
      // Send an event to the PDF Viewer
      // notifying of filled staff signature
      const viewerApp = this.iframe.nativeElement.contentWindow.PDFViewerApplication;
      viewerApp.eventBus.dispatch('signature-signed', value);
    }
  }


  public async onValidationChanged(event) {
    this.isFormValidForCurrentView = event.source.isValid;
  }


  public async onAllRequiredFieldsChanged(event) {
    this.isFormValidForAll = event.source.isEntireFormValid;
  }

  /**
   * Run the optional OnChange calculation for a specific field.
   * This will do three things:
   *    1. Run the calculation resulting in field changes
   *    2. Update our form data state with the new field values
   *    3. Update the Viewer elements with that same information
   *
   * A calculation will NOT trigger another OnChange calculation.
   * @param field
   * @param state
   */
  private runFieldCalculation(field: FieldDTO, state: any) {
    // Run Change Calculation on Field
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const componentRef = this;
    this.viewerSvc.getCalculationData(field.calculations.onChange, this.experience, state, this.prefill).then(function(updatedValues) {
      // Not every field has a calculation, and not every calculation may trigger a field update
      if (updatedValues) {
        const viewerDocument = componentRef.iframe.nativeElement.contentWindow.document;

        // Iterated all fields that were updated as part of this calculation
        for (const key in updatedValues.fields) {
          // eslint-disable-next-line no-prototype-builtins
          if (!updatedValues.fields.hasOwnProperty(key)) continue; // Resolve Lint error

          const updatedField = updatedValues.fields[key];
          state[key] = updatedField;

          // Update the affected field in the PDF Viewer
          const updatedEls = viewerDocument.getElementsByName(key);
          if (!updatedEls.length) continue; // Protect against a bad script field

          // Some elements are grouped, like radio buttons, and need to be processed specially.
          if (updatedEls.length > 1) {

            // Process Radio group - Find the specific input element by value
            const elements: any[] = Array.from(updatedEls);
            const selectRadio = elements.find(obj => obj.type === 'radio' && obj.value === updatedField.Value.Text);

            // If it was found, check it
            if (selectRadio) {
              selectRadio.checked = true;
            }
          }
          else {

            // A regular field - One element that represents the field (unlike Radio).
            const updateEl = updatedEls[0];

            switch (updateEl.type)
            {
              case 'checkbox':
                updateEl.checked = updatedField.Value.Text !== '';
                break;
              default:  // text, select, textarea
                updateEl.value = updatedField.Value.Text;
            } // switch
          }

        } // For all updated fields
        componentRef.experience = componentRef.gxProcessing.getUpdatedConfig(componentRef.experience, updatedValues.configs);

        // Send an event to the PDF Viewer with just the updated configs
        const viewerApp = componentRef.iframe.nativeElement.contentWindow.PDFViewerApplication;
        viewerApp.eventBus.dispatch('updateconfigs', Object.keys(updatedValues.configs).map(function (key) { return updatedValues.configs[key]; }));
      } // If fields have been updated
    });
  }


  /**
   * Update the form data state with form element data found in each annotation layer
   * NOTE: Currently not used, but could be useful in the future.
   * @param state
   */
  private updateStateFromElements(state) {
    // Get all annotation layers that have been rendered
    const viewerDocument = this.iframe.nativeElement.contentWindow.document;
    const annotLayers = viewerDocument.getElementsByClassName('annotationLayer');

    // Iterate through each annotation layer
    for (const annotLayer of annotLayers) {

      // Note: For radio, we are only getting back the specific element that was selected
      const inputEls = annotLayer.querySelectorAll("input[type='text'], input[type='checkbox'], input[type='radio']:checked, select, textarea");
      for (const inputEl of inputEls) {

        this.updateStateFromElement(state, inputEl);
      }
    } // For all annotation layers
  }


  /**
   * Update the form data state with data stored in a specific element
   * @param state
   * @param inputEl
   */
  private updateStateFromElement(state, inputEl) {

    // Get field object that represents the field data.  This includeds Type, Value, etc.
    const fieldValue = state[inputEl.name];
    if (!fieldValue) return; // Field state should be defaulted by pdfjs

    // Update the field value directly from the input element.
    // Different types of elements need to be processed differently.
    switch (inputEl.type)
    {
      case 'checkbox':
        fieldValue.Value.Text = inputEl.checked ? inputEl.value : '';
        break;
      default:  // text, select, textarea, radio
        fieldValue.Value.Text = inputEl.options ? inputEl.options[inputEl.selectedIndex].value : inputEl.value;
    } // switch
  }

  @HostListener('document:webviewerloaded', ['$event'])
  webViewerLoaded(event) {
    if (event.type === 'webviewerloaded') {
      this.onViewerLoad();
    }
  }
}
