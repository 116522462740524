import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  ApiService,
  Form,
  FormFastConfig,
  FormFastConfigService,
  NextAnnotationResponse,
  TaskDTO,
  TokenService,
  UploadFile
} from '@next/shared/common';

@Injectable({
  providedIn: 'root'
})
export class NextFormService extends ApiService {
  validFormTypes: string[] = ['application/pdf'];

  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService,
    private http: HttpClient
  ) {
    super(config, tokenService);
  }

  getPdfBinaryData(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(map((res: any) => {
      return new Blob([res], { type: 'application/pdf', endings: 'native' });
    }));
  }

  getPdf(id: string): Observable<Form> {
    return this.protectedEndpoint<Form>(
      this.http.get<Form>(`${this.config.apiUrl}pdftemplate/${id}`, this.getHeaders())
    );
  }

  downloadForm(url: string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.http.get(url, {
      headers: headers, responseType: 'blob'
    });
  }

  getPhiFormFile(id: string): Observable<Form> {
    return this.protectedEndpoint<Form>(
      this.http.get<Form>(`${this.config.apiUrl}form/${id}/file`, this.getHeaders())
    );
  }

  generateForm(data: any): Observable<string> {
    return this.protectedEndpoint<string>(
      this.http.post<string>(`${this.config.apiUrl}form/generateform`, data, this.getHeaders())
    );
  }

  getForms(): Observable<Form[]> {
    return this.protectedEndpoint<Form[]>(
      this.http.get<Form[]>(`${this.config.apiUrl}pdftemplate`, this.getHeaders())
    );
  }

  getFormFields(id: string): Observable<NextAnnotationResponse> {
    return this.protectedEndpoint<NextAnnotationResponse>(
      this.http.get<NextAnnotationResponse>(`${this.config.apiUrl}pdftemplate/${id}/schema`, this.getHeaders())
    );
  }

  getPatientForm(id): Observable<TaskDTO> {
    return this.protectedEndpoint<TaskDTO>(
      this.http.get<TaskDTO>(`${this.config.apiUrl}task/${id}`, this.getHeaders())
    );
  }

  addPatientForm(patientForm: TaskDTO) {
    return this.protectedEndpoint<TaskDTO>(
      this.http.post<TaskDTO>(`${this.config.apiUrl}task`, patientForm, this.getHeaders())
    );
  }

  addPatientForms(patientForms: TaskDTO[]) {
    return this.protectedEndpoint<TaskDTO[]>(
      this.http.post<TaskDTO[]>(`${this.config.apiUrl}task/bulk`, patientForms, this.getHeaders())
    );
  }

  deletePatientForm(patientForm: TaskDTO): any {
    return this.protectedEndpoint<NextAnnotationResponse>(
      this.http.delete<NextAnnotationResponse>(`${this.config.apiUrl}task/${patientForm.id}`, this.getHeaders())
    );
  }

  deletePatientForms(ids: string[]): Observable<any[]> {
    const options = Object.assign(this.getHeaders(), {body: ids});
    return this.protectedEndpoint<any>(
      this.http.delete<any>(`${this.config.apiUrl}task/bulk`, options)
    );
  }

  patchPatientFormStatus(task: TaskDTO): any {
    return this.protectedEndpoint<TaskDTO>(
      this.http.patch<TaskDTO>(`${this.config.apiUrl}task/${task.id}`, task, this.getHeaders())
    );
  }

  upload(files: UploadFile[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.progress = 0;
      if (!this.isValidFileType(file.file)) {
        file.status = -1;
      } else {
        const formData: FormData = new FormData();
        formData.append('name', file.file.name.replace('.pdf', ''));
        formData.append('description', file.file.name);
        formData.append('pdf-form', file.file, file.file.name);
        const req = new HttpRequest('POST', `${this.config.apiUrl}pdftemplate`, formData, this.getUploadHeaders());
        this.protectedEndpoint<any>(this.http.request(req)).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              files[i].progress = Math.ceil(+(event.loaded / event.total).toFixed(2) * 100);
            }
          },
          err => {
            files[i].status = 0;
            files[i].progress = 0;
          },
          () => {
            files[i].status = 1;
            files[i].progress = 0;
          }
        );
      }
    }
  }

  getFormTasksAssignTo(id): Observable<any> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}formtask?assigned=${id}`, this.getHeaders())
    );
  }

  private isValidFileType(file: File): boolean {
    return this.validFormTypes.includes(file.type.toString());
  }
}
