<div
  [ngClass]="{
    'underline' : field.underline,
    'bold' : field.bold,
    'italics' : field.italics,
    'text-left' : field.alignment === 'left',
    'text-right' : field.alignment === 'right',
    'text-center' : field.alignment === 'center'
  }"
  [class]="field.size"
  class="lead">{{field.text}}
</div>
