import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormSubmission } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextSubmissionService } from '../submission.service';

@Injectable({
  providedIn: 'root',
})
export class NextSubmissionResolverService {
  constructor(private submissionSvc: NextSubmissionService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<FormSubmission> | Observable<never> {
    if (route.queryParams["formId"]) {
      return this.submissionSvc.getSubmission(route.queryParams["formId"]).pipe(
        catchError(err => {
          // If Empty, return null
          if (err.status === 404) {
            return of(null);
          }

          // If the call actually failed, let the caller know.
          return throwError(err);
        })
      );
    }

    return of(null);
  }
}
