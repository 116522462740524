<div class="form-group rounded" [formGroup]="form">
  <label for="{{field.name}}" class="mb-0 w-100 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required bold">*</span>
  </label>
  <div #annoContainer class="position-relative annotation-container">
    <div class="border rounded position-relative"  [ngClass]="{
    'border-success':form.get(field.name).valid && field.required,
    'border-danger': form.get(field.name).invalid }">
      <img #annoImage [attr.src]="this.secureAnnotationUrl | secure | async" (load)="setupAnnotationCanvas()" style="width: 100%" alt="">
      <canvas #annoCanvas id="{{field.name}}"  class="cursor-crosshair position-absolute annotation-canvas"></canvas>
    </div>
    <button type="button" class="btn btn-danger mt-1" (click)="clearAnnotation()" [attr.disabled]="!annoValue?.length || null">
      <fa-icon [icon]="['fas', 'ban']"></fa-icon>
      <span class="font-weight-bolder">&nbsp;&nbsp;{{ 'ANNOTATIONS.DISCARD' | translate }}</span>
    </button>
  </div>
</div>
