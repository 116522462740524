import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FieldDTO, Section } from '@next/shared/common';
import { FormGroup } from '@angular/forms';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})

export class SectionComponent extends FieldBaseComponent implements OnInit, AfterViewInit {

  @Input() form: FormGroup;
  @Input() field: Section;
  @Input() initialState: any;
  @Input() needsValidation: boolean;

  private dynamicChildren: FieldDTO[];

  isValid: boolean;
  hasInput: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.dynamicChildren = this.getAllSubFields(this.field.fields) || [];
    this.hasInput = this.dynamicChildren.filter(c => c.name).length > 0;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isValid = this.hasAtLeastOneValidField(this.dynamicChildren);
    });
  }

  childStatusReceived(event): void {
    setTimeout(() => {
      this.isValid = this.hasAtLeastOneValidField(this.dynamicChildren);
    });
  }

}
