import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService, FormFastConfig, FormFastConfigService } from '@next/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'next-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  logoutModal: BsModalRef;
  returningUrl: string;
  logoutInactivity: any;
  isMainComponent: any;
  isSessionEnded: any;

  @Output() isLoggedOutEvent: EventEmitter<boolean> = new EventEmitter();
  isLoggedOut: boolean;
  
  constructor(private translateSvc: TranslateService, private toastr: ToastrService, private tokenService: TokenService, private modalService: BsModalService,
    private activatedRoute: ActivatedRoute, @Inject(FormFastConfigService) private config: FormFastConfig) { }

  ngOnInit(): void {
    this.isMainComponent = this.activatedRoute.snapshot.queryParamMap.get('isMainComponent');
    this.logoutInactivity = this.activatedRoute.snapshot.queryParamMap.get('logoutInactivity'); 
    this.returningUrl = this.activatedRoute.snapshot.queryParamMap.get('currentUrl');

    // loggedout came from the logout redirect
    if (window.location.pathname === '/logout/sessionended') {
      this.isLoggedOut = true;
      this.modalService.hide(1);
      this.toastr.clear();
      return;
    }

    if (!this.activatedRoute.snapshot.paramMap.get("auto")) {
      this.processModalLogout();
    } else {
      this.logout();
    }
  }

  processModalLogout() {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        title: this.translateSvc.get('LOGOUT.TITLE'),
        message: this.translateSvc.get('LOGOUT.CONFIRM_MESSAGE'),
        cancelButton: this.translateSvc.get('LOGOUT.CANCEL_BUTTON'),
        confirmButton: this.translateSvc.get('LOGOUT.OK_BUTTON')
      }
    }
    this.logoutModal = this.modalService.show(ConfirmationDialogComponent, config);
    this.logoutModal.content.onClose.subscribe(result => {
      if (result) {
        this.isMainComponent = true;
        this.logout();
      } else {
        // send isLoggedOut event to the router
        this.isLoggedOutEvent.emit(false);
      }
    });
  }

  logout() {

    // Logout the authenticator
    const urlParams = new URLSearchParams(window.location.search);
    let logoutURl = `${this.config.apiUrl}auth/oauth/logout?redirectUrl=${window.location.origin}/logout/sessionended`;
    if (urlParams && urlParams.get('connection')) {
      logoutURl += `&connection=${urlParams.get('connection')}`;
    }

    this.isLoggedOut = true;
    this.modalService.hide(1);
    this.toastr.clear();

    // Clear the token
    this.tokenService.clear();
    this.tokenService.clearLastAction();

    // This is artifically added so when the user hits the back button,
    // it will stay on this page.
    history.pushState(history.state, '', '/logout/sessionended');
    
    if (this.isMainComponent) {
      // send isLoggedOut event to the router
      this.isLoggedOutEvent.emit(true);
    }

    window.location.href = logoutURl;
  }

  login() {
    // This will navigate the user to the base url
    window.location.href = this.returningUrl && this.returningUrl !== '/logout/sessionended' ? window.location.origin + this.returningUrl : window.location.origin;
  }
}
