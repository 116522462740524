export interface ConfigTableInterface {
  id: string,
  label: string,
  type: string,
  data: any,
  updatedon: string,
  createdon: string
}

export enum ConfigType {
  Hl7 = 'HL7 Configuration',
  Archival = 'Archival',
  DataMapping = 'Data Mapping',
  Labels = 'Enterprise Labels',
  AutoSign = 'Auto Sign',
  Notification = 'Notification',
  NotificationBackup = 'Notification Backup'
}

export enum EnterpriseLabels {
  Dashboard = 'dashboard',
  Patients = 'patients',
  Appointments = 'appointments',
  Forms = 'forms'
}


export enum ContactAssociation {
  Facility = 'Facility',
}

export interface EnterpriseSettings {
  dashboard: string,
  patients: string, 
  appointments: string,
  forms: string,
  timeoutValue: number
}

export interface PreferenceSubmission {
  id: string,
  userid: string,
  type: string,
  data: any
}

export interface GridSetting {
  id?: string,
  userid: string,
  settingname: string,
  displaycolumn: any,
  sortby: any,
  filterby: any,
  screen: string,
  updatedon?: Date,
  createdon?: Date
}

export interface GridColumn {
  id: string,
  columnname: string,
  friendlyname: string,
  isvisible: boolean,
  isdefault: boolean,
  screen: string,
  updatedon: Date,
  createdon: Date
}

export interface NotificationSettings {
  id: string,
  loginscreentext: string,
  loginscreenstart: Date,
  loginscreenend: Date,
  inapptext: string,
  inappstart: Date,
  inappend: Date
}

export enum SettingEnum {
  Appointment = 'Appointments',
  Patient  = 'Patients',
  Form = "Forms"
}

export enum ConfigEnum {
  Id = 'id',
  Label = 'label',
  Type = 'type',
  Data = 'data',
  UpdatedOn = 'updatedon',
  CreatedOn = 'createdon'
}

export enum Hl7ConfigEnum {
  Id = 'id',
  Label = 'label',
  Type = 'type',
  Event = 'event',
  Namespace = 'namespace',
  Mapping = 'mapping',
  Data = 'data'
}

export enum Hl7ConfigMappingEnum {
  Label = 'label',
  Name = 'name',
  ReferenceId = 'referenceId',
  Action = 'action',
  Fields = 'fields',
  Type = 'type'
}

export enum Hl7ConfigActionsEnum {
  Create = 'create',
  Upsert = 'upsert',
  Read = 'read',
  Update = 'update'
}

export enum Hl7ConfigMappingFieldEnum {
  Source = 'src',
  Lit = 'lit',
  Destination = 'dest',
  Type = 'type',
  IsExternalId = 'isExternalId'
}

export enum HL7ConfigMappingFieldFormatEnum {
  Date = 'date',
  Datetime = 'datetime',
  Boolean = 'boolean',
  Phone = 'phone',
  Email = 'email',
  String = 'string',
  Reference = 'reference'
}

export enum IhObjectNameEnum {
  Patient = 'patientdata',
  Appointment = 'appointment'
}

export interface License {
  id: string,
  licensekey: any,
  updatedon: string,
  createdon: string
}

export interface Facility {
  id?: string,
  logoid?: string,
  name?: string,
  address1?: string, 
  address2?: string,
  city?: string,
  state?: string,
  postalcode?: string,
  website?: string,
  facilityid?: string,
  shortname?: string,
  isactive: boolean,
  updatedon?: Date,
  createdon?: Date
}

export interface Contact {
  id?: string,
  associationid?: string, 
  association?: string,
  displayname?: string,
  datatype?: string,
  data?: string,
  updatedon?: Date,
  createdon?: Date
}

export interface CustomerDataset {
  id: string,
  data: any,
  createdon: string,
  updatedon: string
}

export interface ActivityLog {
  id: string,
  activityname: string,
  status: string,
  rundate: string,
  results : string,
  updatedon?: Date,
  createdon?: Date
}

export const DatasetType = {
  Procedures: '003dd0da-10ce-40b4-87ee-572d55c4a1cb',
  Facilities:'82bac1e1-465c-453c-87d4-c343f2caa846'
}


export interface HardCodeColumn {
  id: string,
  name: string
}