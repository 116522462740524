import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'next-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.css']
})
export class FormSwitchComponent implements OnInit {
  @Input() id;
  @Input() name;
  @Input() startValue = false;
  @Input() offText = 'No';
  @Input() onText = 'Yes';

  @Output() valueChanged = new EventEmitter<boolean>();

  value: boolean;

  ngOnInit() {
    this.value = this.startValue;
  }

  toggle() {
    this.value = !this.value;

    this.valueChanged.emit(this.value);
  }
}
