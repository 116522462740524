<div class="form-group rounded" [ngClass]="{ 'd-none' : field.hidden }" [formGroup]="valueFormGroup">
  <label for="{{ field.name }}" class="m-0 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required bold">&nbsp;*</span>
  </label>
  <!-- Phone -->
  <div *ngIf="field.inputType === TextboxInputType.PHONE" class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <fa-icon [icon]="['fas', 'phone']" class="margin-center"></fa-icon>
      </span>
    </div>
    <input
      class="form-control"
      formControlName="Text"
      autocomplete="off"
      id="{{ field.name }}"
      name="{{ field.name }}"
      type="tel"
      inputmode="tel"
      [placeholder]="field.placeholder || '(000) 000-0000'"
      [readonly]="field.readonly"
      [required]="field.required"
      [maxlength]="15"
      [minLength]="10"
      [mask]="'(000) 000-0000'"
      [pattern]="phonePattern"
      [ngClass]="{'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation}"
      [dropSpecialCharacters]="false">
  </div>
  <!------------------------
  Validation Error Message
  ------------------------->
  <ng-container *ngIf="valueFormGroup?.get('Text')">
    <span *ngIf="valueFormGroup?.get('Text').invalid && !newField && needsValidation" class="float-right text-sizing color-danger">
      {{ getFormattingError(valueFormGroup?.get('Text')) | translate:getFormattingErrorParam(valueFormGroup?.get('Text')) }}
    </span>
  </ng-container>
</div>
