import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewInit, Input, OnDestroy, OnChanges, ChangeDetectorRef
} from '@angular/core';
import { OverlayTemplate } from '@next/shared/common';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OverlayService } from '@next/shared/next-services';
@Component({
  selector: 'next-pdf-page',
  templateUrl: './pdf-page.component.html',
  styleUrls: ['./pdf-page.component.css']
})
export class PdfPageComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() url: string;
  @Input() pageNum: number;
  @Input() scale: number;
  @Input() startX: number;
  @Input() startY: number;
  @Input() width: number;
  @Input() height: number;
  @Input() styleWidth: string;
  @Input() styleHeight: string;
  @Input() pdfTemplateId: string;
  @Input() pages: any;
  @Input() pdfSelectedPageIndex: number;
  @Input() settingType: string;
  @Input() onOverlayChange: Observable<void>;
  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() totalPagesLoaded: EventEmitter<number> = new EventEmitter<number>();

  pdfjs: any; //pdfjs library
  canvas: HTMLCanvasElement; // dom element rendering pdf image
  viewport: any; // pdfjs render obj
  thisPdf: any // instance of this pdf
  page: any; // pdfjs render source
  pageCount: number; // number of pages pdfjs finds
  naturalWidth: number;
  naturalHeight: number;
  loadComplete = false;
  loadingTask: any;

  @ViewChild('pdfCanvas') pdfCanvas: ElementRef;
  observerCleanup: Subject<void> = new Subject();
  selectedOverlayId: string;

  constructor(private overlayService: OverlayService, private cd: ChangeDetectorRef){ }

  ngOnInit() {
    this.pdfjs = window['pdfjs-dist/build/pdf'];
    this.pdfjs.GlobalWorkerOptions.workerSvc = 'pdfjs-dist/build/pdf.worker.js';

    this.onOverlayChange.pipe(
      takeUntil(this.observerCleanup)
    ).subscribe(() => {
      this.loadPage()
    });
  }

  async ngOnChanges(): Promise<void> {
    if(!this.loadComplete && this.pdfjs && !this.loadingTask.destroyed){
      this.loadingTask.destroy();
    }

    await this.loadPage();
  }

  async ngAfterViewInit() {
    await this.loadPage();
  }

  ngOnDestroy() {
    if (this.thisPdf) {
      // clean up
      this.thisPdf.destroy();
    }

    this.observerCleanup.next();
  }

  public async loadPage(): Promise<void> {
    if(!this.pdfjs) return;

    this.loadComplete = false;

    if(this.isOverlayAddedToPage())
    {
     const file =  await this.getOverlayPdf(this.selectedOverlayId);
      if(file)
      {
        const base64data: any = await this.blobToBase64(file);
        const pdfData = atob(base64data.replace('data:application/pdf;base64,', ''));
        this.loadingTask = this.pdfjs.getDocument({data: pdfData});
        this.processPdfLoadingTask(this.loadingTask);
        this.updatePageOverlayData(this.selectedOverlayId);
      }
    }
    else if (this.url) {
      this.loadingTask = this.pdfjs.getDocument(this.url);
      this.processPdfLoadingTask(this.loadingTask);
    } else {
      this.setBlankCanvas();
      this.isLoaded();
    }
    this.cd.detectChanges();
  }

  private isOverlayAddedToPage(): boolean{
    if(this.pages.length === 0) return false;

    const overlayId = this.pages[this.pdfSelectedPageIndex].settings[this.settingType].overlayId;
    if(overlayId === '' || overlayId === 'None'){
      this.selectedOverlayId = '';
      return false;
    }
    else{
      this.selectedOverlayId = overlayId;
      return true;
    }
  }

  async getOverlayPdf(overlayId: string) : Promise<Blob>{
    const pdftemplate: OverlayTemplate = {
      pdfTemplateId: this.pdfTemplateId,
      overlayPages: [
        {
          pageNumber: this.pageNum,
          overlayId: overlayId
        }
      ]
    };
    return this.overlayService.getOverlayPdf(pdftemplate).toPromise();
  }

  private processPdfLoadingTask(loadingTask: any){
    loadingTask.promise.then((pdf) => {
      this.thisPdf = pdf;
      this.pageCount = pdf.numPages;
      this.totalPagesLoaded.emit(this.pageCount);
      this.getPDFPage(pdf, this.pageNum);
    })
    .catch(() => {
      console.log("Aborted");
    });
  }

  setBlankCanvas() {
    this.canvas = <HTMLCanvasElement>this.pdfCanvas.nativeElement;

    this.canvas.style.width = 'auto';
    this.canvas.style.height = 'auto';
    this.canvas.style.backgroundColor = '#fff';

    this.canvas.width = 612;
    this.canvas.height = 792;
  }

  private blobToBase64(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
    });
  }

  public getPDFPage(pdf: any, pageNum: number): void {
    if (pdf) {
      pdf.getPage(pageNum).then((p) => {
        this.page = p;
        const s = this.scale ? this.scale : 1;
        this.viewport = this.page.getViewport({scale: s});

        this.canvas = <HTMLCanvasElement>this.pdfCanvas.nativeElement;

        this.naturalHeight = this.viewport.height;
        this.naturalWidth = this.viewport.width;

        this.canvas.width = this.width ? this.width : this.naturalWidth;
        this.canvas.height = this.height ? this.height : this.naturalHeight;

        const context: CanvasRenderingContext2D = this.canvas.getContext('2d');
        if (this.startX || this.startY) {
          context.translate(-1 * this.startX, -1 * this.startY);
        }

        this.canvas.style.width = this.styleWidth ? this.styleWidth : 'auto';
        this.canvas.style.height = this.styleHeight ? this.styleHeight : 'auto';

        const render = this.page.render( { canvasContext: context, viewport: this.viewport } );
        render.promise.then(() => {
          this.isLoaded();
        });
      });
    }
  }

  private isLoaded(): any {
    this.loadComplete = true;
    this.loaded.emit();
  }

  private updatePageOverlayData(overlayId: string) {
      //Update overlay data
    this.overlayService.getOverlay(overlayId).subscribe(overlay => {
      const currentPage = this.pages.filter(p => p.pageNumber === this.pageNum)[0];
      if(!currentPage) return;

      currentPage.settings[this.settingType].overlayData = overlay[0].data;
    });
  }
}
