<div #extractModal class="container-fluid" style="height: 90vh">
  <!--MODAL HEADER-->
  <div class="row modal-header">
    <h4 class="modal-title">
      <fa-icon [icon]="['fas', 'crop']" class="fa-uploadHeader"></fa-icon>
      {{ 'PDFEXTRACTOR.TITLE' | translate }}
    </h4>
    <div class="close pull-right" aria-label="Close" (click)="exit()">
      <span aria-hidden="true">
        <fa-icon [icon]="['fas', 'times']" class="exitPreview"></fa-icon>
      </span>
    </div>
  </div>

  <!--CONTROLS-->
  <div class="border rounded my-1 p-1">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text page-control-row">
          <div class="p-0" style="margin-right: auto">
            {{ 'PDFEXTRACTOR.CTRL_PAGE' | translate }}
          </div>
          <div class="px-1 border rounded" style="margin: -1px">
            <fa-icon type="button" [icon]="['fas', 'minus-circle']" (click)="pageDown()"></fa-icon>
          </div>
          <div class="px-1 border rounded" style="margin: -1px">
            <fa-icon type="button" [icon]="['fas', 'plus-circle']" (click)="pageUp()"></fa-icon>
          </div>
        </span>
      </div>
      <input type="number" id="pageN" step="1" min="1" max="{{pdfImage.pageCount}}" [(ngModel)]="page" (change)="changePage()" class="form-control">
    </div>
  </div>

  <!--VIEWPORT-->
  <div #modalBody class="d-flex justify-content-center align-content-center position-relative border rounded mb-3 p-1 viewport backface-hidden overflow-auto">
    <next-pdf-renderer
      id="pdf-view-component"
      #pdfImage
      [url]="pdf.url"
      [pageNum]="page"
      [scale]="scale"
      (loaded)="init(page)">
    </next-pdf-renderer>
  </div>

  <!--FOOTER-->
  <div class="modal-footer px-0 py-1">
    <button type="button" class="btn btn-default btn-outline-primary" style="margin-right: auto" (click)="preview()" [attr.disabled]="!transforms.length ? true : null">
      <fa-icon [icon]="['fas', 'search']"></fa-icon> {{ 'PDFEXTRACTOR.BTN_PREVIEW' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="exit()">
      {{ 'PDFEXTRACTOR.BTN_CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-default btn-primary" (click)="submit()" [attr.disabled]="!transforms.length ? true : null">
      {{ 'PDFEXTRACTOR.BTN_CONFIRM' | translate }}
    </button>
  </div>
</div>

<!--PREVIEW MODAL-->
<ng-template #previewTemplate>
  <div class="container-fluid">
    <div class="row modal-header mb-1 position-relative">
      <h4 class="modal-title">
        <fa-icon [icon]="['fas', 'search']" class="fa-uploadHeader"></fa-icon> {{ 'PDFEXTRACTOR.BTN_PREVIEW' | translate }}
      </h4>
      <fa-icon [icon]="['fas', 'times']" class="exitPreview" (click)="exitPreview()"></fa-icon>
    </div>
    <div class="row mx-1 my-2 p-2">
      <div class="position-relative w-100">
        <next-pdf-renderer
          id="pdf-renderer-preview"
          class="d-block border"
          [url] = "pdf.url"
          [scale] = scale
          [pageNum]="page"
          [startX] = transforms[0]
          [startY] = transforms[1]
          [width] = transforms[2]
          [height] = transforms[3]
          [styleWidth]= "'100%'">
        </next-pdf-renderer>
      </div>
    </div>
    <div class="mb-3">
      <button type="button" class="btn btn-default btn-primary w-100" (click)="exitPreview()">
        {{ 'PDFEXTRACTOR.DIALOGS.PREVIEW_CLOSE_BTN' | translate }}
      </button>
    </div>
  </div>
</ng-template>
