import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  jwtHelper = new JwtHelperService();

  hasAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  /**
   * - _Returns ___access_token___ from Session Storage_ */
  getAccessToken(): string {
    return sessionStorage.getItem('access_token');
  }

  saveAccessToken(token: string) {
    sessionStorage.setItem('access_token', token);
  }

  saveIdentityToken(token: string) {
    sessionStorage.setItem('id_token', token);
  }

  getIdentityToken(): string {
    return sessionStorage.getItem('id_token');
  }

  clear() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('id_token');
  }

  clearLastAction() {
    sessionStorage.removeItem('lastAction');
  }

  getCurrentUserId() {
    return (this.jwtHelper.decodeToken(this.getIdentityToken()).oid);
  }

  isIdInCurrentUsersGroups(id) {
    const groups =  this.jwtHelper.decodeToken(this.getIdentityToken()).groups;
    return groups.includes(id);
  }
}
