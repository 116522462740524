import { Component, Input } from '@angular/core';
import { Header } from '@next/shared/common';

@Component({
  selector: 'next-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {
  @Input() field: Header;

}
