import { Inject, Injectable } from '@angular/core';
import { ApiService, FormFastConfig, FormFastConfigService, GuidedExperienceDTO, ImportHelperService, TokenService, UploadFile } from '@next/shared/common';
import { NextExperienceService } from './experience.service';
import { NextFormService } from './form.service';
import { switchMap } from 'rxjs/operators';
import download from 'downloadjs';
import * as jsZip from 'jszip';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService extends ApiService {
  validFileTypes: string[] = ['application/x-zip-compressed'];

  private _exportFiles: BehaviorSubject<string> = new BehaviorSubject('');
  public get exportFiles(): Observable<string> {
    return this._exportFiles.asObservable();
  }

  private _createdExperiences: BehaviorSubject<any> = new BehaviorSubject('');
  public get createdExperiences(): Observable<GuidedExperienceDTO> {
    return this._createdExperiences.asObservable();
  }

  constructor (
    @Inject(FormFastConfigService)
    config: FormFastConfig,
    tokenService: TokenService,
    private experienceSvc: NextExperienceService,
    private formSvc: NextFormService,
    private importHelperService: ImportHelperService,
    private http: HttpClient,
  ) {
    super(config, tokenService);
  }

  async generateZipFile(file: GuidedExperienceDTO[]): Promise<void> {
    const zip = new jsZip();

    if (file.length === 0) {
      this._exportFiles.next(file.length.toString());
    }

    for (const x of file) {
      const index = file.indexOf(x);
      const experience: GuidedExperienceDTO = await this.experienceSvc.getExperienceWithMetadata(x.id, x.vid).toPromise();
      const pdfBlob: Blob = await this.formSvc.getPdf(x.pdftemplateid).pipe(switchMap(val => this.formSvc.getPdfBinaryData(val.url).toPromise())).toPromise();

      zip.file(this.importHelperService.formatFileName(x.name, x.id) + '.txt', this.importHelperService.generateMetaData(experience));
      zip.file(this.importHelperService.formatFileName(x.name, x.id) + '.pdf', pdfBlob.arrayBuffer(), { binary: true });

      if (index === file.length - 1) {
        await this.downloadZipFile(zip, file.length);
      }
    }
  }

  async downloadZipFile(zip: jsZip, index): Promise<void> {
    const content: Blob = await zip.generateAsync({ type: 'blob', mimeType: 'application/pdf'});
    download(new Blob([content], { type: 'application/pdf', endings: 'native'}), 'experiences.zip');
    this._exportFiles.next(index);
  }

  async uploadFiles(zipFiles: UploadFile[]): Promise<number> {
    let importCount = 0;
    for (const zip of zipFiles) {
      zip.progress = 0;
      if (!this.isZipFile(zip.file)) {
        this.errorHandler(zip, new Error('Invalid file type'));
      }
      else {
        try {
          importCount += await this.iterateOverZip(zip);
          zip.status = 1;
          zip.progress = 100;
        }
        catch (e) {
          this.errorHandler(zip, e);
        }
      }
    }
    return importCount;
  }

  private async iterateOverZip(compressedFile: UploadFile): Promise<number> {
    const zip = new jsZip();
    const fs = await zip.loadAsync(compressedFile.file, { base64: true });
    let json = null, binary = null, binaryName = null, successCount = 0;

    const files: any[] = Object.values(fs.files);
    for (const file of files) {
      if (file.name.length >= 4 ) {
        const fn = file.name.substr(0, file.name.length - 4).toLowerCase();
        const suffix = file.name.slice(-4).toLowerCase();
        if (suffix === '.txt') {
          const pdf = files.find(f => f.name.toLowerCase() === (fn + '.pdf'));
          if (!pdf) {
            this.errorHandler(file, new Error('Could not find matching GX.PDF file for GX.txt'));
          }

          json = await fs.file(file.name).async('binarystring');
          binary = await fs.file(pdf.name).async('arraybuffer');
          binaryName = pdf.name.substring(0, 80);

          const formData: FormData = new FormData();
          formData.append('name', binaryName);
          formData.append('description', binaryName);
          formData.append('pdf-form', new Blob([binary], { type: 'application/pdf', endings: 'native' }), binaryName);

          const req = new HttpRequest('POST', `${this.config.apiUrl}pdftemplate`, formData, this.getUploadHeaders());
          const res = await this.protectedEndpoint(this.http.request(req)).toPromise().catch(err => this.errorHandler(compressedFile, err));
          await this.UploadJsonFileIntoSystem(res['body'].id, json).catch(err => this.errorHandler(compressedFile, err));
          successCount++;
        }
      }
    }
    return successCount;
  }

  async UploadJsonFileIntoSystem(pdftemplateid: string, Jsoncontent: string): Promise<any> {
    return this.formSvc.getFormFields(pdftemplateid).pipe(switchMap(val => {
      const sources = val || '';
      const convertedExperience = Jsoncontent
        .replace('@pdftemplateid', pdftemplateid)
        .replace('@metadataid', pdftemplateid)
        .replace('@sources', JSON.stringify(this.importHelperService.convertFields(sources)));
      return this.protectedEndpoint<any>(this.http.post<any>(`${this.config.apiUrl}experience`, convertedExperience, this.getHeaders()))
    })).subscribe(n => this._createdExperiences.next(n));
  }

  isZipFile(file: File): boolean {
    const validFileTypes: string[] = this.validFileTypes;
    return validFileTypes.includes(file.type.toString());
  }

  errorHandler(file: UploadFile, err: Error): ErrorEvent {
    file.status = -1;
    file.progress = 100;
    throw err;
  }

}
