import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GuidedExperienceDTO } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextExperienceService } from '../experience.service';

@Injectable({
  providedIn: 'root',
})
export class NextViewerExperienceResolverService {
  constructor(private experienceSvc: NextExperienceService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<GuidedExperienceDTO> | Observable<never> {
    const experienceId = route.paramMap.get('experienceId');
    const versionId = route.paramMap.get('versionId');

    if (experienceId) {
      if (versionId) {
        return this.experienceSvc.getExperienceVersion(experienceId, versionId);
      }

      return this.experienceSvc.getExperience(experienceId);
    }
    else {
      return of({
        id: null,
        vid: null,
        pdftemplateid: null,
        formId: null,
        name: "Experience",
        description: "",
        logoUrl: "",
        logoHeight: 0,
        logoAlignment: "left",
        pages: [],
        pdfPages: [],
        annotations: [],
        metadata: {
          sources: []
        }
      } as GuidedExperienceDTO);
    }
  }
}
