import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiService, FormFastConfig, FormFastConfigService, TokenService } from "@next/shared/common";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class PDFService extends ApiService {

  constructor(
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService,
    private http: HttpClient
  ) {
    super(config, tokenService);
  }

  /**
   * POST File IDs of PDFs to generate a merged, flattened PDF data-uri
   *
   * @param  {string[]} ids - IDs of files to retrieve minio S3 URLs from
   * @return {Promise<string>} - Data URI of mime-type application/pdf
   */
  mergePdfs(ids: string[]): Observable<string> {
    return this.protectedEndpoint<string>(
      this.http.post<string>(`${this.config.apiUrl}pdf/merge`, ids, this.getHeaders())
    );
  }
}
