import { Injectable } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Overlay } from '@next/shared/common';
import { Observable, of } from 'rxjs';
import { OverlayService } from '../overlay.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayResolverService {
  constructor(private overlaySvc: OverlayService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Overlay> {
    const overlayId = route.paramMap.get('overlayId');
    if (overlayId) {
      return this.overlaySvc.getOverlay(overlayId);
    }
    return of(null);
  }
}
