import {ActivatedRouteSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NextSubmissionService } from '../submission.service';

@Injectable({
  providedIn: 'root',
})
export class NextAttachmentResolverService {

  constructor (private submissionSvc: NextSubmissionService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> | Observable<never> {
    if (route.queryParams.formId) {
      return this.submissionSvc.getAttachments(route.queryParams.formId);
    }
    return null;
  }
}
