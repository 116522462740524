import { Component } from '@angular/core';
import { TextboxComponent } from '../../textbox.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
export enum DisplayCC {
  SHOW = 'SHOW',
  HIDE = 'HIDE'
}
@Component({
  selector: 'next-creditcard',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./../../textbox.component.css']
})
export class CreditCardComponent extends TextboxComponent {
  constructor (currencyPipe: CurrencyPipe, datePipe: DatePipe) {
    super(currencyPipe, datePipe);
  }

  DisplayCC: typeof DisplayCC = DisplayCC;
  hiddenText: boolean = true;

  // Regex match:
  CCPattern: RegExp = new RegExp(/^\d{4}$/);

  toggleHiddenText(): void {
    this.hiddenText = !this.hiddenText;
    // setTimeout() is a workaround for a known issue in ngx-mask, resolved in version 14.0.2 (requires angular 14)
    // https://github.com/JsDaddy/ngx-mask/issues/996
    const val: string = this.valueFormGroup.value.Text;
    setTimeout(() => this.valueFormGroup.controls['Text'].setValue(val, { emitEvent: false }));
  }
}
