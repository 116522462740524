import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'next-pdf-renderer',
  templateUrl: './pdf-renderer.component.html',
  styleUrls: ['./pdf-renderer.component.css']
})
export class PdfRendererComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pdfCanvas') pdfCanvas: ElementRef;

  @Input() url: string;
  @Input() pageNum: number;
  @Input() scale: number;
  @Input() startX: number;
  @Input() startY: number;
  @Input() width: number;
  @Input() height: number;
  @Input() styleWidth: string;
  @Input() styleHeight: string;
  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();

  pdfjs: any;                 // PDF.JS library
  canvas: HTMLCanvasElement;  // Dom element rendering pdf image
  viewport: any;              // PDF.JS render object
  thisPdf: any;               // Instance of this pdf
  page: any;                  // PDF.JS render source
  pageCount: number;          // Number of pages PDF.JS finds

  naturalWidth: number;
  naturalHeight: number;

  loadComplete: boolean = false;

  ngOnInit() {
    this.pdfjs = window['pdfjs-dist/build/pdf'];
    this.pdfjs.GlobalWorkerOptions.workerSvc = 'pdfjs-dist/build/pdf.worker.js';
  }

  ngAfterViewInit() {
    this.loadComplete = false;
    this.loadDocument(this.pageNum);
  }

  ngOnDestroy() {
    if (this.thisPdf) {
      this.thisPdf.destroy();
    }
  }

  public loadDocument(pageN: number = 1): void {
    this.pageNum = pageN;
    const loadingTask = this.pdfjs.getDocument(this.url);
    loadingTask.promise.then((pdf) => {
      this.thisPdf = pdf;
      this.pageCount = pdf.numPages;
      this.setupPage(pdf, this.pageNum);
    });
  }

  public setupPage(pdf: any, pageNum: number): void {
    if (pdf) {
      pdf.getPage(pageNum).then((p) => {
        this.page = p;
        const s = this.scale ? this.scale : 1;
        this.viewport = this.page.getViewport({scale: s});

        this.canvas = <HTMLCanvasElement>this.pdfCanvas.nativeElement;

        this.naturalHeight = this.viewport.height;
        this.naturalWidth = this.viewport.width;

        this.canvas.width = this.width ? this.width : this.naturalWidth;
        this.canvas.height = this.height ? this.height : this.naturalHeight;

        const context: CanvasRenderingContext2D = this.canvas.getContext('2d');
        if (this.startX || this.startY) {
          context.translate(-1 * this.startX, -1 * this.startY);
        }

        this.canvas.style.width = this.styleWidth ? this.styleWidth : 'auto';
        this.canvas.style.height = this.styleHeight ? this.styleHeight : 'auto';

        const render = this.page.render( { canvasContext: context, viewport: this.viewport } );
        render.promise.then(() => {
          this.isLoaded();
        });
      });
    }
  }

  private isLoaded(): any {
    this.loadComplete = true;
    this.loaded.emit();
  }

}
