import { Patient } from "./patient.model";

export interface Form {
  id: string;
  name: string;
  description: string;
  url: string;
}

export interface TaskDTO {
  id?: string;
  assignto: string;
  name: string;
  formid: string;
  experienceid: string;
  experienceversionid: string;
  pdftemplateid?: string;
  issuedon: string;
  completedon: string;
  issuedby: string;
  type: TaskType;
  status: TaskStatus;
  tags?: string;
  formtype?: string;
  patientdata?: Patient;
  fields?: any;
  signall?: string;
  signaturefor?: string;
  signaturetype?: string;
  patientfirstname?: string;
  patientlastname?: string;
}

export enum TaskType {
  StaffUser = 'staff_user',
  StaffGroup = 'staff_group',
  Patient = 'patient',
  Appointment = 'appointment'
}

export enum TaskStatus {
  NotStarted = 'not_started',
  Incomplete = 'incomplete',
  Complete = 'complete'
}

export enum FormStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed  = 'COMPLETE',
  PatientSign  = 'PATIENT_SIGN',
  StaffSign  = 'STAFF_SIGN',
  OtherSign  = 'OTHER_SIGN'
}

export enum PatientFormStateDisplay {
  not_started = 'Not Started',
  incomplete = 'Incomplete',
  complete = 'Complete',
  NOT_STARTED = 'Not Started',
  COMPLETE = 'Complete',
  PATIENT_SIGN = 'Patient to Sign',
  STAFF_SIGN = 'Staff to Sign',
  OTHER_SIGN = 'Other to Sign'
}

export interface Annotation {
  alignment: string;
  id: string;
  name: string;
  groupName: string;
  fieldLocking: any;
  fieldType: FieldType;
  readOnly: boolean;
  required: boolean;
  viewable: boolean;
  value: string;
  label: string;
  maxLength: string;
  multiLine: boolean;
  coordinates: any;
  textFormat: any;
  tooltip: string;
  selectOptions: SelectOption[];
  groupMembers: Annotation[];
}

export enum FieldType {
  TextBox = 'TEXTBOX',
  CheckBox = 'CHECKBOX',
  DropDown = 'DROPDOWN',
  Radio = 'RADIO',
  Button = 'BUTTON',
  Hyperlink = 'HYPERLINK',
  Group = 'GROUP',
  Signature = 'SIGNATURE'
}

export interface SelectOption {
  id: string;
  text: string;
  value: string;
  selected: boolean;
}

export enum CheckBoxValue {
  True = 'Checked',
  False = 'Unchecked'
}

export interface CollectionDTO {
  id: string;
  name: string;
  parent: string;
  type: CollectionType;
  expanded: boolean;
  tags: string;
}

export interface CollectionJunctionDTO {
  collection: string;
  element: string;
}

export enum CollectionType {
  Folder = 'Folder',
  Packet = 'Packet',
  Form = 'Form'
}

export enum TaskTypes {
   IhPlatform = 'IH Platform'
}
