<div class="container-fluid h-100">
    <div class="row d-flex px-0 pb-2 align-items-center modal-header justify-content-between title">
      <div class="col-2 text-left">
        <button id='manage-tags-cancel' class="btn btn-unstyled" (click)="close()" role="button">
          {{ 'NOTIFICATION_MODAL.CANCEL' | translate }}
        </button>
      </div>
      <div class="col-2 text-center">
        {{'NOTIFICATION_MODAL.TITLE' | translate }}
      </div>
      <div class="col-2">
      </div>
    </div>
    <div class='d-block row manage-tags-body'>
      <div class='container-fluid m-10 p-10 h-100'>
           <p [innerHTML]="notification | replaceLineBreaks"></p>
      </div>
    </div>
  </div>
  