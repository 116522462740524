import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GuidedExperienceDTO } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextExperienceService } from '../experience.service';

@Injectable({
  providedIn: 'root',
})
export class NextExperienceResolverService {
  constructor(private experienceSvc: NextExperienceService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GuidedExperienceDTO> | Observable<never> {
    const experienceId = route.paramMap.get('experienceId');
    const versionId = route.paramMap.get('versionId');

    if (experienceId && versionId) {
      return this.experienceSvc.getExperienceWithMetadata(experienceId, versionId);
    } else {
      return of({
        id: null,
        vid: null,
        pdftemplateid: null,
        formId: null,
        name: "Experience",
        description: "",
        logoUrl: "",
        logoHeight: 0,
        logoAlignment: "left",
        pages: [],
        pdfPages: [],
        annotations: [],
        configversion: 1,
        metadata: {
          sources: []
        }
      } as GuidedExperienceDTO);
    }
  }
}
