<div [ngClass]="{'d-none' : field.hidden}">
  <div class="form-group form-check" [formGroup]="valueFormGroup">
    <fieldset [disabled]="field.readonly">
      <legend></legend>
      <input type="checkbox" class="form-check-input" id="{{ field.name }}" formControlName="Text" (change)="onChange()">
      <label for="{{ field.name }}" class="form-check-label">
        {{ field.text }}
      </label>
    </fieldset>
  </div>
  <div>
    <div *ngIf="getFieldValue(field)" [@subField]="animateState" (@subField.done)="turnAnimationsOn()">
      <div *ngFor="let subField of field.trueFields">
        <next-viewer-element [form]="form" [field]="subField" [attachments]="attachments" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)">
        </next-viewer-element>
      </div>
    </div>
    <div *ngIf="!getFieldValue(field)" [@subField]="animateState" (@subField.done)="turnAnimationsOn()">
      <div *ngFor="let subField of field.falseFields">
        <next-viewer-element [form]="form" [field]="subField" [attachments]="attachments" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)">
        </next-viewer-element>
      </div>
    </div>
  </div>
</div>
