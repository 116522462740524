import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubmissionDataType, WrittenSigDTO, DisplayStatus } from '@next/shared/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import SignaturePad from 'signature_pad';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-written-sig',
  templateUrl: './written-sig.component.html',
  styleUrls: ['./written-sig.component.css'],
  animations: [
    trigger('slide', [
      state(DisplayStatus.CLOSED, style({ transform: 'translateY(0)'})),
      state(DisplayStatus.OPENED, style({ transform: 'translateY(-100%)' })),
      transition('* <=> *', animate('800ms ease-in-out'))
    ])
  ]
})

export class WrittenSigComponent extends FieldBaseComponent implements OnInit, AfterViewInit {
  SubmissionDataType: typeof SubmissionDataType = SubmissionDataType;
  DisplayStatus: typeof DisplayStatus = DisplayStatus;

  @Input() form: FormGroup;
  @Input() field: WrittenSigDTO;
  @Input() initialState: any;

  display: string = DisplayStatus.CLOSED;
  canvasMaxWidth = 1110;
  sigValue: any;
  sigPad: SignaturePad;
  previewPad: SignaturePad;
  typedSigCtrl: string;
  isStandardSig: boolean;

  @ViewChild('sigCanvas') sigCanvas: ElementRef;
  @ViewChild('sigPreviewCanvas') sigPreviewCanvas: ElementRef;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const initValue: any = this.initialState[this.field.name];
    this.setTempValueControlSwitching(this.field.name);

    if (!this.form.contains(this.field.name)) {
      const options: any = this.field.required ? { validators: [Validators.required] } : { };
      this.valueFormGroup = new FormGroup({
        SignedDate: new FormControl(''),
        Strokes: new FormControl('', options),
        Text: new FormControl('', options)
      });

      this.form.addControl(this.field.name, new FormGroup({
        Type: new FormControl(initValue.Type || SubmissionDataType.Signature),
        Value: this.valueFormGroup
      }));
    } else {
      this.valueFormGroup = this.form.get(`${this.field.name}.Value`) as FormGroup;
    }
    this.isStandardSig = this.form.value[this.field.name].Type === SubmissionDataType.Signature;
    this.sigValue = this.isStandardSig ? this.form.get(`${this.field.name}.Value.Strokes`).value : this.form.get(`${this.field.name}.Value.Text`).value;
  }

  ngAfterViewInit(): void {
    if (this.sigValue && this.sigValue.length !== 0) {
      this.setupPreviewCanvas();
      if (this.isStandardSig) {
        this.sigPadFillStroke();
      }
      else {
        this.sigPadFillText(this.sigPreviewCanvas);
      }
    }
  }

  toggleSig(enableTypedSignature: boolean): void {
    this.form.patchValue({[this.typedSigCtrl]: enableTypedSignature});
    this.form.patchValue({[this.field.name]: { Type: enableTypedSignature ? SubmissionDataType.TypedSignature : SubmissionDataType.Signature }});
    this.isStandardSig = !enableTypedSignature;
  }

  private setupSigCanvas(resize: boolean = false): void {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>this.sigCanvas.nativeElement;
    const prevWidth = canvas.width;
    const prevHeight = canvas.height;
    const newWidth = (window.document.body.offsetWidth - 48 < this.canvasMaxWidth)
      ? window.document.body.offsetWidth - 48
      : this.canvasMaxWidth;
    const newHeight = newWidth / 5 < 100 ? 100 : newWidth / 5;
    canvas.width = newWidth;
    canvas.height = newHeight;
    canvas.style.width = newWidth + 'px';
    canvas.style.height = newHeight + 'px';
    this.sigPad = new SignaturePad(canvas, {
      onEnd: event => {
        this.updateSigValue();
        this.setCSSUserSelect('auto');
      },
      onBegin: event => {
        this.setCSSUserSelect('none');
      }
    });
    if (this.sigValue && resize) {
      const xRatio: number = canvas.width / prevWidth;
      const yRatio: number = canvas.height / prevHeight;
      this.resizeData(this.sigValue, xRatio, yRatio);
      this.sigPad.fromData(this.sigValue);
    }
  }

  private setupPreviewCanvas(): void {
    const previewCanvas = <HTMLCanvasElement>this.sigPreviewCanvas.nativeElement;

    const newWidth = (window.document.body.offsetWidth - 48 < this.canvasMaxWidth)
      ? window.document.body.offsetWidth - 48
      : this.canvasMaxWidth;
    const newHeight = newWidth / 5 < 100 ? 100 : newWidth / 5;

    previewCanvas.width = newWidth;
    previewCanvas.height = newHeight;
    this.previewPad = new SignaturePad(previewCanvas,  { minWidth:1, maxWidth:3});
    this.previewPad.off();
  }

  openDialog(): void {
    this.display = DisplayStatus.OPENED;
    if (this.sigCanvas) this.setupSigCanvas();
  }

  closeDialog(): void {
    this.updateSigValue();
    this.setupPreviewCanvas();
    if (this.isStandardSig) {
      this.sigPadFillStroke();
    }
    else {
      this.sigPadFillText(this.sigPreviewCanvas);
    }
    this.display = DisplayStatus.CLOSED;
    this.updateStatus(this.form.get(this.field.name).status);
    this.valueChanged.emit(this.field);
  }

  clearSignature(): void {
    this.sigPad.clear();
    this.sigValue = '';
  }

  discardSignature(): void {
    this.sigValue = '';
    this.valueFormGroup.patchValue({['SignedDate']: ''});
    this.valueFormGroup.patchValue({['Strokes']: ''});
    this.valueFormGroup.patchValue({['Text']: ''});
    if (this.field.signatureTimeStampEnabled && this.field.signatureTimeStampFieldName) {
      delete this.initialState[this.field.signatureTimeStampFieldName];
    }
    this.updateStatus(this.valueFormGroup.status);
    this.valueChanged.emit(this.field);
  }

  private sigPadFillText(canvas: ElementRef): void {
    canvas.nativeElement.width = document.getElementById('popupTitle').offsetWidth;
    canvas.nativeElement.height = 120;
    const ctx = canvas.nativeElement.getContext('2d');
    if (canvas.nativeElement.offsetWidth <= 400) {
      ctx.font = "normal normal 36px 'Brush Script MT', cursive";
    } else {
      ctx.font = "normal normal 48px 'Brush Script MT', cursive";
    }
    ctx.fillText(this.sigValue, 15, 75, canvas.nativeElement.width - 20);
  }

  private sigPadFillStroke(): void {
    if (this.sigValue.length) {

      if (this.isStandardSig) {
        const size = this.getSigSize(this.sigValue);

        //create deep copy of signature
        const sigCopy = JSON.parse(JSON.stringify(this.sigValue));
        this.adjustSig(sigCopy, size);
        this.previewPad.fromData(sigCopy);
      } else {
        this.previewPad.fromData(this.sigValue);
      }
    }
  }

  private adjustSig(strokes: any, sigSize: any): void {
    const xPadding = 3;
    const width = this.sigPreviewCanvas.nativeElement.width;
    const height = this.sigPreviewCanvas.nativeElement.height;

    const deltaWidth = sigSize.width - (width - 2*xPadding);
    const deltaHeight = (sigSize.height - height);
    let multiplier = 1;
    if (deltaWidth > 0 || deltaHeight > 0) {
      if (deltaWidth > deltaHeight)
      {
        multiplier = (width - 2*xPadding) / sigSize.width;
      }
      else
      {
        multiplier = height / sigSize.height;
      }
    }
    const yCenterPadding = (height - (sigSize.height * multiplier)) / 2;
    if (strokes) {
      strokes.forEach(function (stroke) {
      stroke.points.forEach(function (point: any) {
            point.x = ((point.x - sigSize.minX) * multiplier) + xPadding;
            point.y = ((point.y - sigSize.minY) * multiplier) + yCenterPadding;
        });
      });
    }
  }

  private getSigSize(strokes) {
      let minX = Number.MAX_VALUE;
      let minY = Number.MAX_VALUE;
      let maxX = 0;
      let maxY = 0;
      strokes.forEach(function (stroke) {
        stroke.points.forEach(function (point) {
          point.x +=10;
          point.y +=10;
          if (point.x < minX) {
            minX = point.x;
          }

          if (point.x > maxX) {
            maxX = point.x;
          }

          if (point.y < minY) {
            minY = point.y;
          }

          if (point.y > maxY) {
            maxY = point.y;
          }
        });
      });
      return {
        width: maxX - minX,
        height: maxY - minY,
        maxX: maxX,
        maxY: maxY,
        minX: minX,
        minY: minY
      };
  }

  private updateSigValue(): void {
    if (this.isStandardSig) {
      this.sigValue = this.sigPad.toData();
      this.valueFormGroup.patchValue({ ['Strokes']: this.sigValue });
      this.valueFormGroup.patchValue({ ['SignedDate']: new Date() });
      this.form.get(this.field.name).patchValue({['Type']: SubmissionDataType.Signature})
    }
    else {
      this.sigValue = this.form.get(this.field.name + '.Value.Text').value;
      this.valueFormGroup.patchValue({ ['SignedDate']: new Date() });
      this.form.get(this.field.name).patchValue({['Type']: SubmissionDataType.TypedSignature});
    }

    // If signature-timestamp enabled, apply timestamp to target field
    if (this.field.signatureTimeStampEnabled && this.field.signatureTimeStampFieldName) {
      const format: string = this.field.signatureTimeStampFormat || '%m/%d/%Y %I:%M %p';
      const formattedStamp: string = this.strftime(format, this.valueFormGroup.value.SignedDate);
      this.initialState[this.field.signatureTimeStampFieldName] = {
        Type: 'Text',
        Value: {
          Text: formattedStamp,
          Format: 'alphanumeric'
        }
      }
    }
  }

  // scale draw data by ratio
  private resizeData(data: any, ratioX: number, ratioY: number): void {
    if (data) {
      data.forEach(function (stroke) {
        stroke.points.forEach(function (point: any) {
          point.x *= ratioX;
          point.y *= ratioY;
        });
      });
    }
  }

  /**
   * https://stackoverflow.com/questions/8847109/formatting-the-date-time-with-javascript
   * @param sFormat {string} - Preferred format
   * @param date {Date}      - JS Date Object to convert to string
   * @returns {*}
   */
  strftime(sFormat, date) {
    if (!(date instanceof Date)) date = new Date();
    const nDay = date.getDay(),
      nDate = date.getDate(),
      nMonth = date.getMonth(),
      nYear = date.getFullYear(),
      nHour = date.getHours(),
      aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
      isLeapYear = function() {
        return (nYear%4===0 && nYear%100!==0) || nYear%400===0;
      },
      getThursday = function() {
        const target = new Date(date);
        target.setDate(nDate - ((nDay+6)%7) + 3);
        return target;
      },
      zeroPad = function(nNum, nPad) {
        return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
      };
    return sFormat.replace(/%[a-z]/gi, function(sMatch) {
      return {
        '%a': aDays[nDay].slice(0,3),
        '%A': aDays[nDay],
        '%b': aMonths[nMonth].slice(0,3),
        '%B': aMonths[nMonth],
        '%c': date.toUTCString(),
        '%C': Math.floor(nYear/100),
        '%d': zeroPad(nDate, 2),
        '%e': nDate,
        '%F': date.toISOString().slice(0,10),
        '%G': getThursday().getFullYear(),
        '%g': ('' + getThursday().getFullYear()).slice(2),
        '%H': zeroPad(nHour, 2),
        '%I': zeroPad((nHour+11)%12 + 1, 2),
        '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
        '%k': '' + nHour,
        '%l': (nHour+11)%12 + 1,
        '%m': zeroPad(nMonth + 1, 2),
        '%M': zeroPad(date.getMinutes(), 2),
        '%p': (nHour<12) ? 'AM' : 'PM',
        '%P': (nHour<12) ? 'am' : 'pm',
        '%s': Math.round(date.getTime()/1000),
        '%S': zeroPad(date.getSeconds(), 2),
        '%u': nDay || 7,
        '%V': (() => {
          const target = getThursday(),
          n1stThu = target.valueOf();
          target.setMonth(0, 1);
          const nJan1 = target.getDay();
          if (nJan1!==4) {
            target.setMonth(0, 1 + ((4-nJan1)+7)%7);
          }
          return zeroPad(1 + Math.ceil((n1stThu - target.valueOf())/604800000), 2);
        })(),
        '%w': '' + nDay,
        '%x': date.toLocaleDateString(),
        '%X': date.toLocaleTimeString(),
        '%y': ('' + nYear).slice(2),
        '%Y': nYear,
        '%z': date.toTimeString().replace(new RegExp(/.+GMT([+-]\d+).+/), '$1'),
        '%Z': date.toTimeString().replace(new RegExp(/.+\((.+?)\)$/), '$1')
      }[sMatch] || sMatch;
    });
  }

  @HostListener("window:resize") orientation(): void {
    if (this.display === DisplayStatus.OPENED && this.sigCanvas) {
      this.sigPad.off();
      this.setupSigCanvas(true);
    }
  }
}
