import { ApiService, FormFastConfig, FormFastConfigService, TokenService, Authorization, User, UserGroups, AzureADUsers } from '@next/shared/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NextAuthService extends ApiService {

  constructor(
    @Inject(FormFastConfigService) config: FormFastConfig,
    private tokenSvc: TokenService,
    private http: HttpClient) { super(config, tokenSvc); }

  authorizeCode(code: string, state: string): Observable<Authorization> {

    const body = {
      grant_type: 'authorization_code',
      code: code,
      state: state
    };

    return this.protectedEndpoint<Authorization>(
      this.http.post<any>(`${this.config.apiUrl}/auth/oauth/token`, body, this.getHeaders()).pipe(
        map(result => {
          return {
            accessToken: result.access_token,
            idToken: result.id_token,
            expiresIn: result.expires_in,
            firstTimeUser:result.firstTimeUser
          } as Authorization
        }))
    )
  }

  getUserInfo(): Observable<User> {
    return this.protectedEndpoint<User>(
      this.http.get<any>(`${this.config.apiUrl}/auth/oauth/userinfo`, this.getHeaders()).pipe(
        map(result => {
          return {
            firstName: result.given_name,
            lastName: result.family_name,
            thumbnail: result.picture,
            photo: result.picture
          } as User
        }))
    )
  }

  getAzureActiveDirectoryGroups(): Observable<UserGroups> {
    return this.protectedEndpoint<UserGroups>(
      this.http.get<UserGroups>(`${this.config.apiUrl}auth/azureactivedirectory/groups`, this.getHeaders())
    );
  }

  getAzureActiveDirectoryUsers(): Observable<AzureADUsers[]> {
    return this.protectedEndpoint<AzureADUsers[]>(
      this.http.get<any>(`${this.config.apiUrl}auth/azureactivedirectory/users`, this.getHeaders())
        .pipe(map(results => {
          return results.map(res => {
            return {
              id: res.id,
              fullName: res.giveName !== null && res.surname !== null ? `${res.givenName} ${res.surname}` : null,
              firstName: res.givenName,
              lastName: res.surname
            } as AzureADUsers;
          })
        }))
    );
  }
}
