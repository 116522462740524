import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HardCodeColumn, SettingEnum } from '@next/shared/common';
import { NextAdminService, UserResolverService } from '@next/shared/next-services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Table } from 'primeng/table';
import { GridColumnModalComponent } from '../grid-column-modal/grid-column-modal.component';
import { GridSettingModalComponent } from '../grid-setting-modal/grid-setting-modal.component';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-grid-configuration',
  templateUrl: './grid-configuration.component.html',
  styleUrls: ['./grid-configuration.component.css']
})
export class GridConfigurationComponent implements OnInit {

  @Input() table: Table;
  @Input() screen: string;
  @Input() sortby: string;
  @Input() filterby: string;

  @Output() gridHeadersUpdate: EventEmitter<any[]> = new EventEmitter<any[]>();

  isDefault: boolean;
  userId: string;
  displaycolumn: any;
  selectedMySavedGridView: any;
  HardCodeColumn: HardCodeColumn[];
  gridHeaders: Array<any>
  

  gridColumnModal: BsModalRef;
  gridSettingModal: BsModalRef;
  

  constructor(private modalService: BsModalService, private adminSvc: NextAdminService, public userSvc: UserResolverService, public stateSvc: StoreService) { }

  ngOnInit(): void {
    this.getUserId();
    this.setcolumnHeaders(null);
    this.getGridSettingData(null);
  }

  onGridSettingModal() {
    const config: any = {
      class: 'modal-appointment-setting',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        sortby: this.sortby || null,
        filterby: this.filterby || null,
        displaycolumn: this.displaycolumn || null,
        screen: this.screen,
        userid: this.userId
      }
    };
    this.gridSettingModal = this.modalService.show(GridSettingModalComponent, config);
    this.gridSettingModal.content.onClose.subscribe(result => {
      if (result) {
        this.getGridSettingData(result[0].id);
      } else {
        this.getGridSettingData(this.selectedMySavedGridView);
      }

    });
  }

  getGridSettingData(id): void {
    this.HardCodeColumn = [];
    this.adminSvc.getAllGridSettings(this.screen, this.userId).subscribe(data => {
      this.HardCodeColumn = [
        {name: 'Select A Saved Setting', id: 'Select A Saved Setting'}
      ];
      data.forEach(setting => {
        this.HardCodeColumn.push({
          id: setting.id,
          name: setting.settingname
        })
      })

      this.selectSetting(id);
      this.checkForDefaultSetting();
    });
  }

  selectSetting(id) {
    if (id) {
      this.selectedMySavedGridView = id;
    } else {
      this.selectedMySavedGridView = this.HardCodeColumn[0].id;
    }
  }

  onGridColumnModal() {
    const config: any = {
      class: 'modal-full',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        displaycolumn: this.displaycolumn || null,
        screen: this.screen
      }
    };
    this.gridColumnModal = this.modalService.show(GridColumnModalComponent, config);
    this.gridColumnModal.content.onClose.subscribe(result => {
      if (result) {
        this.selectedMySavedGridView = this.HardCodeColumn[0].name;
        this.checkForDefaultSetting();
        this.setcolumnHeaders(result);
      }
    });
  }

  
  checkForDefaultSetting() {
    if (this.selectedMySavedGridView === 'Select A Saved Setting') {
      this.isDefault = true;
    } else {
      this.isDefault = false;
    }
  }

  setcolumnHeaders(result) {
    this.gridHeaders = [];
    this.adminSvc.getGridColumn(this.screen).subscribe(data => {
      if (result) {
        this.setColumnsByCustomView(result);
        this.displaycolumn = result;
      } else {
        this.setColumnsByDefaultView(data);
      }
      
    });
  }

  setColumnsByCustomView(result) {
    result.forEach(cond => {
      this.gridHeaders.push({
        field: cond.columnname,
        header: cond.friendlyname
      })
    });

    this.gridHeadersUpdate.emit(this.gridHeaders)
  }

  setColumnsByDefaultView(data) {
    if (this.screen === SettingEnum.Appointment) {
      this.setColumnsByCustomView(data.filter(x => x.isdefault === true)) 
    } else if (this.screen === SettingEnum.Patient) {
      this.setColumnsByCustomView([
        { columnname: 'lastname', friendlyname: 'Last Name' },
        { columnname: 'firstname', friendlyname: 'First Name' },
        { columnname: 'gender', friendlyname: 'Gender' },
        { columnname: 'birthdate', friendlyname: 'Date of Birth' },
        { columnname: 'email', friendlyname: 'Email' },
        { columnname: 'mrn', friendlyname: 'MRN' },
        { columnname: 'activetasks', friendlyname: 'Status' }
      ]);
    } else if (this.screen === SettingEnum.Form) {
      this.setColumnsByCustomView([
        { columnname: 'name', friendlyname: 'Name' },
        { columnname: 'facility', friendlyname: 'Facility' },
        { columnname: 'tags', friendlyname: 'Tags' },
        { columnname: 'category', friendlyname: 'Category' },
        { columnname: 'status', friendlyname: 'Status' },
        { columnname: 'type', friendlyname: 'Type' },
        { columnname: 'language', friendlyname: 'Language' }
      ]);
    }
  }

  getUserId() {
    this.userSvc.resolve().subscribe(user => {
      if (user) {
        this.userId =  user['oid'];
      }
      return '';
   });
 }

 applySavedSettings(event) {
  this.checkForDefaultSetting();
  this.adminSvc.getGridSetting(event.id).subscribe(res => {
    this.setcolumnHeaders(res[0]?.displaycolumn);
    if(this.gridHeaders.length > 6) {
      this.table.scrollDirection = "both";
    }
    
    this.table.clear();
    //deep copy dt value for settings to apply
    if(this.screen === SettingEnum.Appointment) {
      this.stateSvc.appointmentsFilteredByDate = JSON.parse(JSON.stringify(this.stateSvc.appointmentsFilteredByDate));
    } else if (this.screen === SettingEnum.Patient) { 
      this.stateSvc.patients = JSON.parse(JSON.stringify(this.stateSvc.patients));
    } else if (this.screen === SettingEnum.Form) { 
      this.stateSvc.filteredExperiences = JSON.parse(JSON.stringify(this.stateSvc.filteredExperiences));
    }

    if (res[0]?.filterby) {
      this.table.filters = res[0].filterby;
    }

    if (res[0]?.sortby) {
      this.table.sortField = res[0].sortby.field;
      this.table.sortOrder = res[0].sortby.order;
    }
  });
}
}
