import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], column: string, filter: string): any {
    if (!items || !filter) {
      return items;
    }

    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    // this reduce allows the 'prop' parameter to dig into grandchild properties with dot notation
    // example:  prop = 'a.b' => item.a.b
    return items.filter(item => column.split('.').reduce((a, b) => a[b], item).toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }
}
