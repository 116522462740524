import {
  ApiService,
  FormFastConfig,
  FormFastConfigService, SolutionAccess,
  TokenService
} from '@next/shared/common';
  import { Inject, Injectable } from '@angular/core';
  import { HttpClient } from '@angular/common/http';
  import { Observable } from 'rxjs';

  @Injectable({
    providedIn: 'root'
  })
  export class SolutionAccessService extends ApiService {

    constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
      super(config, tokenSvc);
    }

    getAllSolutionAccess(): Observable<SolutionAccess[]> {
      return this.protectedEndpoint<SolutionAccess[]>(
        this.http.get<SolutionAccess[]>(`${this.config.apiUrl}solutionaccess/`, this.getHeaders())
      );
    }

    getSolutionAccess(id): Observable<SolutionAccess[]> {
      return this.protectedEndpoint<SolutionAccess[]>(
        this.http.get<SolutionAccess[]>(`${this.config.apiUrl}solutionaccess/${id}`, this.getHeaders())
      );
    }

    postSolutionAccess(solutionAccess): Observable<SolutionAccess> {
      return this.protectedEndpoint<SolutionAccess>(
        this.http.post<SolutionAccess>(`${this.config.apiUrl}solutionaccess/`, solutionAccess ,this.getHeaders())
      );
    }

    putSolutionAccess(solutionAccess): Observable<boolean> {
      return this.protectedEndpoint<boolean>(
        this.http.put<boolean>(`${this.config.apiUrl}solutionaccess/${solutionAccess.id}`, solutionAccess, this.getHeaders())
      );
    }

    deleteSolutionAccess(id: string): Observable<boolean> {
      return this.protectedEndpoint<boolean>(
        this.http.delete<boolean>(`${this.config.apiUrl}solutionaccess/${id}`,this.getHeaders())
      );
    }


  }
