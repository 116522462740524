import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GuidedExperienceDTO,
  GuidedExperienceAnnotation,
  FormFastConfigService,
  FormFastConfig,
  ApiService, TokenService
} from '@next/shared/common';

@Injectable()
export class ExperienceService extends ApiService {

  constructor(@Inject(FormFastConfigService) config: FormFastConfig,
              tokenSvc: TokenService, private http: HttpClient) { super(config, tokenSvc); }

  getAnnotation(formId: string, fieldName: string): Observable<GuidedExperienceAnnotation> {
    return this.protectedEndpoint<GuidedExperienceAnnotation>(
      this.http.get<GuidedExperienceAnnotation>(
        `${this.config.apiUrl}fastflow/forms/${formId}/GuidedExperience/Annotations/${fieldName}`, this.getHeaders())
    );
  }

  getExperiencesForForm(formId: string): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<GuidedExperienceDTO[]>(
      this.http.get<GuidedExperienceDTO[]>(`${this.config.apiUrl}fastflow/forms/${formId}/GuidedExperience`, this.getHeaders())
    );
  }

  getExperience(formId: string, experienceId: number): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<GuidedExperienceDTO>(
      this.http.get<GuidedExperienceDTO>(`${this.config.apiUrl}fastflow/forms/${formId}/GuidedExperience/${experienceId}`,
        this.getHeaders())
    );
  }

  deleteExperience(formId: string, expId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<GuidedExperienceDTO>(
      this.http.delete<GuidedExperienceDTO>(`${this.config.apiUrl}fastflow/forms/${formId}/GuidedExperience/${expId}`, this.getHeaders())
    );
  }

  saveExperience(formId: string, experience: GuidedExperienceDTO) {
    if (experience.id && experience.id !== '') {
      return this.protectedEndpoint<GuidedExperienceDTO>(
        this.http.put<GuidedExperienceDTO>(
          `${this.config.apiUrl}fastflow/forms/${formId}/GuidedExperience/${experience.id}`, experience, this.getHeaders())
      );
    } else {
      return this.protectedEndpoint<GuidedExperienceDTO>(
        this.http.post<GuidedExperienceDTO>(`${this.config.apiUrl}fastflow/forms/${formId}/GuidedExperience`, experience, this.getHeaders())
      );
    }
  }
}
