import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UploadFile } from '@next/shared/common';

@Component({
  selector: 'next-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css']
})

export class UploadDialogComponent {
  title: string;
  successMessage: string;
  addFileInfoMessage: string;
  successUploadMessage: string;
  failureUploadMessage: string;

  filetypeErrorMessage: string;
  duplicateErrorMessage: string;
  genericErrorMessage: string;

  addButton: string;
  cancelButton: string;
  uploadButton: string;
  uploadButtonProcessing: string;
  uploadButtonComplete: string;

  allowedFileTypes: string[] = ['*'];

  onUpload: Subject<UploadFile[]> = new Subject<UploadFile[]>();
  onClose: Subject<boolean> = new Subject<boolean>();

  constructor(public modalRef: BsModalRef) { }

  fileList: UploadFile[] = [];
  uploadExecuted = false;
  dropzoneActive = false;
  newFilesIncludeDuplicate: boolean;

  getFailCount(): number {
    return this.fileList.filter(state => state.status <= 0).length;
  }

  getSuccessCount(): number {
    return this.fileList.filter(state => state.status > 0).length;
  }

  removeFile(myIndex: number): void {
     this.fileList.splice(myIndex, 1);
  }

  onSelectEventToFileList(event): FileList {
    return event.target.files;
  }

  onActionAddFiles(newFileList: FileList): void {
    this.newFilesIncludeDuplicate = false;
    const newFiles: File[] = Array.from(newFileList);
    const fileNames = new Set(this.fileList.map(file => file.file.name));

    for (let i = 0; i < newFiles.length; i++) {
      if (!fileNames.has(newFiles[i].name)) {
        this.fileList.push({ file: newFiles[i], status: null, progress: null });
      } else {
        this.newFilesIncludeDuplicate = true;
      }
    }
  }

  confirm(): void {
    this.uploadExecuted = !this.uploadExecuted;
    this.onUpload.next(this.fileList);
  }

  exit(): void {
    this.modalRef.hide();
    this.onClose.next(this.uploadExecuted);
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  fileItemHoverInfo(file: UploadFile): string {
    if (file.status === 1) {
      return this.successMessage;
    } else if (file.status === -1) {
      return this.filetypeErrorMessage;
    } else if (file.status === 0) {
      return this.genericErrorMessage;
    } else {
      return '';
    }
  }

  uploadBtnFinish(): boolean {
    return (this.uploadExecuted && (this.fileList.filter(file => file.status === null).length === 0));
  }

  uploadBtnMiddle(): boolean {
    return (this.uploadExecuted && (this.fileList.filter(file => file.status === null).length !== 0));
  }

  fileIconStart(file: UploadFile): boolean {
    return (!this.uploadExecuted && file.status == null);
  }

  fileIconMiddle(file: UploadFile): boolean {
    return (this.uploadExecuted && file.status == null);
  }

  fileIconUploadSuccess(file: UploadFile): boolean {
    return (this.uploadExecuted && file.status !== null && file.status > 0 && this.getSuccessCount() > 0);
  }

  fileIconUploadError(file: UploadFile): boolean {
    return (this.uploadExecuted && file.status !== null && file.status <= 0 && this.getFailCount() > 0);
  }
}
