import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NextAdminService, NextFormService, UserResolverService } from '@next/shared/next-services';
import { SignatureFor, TaskDTO } from '@next/shared/common';
import SignaturePad from 'signature_pad';
import { map, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

// User Enum
export interface User {
  id: string;
  fullName: string;
  lastName: string;
  firstName: string;
}

// Total Attempts is hardcoded
// until we add this as a setting
const TOTAL_ATTEMPTS = 10;

@Component({
  selector: 'next-signature-prompt',
  templateUrl: './signature-prompt.component.html',
  styleUrls: ['./signature-prompt.component.scss']
})
export class SignaturePromptComponent implements OnInit, AfterViewInit {
  @ViewChild('codeElement') codeElement: ElementRef;
  @ViewChild('drawCanvas') drawCanvas: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Output() exitModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() staffSignature: EventEmitter<any> = new EventEmitter<any>();

  @Input() task: any;
  @Input() batchSign = false;
  @Input() signatureProperties: any = { };

  signerID: string;
  loggedInUUID: string;
  users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  // Signature Canvas Properties
  sigValue: any = null;
  sigName: string = null;
  drawPad: SignaturePad;
  showCanvas = false;

  // Access Code Form Control Properties
  remainingAttempts: number = TOTAL_ATTEMPTS;
  codeValue = '';
  invalidMatch = false;
  accessCodeDisabled = false;
  accessCode: FormGroup = new FormGroup({
    ['code']: new FormControl('', { updateOn: 'change'})
  });

  // Access Code Masking Properties
  hideCode = true;
  masking = 'ZZZZZZZZ';
  pattern: any = {
    'Z' : { pattern : /\w/, symbol : '•' },
    'Y' : { pattern : /\w/ }
  };
  assignToView = false;

  constructor (
    private toastrSvc: ToastrService,
    private formSvc: NextFormService,
    private adminSvc: NextAdminService,
    public userSvc: UserResolverService
  ) {  }

  ngOnInit(): void {
    forkJoin([this.userSvc.resolve(), this.adminSvc.getUsers()]).pipe(
      tap(([ user, users ]) => {
        this.users.next(users.map(el => ({ id: el.id, firstName: el.firstname, lastName: el.lastname, fullName: `${el.firstname} ${el.lastname}`})));
        this.loggedInUUID = this.signerID = user.oid;
      })
    ).subscribe();

    this.accessCode.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.codeValue = value.code;
        if (!this.codeValue) this.invalidMatch = false;
        this.masking = 'Z'.repeat(Math.max(value.code.length - 1, 0)) + 'YY';
      });
    });
    this.showCanvas = this.signatureProperties.signatureFor !== SignatureFor.Staff;
  }

  ngAfterViewInit(): void {
    // Set up the canvas if canvas view on start
    if (this.showCanvas && !this.batchSign) {
      setTimeout(() => {
        this.setupDrawCanvas(true);
      });
    }
    // Focus the input in Staff Access code view
    else if (!this.batchSign) {
      this.codeElement.nativeElement.focus();
    }
  }

  cancel(): void {
    this.exitModal.emit(null);
    this.signerID = null;
    this.accessCode.patchValue({ ['code']: ''});
    if (!this.batchSign) {
      this.clearSignature();
    }
  }

  async sign(code: string = ''): Promise<void> {
    const accessCode: string = code || this.codeValue;
    const signatureType: string = this.signatureProperties?.signatureType || ''
    this.remainingAttempts -= 1;
    if (this.remainingAttempts <= 0) {
      this.accessCodeDisabled = true;
      this.accessCode.patchValue({ ['code']: ''});
      this.accessCode.controls['code'].disable();
      this.signerID = null;
    }
    if (accessCode && this.signerID) {
      const serverCode = await this.adminSvc.getPreference(this.signerID, 'ACCESSCODE').pipe(
        map(res => res[0].data.ACCESSCODE)
      ).toPromise().catch(err => {
        this.toastrSvc.error(err.message);
      });

      if (serverCode === accessCode) {
        const staffSigData = { id: this.signerID, code: accessCode, signatureType: signatureType };
        this.staffSignature.emit(staffSigData);
        this.accessCode.patchValue({ ['code']: ''});
        this.exitModal.emit(null);
      }
      else {
        this.invalidMatch = true;
      }
    }
  }

  applySignature(): void {
    const staffSigData: any = {
      applySignature: true,
      Type: 'Signature',
      Value: { Strokes: this.sigValue, SignedDate: new Date() }
    };
    this.staffSignature.emit(staffSigData);
    this.exitModal.emit(null);
  }

  signWithoutCode(): void {
    this.batchSign = false;
    this.showCanvas = true;
    this.setupDrawCanvas(true);
  }

  /**
   * Toggle the assign-to view
   */
  assignTo(): void {
    this.assignToView = true;
  }

  /**
   * Callback function for when assign-to submit performed
   * @param staffTask
   */
  assignToCb(staffTask: TaskDTO): void {
    this.formSvc.addPatientForm(staffTask).subscribe({
      next: () => {
        this.assignToView = false;
      },
      error: (err) => { this.toastrSvc.error(err.message); },
      complete: () => { this.exitModal.emit(null); }
    });
  }

  toggleHiddenCode(): void {
    this.hideCode = !this.hideCode;
    this.masking = 'ZZZZZZZZ';
    if (this.hideCode) {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : '•' },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
    else {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : null },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
  }

  setupDrawCanvas(resize: boolean = false): void {

    const modal = document.getElementsByClassName("modal-iop-signature-prompt")[0] as any;
    modal.style.maxWidth = "1200px";

    const canvas: HTMLCanvasElement = <HTMLCanvasElement>this.drawCanvas.nativeElement;
    const prevWidth = canvas.width;
    const prevHeight = canvas.height;
    const currentWidth = this.container.nativeElement.offsetWidth - 16;

    canvas.width = currentWidth;
    canvas.height = currentWidth / 5;
    canvas.style.width = currentWidth + 'px';
    canvas.style.height = (currentWidth / 5) + 'px';

    this.drawPad = new SignaturePad(canvas, {
      maxWidth: 3,
      minWidth: 1,
      onBegin: () => {
        this.setCSSUserSelect('none');
      },
      onEnd: () => {
        this.saveDrawing();
        this.setCSSUserSelect('auto');
      }
    });
    if (this.sigValue && resize) {
      const xRatio: number = canvas.width / prevWidth;
      const yRatio: number = canvas.height / prevHeight;
      this.resizeData(this.sigValue, xRatio, yRatio);
      this.drawPad.fromData(this.sigValue);
    }
  }

  resizeData(data: any, ratioX: number, ratioY: number): void {
    if (data) {
      data.forEach(function (stroke) {
        stroke.points.forEach(function (point: any) {
          point.x *= ratioX;
          point.y *= ratioY;
        });
      });
    }
  }

  clearSignature(): void {
    if (this.drawPad) this.drawPad.clear();
    this.sigValue = null;
  }

  saveDrawing(): void {
    this.sigValue = this.drawPad.toData();
  }

  setCSSUserSelect(val: string) : void {
    window.document.body.style.userSelect = val;
  }

  enterKeyPressed():void{
    this.sign();
  }

  get attemptsObject(): any {
    return { attempts: this.remainingAttempts, totalAttempts: TOTAL_ATTEMPTS }
  }

  @HostListener("window:resize") orientation(): void {
    if (this.drawPad) {
      this.drawPad.off();
      this.setupDrawCanvas(true);
    }
  }
}
