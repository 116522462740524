export interface Authorization {
  accessToken: string;
  idToken: string;
  expiresIn: number;
  firstTimeUser?:boolean;
}

export enum Logout {
  STORE_KEY =  'lastAction',
  CHECK_INTERVAL = 15000, // in ms
  WARNING_TIME = 5,
  TIMEOUT_VALUE = 10
}