<div class="position-absolute h-100 w-100">
  <div class="position-relative h-100 w-100">
    <ng-container [ngSwitch]="viewMode">
      <next-viewer *ngSwitchCase="'web'" [instance$]="instance$"></next-viewer>
      <next-pdf-viewer *ngSwitchCase="'pdf'" [instance$]="instance$"></next-pdf-viewer>
    </ng-container>
  </div>
</div>



