import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  Patient,
  TaskDTO,
  TokenService,
  Appointment
} from '@next/shared/common';
import { ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class NextClinicalService extends ApiService {
  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    private tokenSvc: TokenService,
    private http: HttpClient
  ) {
    super(config, tokenSvc);
  }

  getPatients(search: string): Observable<Patient[]> {
    return this.protectedEndpoint<Patient[]>(
      this.http.get<any>(`${this.config.apiUrl}patient?_content=${search}`, this.getHeaders()).pipe(
        map(res => res)));
  }

  getAppointmentsByPersonName(search: string, facility: string): Observable<Appointment[]> {
    return this.protectedEndpoint<Appointment[]>(
      this.http.get<any>(`${this.config.apiUrl}appointment/patient/search?_content=${search}&_facility=${facility}`, this.getHeaders()).pipe(
        map(res => res)));
  }

  getAppointmentsByDate(datetime: Date, facility: string): Observable<Appointment[]> {
    const dateOnly = `${datetime.getMonth() + 1}/${datetime.getDate()}/${datetime.getFullYear()}`;
    return this.protectedEndpoint<Appointment[]>(
      this.http.get<any>(`${this.config.apiUrl}appointment/date/search?_content=${dateOnly}&_facility=${facility}`, this.getHeaders()).pipe(
        map(res => res)));
  }

  updateAppointment(id, data) {
    return this.protectedEndpoint<Appointment[]>(
      this.http.put<any>(`${this.config.apiUrl}appointment/${id}`, data, this.getHeaders()).pipe(
        map(res => res)));
  }

  getPatient(patientId: string): Observable<Patient> {
    return this.protectedEndpoint<Patient>(
      this.http.get<any>(`${this.config.apiUrl}patient/${patientId}`, this.getHeaders()).pipe(
        map(res => res)));
  }

  getTasksForUser(id: string): Observable<TaskDTO[]> {
    return this.protectedEndpoint<TaskDTO[]>(
      this.http.get<TaskDTO[]>(`${this.config.apiUrl}task/person/${id}`, this.getHeaders()).pipe(
        map(res => res)));
  }

  getPrefill(experienceId: string, params: ParamMap, additionalData: any = { }): Observable<any> {
    // Allow the viewer's caller to pass through any custom params needed for prefill
    let dynamicParams = '';
    for (const key of params.keys) {
      if (key === 'token' || key === 'experienceId') continue;
      dynamicParams += `&${key}=${params.get(key) || ''}`;
    }

    for (const key of Object.keys(additionalData)) {
        dynamicParams += `&${key}=${additionalData[key]}`;
    }

    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}prefill/composition?experienceId=${experienceId}${dynamicParams}`, this.getHeaders())
    );
  }

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      return throwError(error.error.message);
    }
  }

  getFormsByDate(datetime: Date): Observable<TaskDTO[]> {
    return this.protectedEndpoint<TaskDTO[]>(
      this.http.get<TaskDTO[]>(`${this.config.apiUrl}task/date/search?_date=${datetime}`, this.getHeaders()).pipe(
        map(results => results.map(res => {
          return {
            id: res.id,
            name: res.name,
            formid: res.formid,
            assignto: res.assignto,
            experienceid: res.experienceid,
            experienceversionid: res.experienceversionid,
            status: res.status,
            issuedon: res.issuedon,
            issuedby: res.issuedby,
            completedon: res.completedon,
            tags: res.tags,
            type: res.type,
            fields: res.fields,
            signall: res.signall,
            signaturefor: res.signaturefor,
            signaturetype: res.signaturetype
          } as TaskDTO
        }))
      )
    )
  }
}
