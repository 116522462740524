<div class="container-fluid h-100 custom-padding">
    <div class="row d-flex px-0 pb-2 align-items-center modal-header justify-content-between title">
      <div class="col-2">
        <button id='appointment-save-button' class='btn-plain pointer-on-hover text_button' (click)='close()'>
          {{ 'APPOINTMENTS.COLUMN_SETTINGS.CLOSE_BUTTON' | translate }}
        </button>
      </div>
      <div class="col-8 text-center">
        <h6>{{'APPOINTMENTS.COLUMN_SETTINGS.TITLE' | translate }}</h6>
      </div>
      <div class="col-2">
        <button id='appointment-save-button' class='btn primaryButton w-100' (click)='save()'>
          {{ 'APPOINTMENTS.COLUMN_SETTINGS.SAVE_BUTTON' | translate }}
        </button>
      </div>
    </div>
    <div class="picklist">
        <p-pickList 
          [source]="sourceData" 
          [target]="targetData" 
          [dragdrop]="true"
          [sourceStyle]="{'height':'20rem'}" 
          [targetStyle]="{'height':'20rem'}"
          [responsive]="true">
          <ng-template let-column pTemplate="item">
                {{column.friendlyname}}
          </ng-template>
        </p-pickList>
    </div>
    </div>
  