import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ActivityLog,
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
} from '@next/shared/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ActivityLogService extends ApiService {
  constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
    super(config, tokenSvc);
  }
  getActivityLogByPatientId(patientId: string): Observable<ActivityLog[]> {
    return this.protectedEndpoint<ActivityLog[]>(
      this.http.get<ActivityLog[]>(`${this.config.apiUrl}activitylog/${patientId}`, this.getHeaders())
    );
  }
}
