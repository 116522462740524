import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {tap} from "rxjs/operators";
import {HttpProgressService} from "../http-progress.service";

@Injectable({ providedIn: 'root' })
export class HttpProgressInterceptor implements HttpInterceptor {

  constructor(private httpProgressSvc: HttpProgressService) { }
  /** If ___reportProgress : true___ is found in the _HttpRequestHeader_ then
   * intercept the request and add a _progress_ property to the result _HttpResponse_ object */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.reportProgress) {
      return next.handle(request).pipe(
        tap((event: HttpEvent<unknown>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.httpProgressSvc.eventSub$.next(event);
                this.httpProgressSvc.progress$.next(0);
                break;

              case HttpEventType.DownloadProgress:
              case HttpEventType.UploadProgress:
                this.httpProgressSvc.eventSub$.next(event);
                this.httpProgressSvc.progress$.next((event.loaded / event.total) * 100);
                break;

              case HttpEventType.Response:
                Object.assign(event, { progress: 100 });
                this.httpProgressSvc.eventSub$.next(event);
                this.httpProgressSvc.progress$.next(100);
                return event;
            }},
          (error) => {
            return throwError(error);
          },
          () => {
            // The request is finished, so ping the listener
            this.httpProgressSvc.completed.next(null);
          }));
    }
    else {
      return next.handle(request);
    }
  }
}
