import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  Image,
  TokenService,
  UploadFile
} from '@next/shared/common';

@Injectable()

export class ImageService extends ApiService {
  validImageTypes: string[] = ['image/bmp', 'image/gif', 'image/jpg', 'image/jpeg', 'image/png'];

  constructor(@Inject(FormFastConfigService) config: FormFastConfig,
              tokenSvc: TokenService, private http: HttpClient) { super(config, tokenSvc); }

  isValidFileType(file: File): boolean {
    return this.validImageTypes.includes(file.type.toString());
  }

  upload(files: UploadFile[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.progress = 0;
      if (!this.isValidFileType(file.file)) {
        file.status = -1;
      } else {
        const formData: FormData = new FormData();
        formData.append('image', `{"id":null,"Name":"${file.file.name}","Url":null}`);
        formData.append('', file.file, file.file.name);

        const req = new HttpRequest('POST', `${this.config.apiUrl}image`, formData, this.getUploadHeaders());
        this.protectedEndpoint<any>(this.http.request(req)).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              files[i].progress = Math.ceil(+(event.loaded / event.total).toFixed(2) * 100);
            }
          },
          err => {
            files[i].status = 0;
            files[i].progress = 0;
          },
          () => {
            files[i].status = 1;
            files[i].progress = 0;
          }
        );
      }
    }
  }

  deleteImage(img: Image): Observable<void> {
    return this.protectedEndpoint(this.http.delete<void>(`${this.config.apiUrl}images/${img.id}`, this.getHeaders()));
  }

  getImages(): Observable<Image[]> {
    return this.protectedEndpoint(this.http.get<Image[]>(`${this.config.apiUrl}images`, this.getHeaders()));
  }
}
