<div
  dropdown
  #dropdown='bs-dropdown'
  [container]='appendTo || "body"'
  placement='left'
  class="btn-group"
  (onShown)='dropdownOpen()'
  [insideClick]='true'
  [isAnimated]='true'
  [autoClose]='false'>
  <button dropdownToggle class='btn-unstyled'>
    <fa-icon [icon]='["fas", "tag"]' class='tag-toggle-icon'></fa-icon>
  </button>
  <div *dropdownMenu class='dropdown-menu-tags dropdown-menu dropdown-menu-left'>
    <div class='container-fluid justify-content-center h-100'> 

      <div class='row justify-content-between d-flex pt-3 pb-0 align-items-center header-background modal-rounded'>
        <div class="col-4">
          <button (click)="close()" role="button" type="reset" class="btn btn-unstyled cancel-btn">{{ 'TAG_SEARCH.CANCEL' | translate }}</button>
        </div>
        <div class="col-8">
          <h5>{{ 'TAG_SEARCH.TITLE' | translate }}</h5>
        </div>        
      </div>
      <div class='row p-0 align-items-center header-background border-bottom'>
        <div class='col-12 px-2 pb-2'>
          <label for="form-search"></label>
          <input
            [(ngModel)]='searchFilter'
            id="form-search"
            role='searchbox'
            type="text"
            class="form-control"
            placeholder="{{'TAG_SEARCH.PLACEHOLDER' | translate}}"
            autocomplete='off'/>
        </div>
      </div>
      <div class='row modal-body'>
        <div class='container-fluid m-0 p-0'>
          <div class='row p-2 align-content-center' *ngFor='let tag of allTags | filter: "tag":searchFilter | sortBy: "asc":"tag"; let i = index'>
            <button class='col-2 pr-0 text-center btn-unstyled' (click)='onTagClick(tag)'>
              <fa-icon [icon]='isActive(tag) ? ["fas","check-circle"] : ["far","circle"]' size='lg' class='tag-icon'></fa-icon>
            </button>
            <div class='col-8 text-left'>
              <fa-icon [icon]='["fas", "tag"]' size='lg' class='tag-icon'></fa-icon>&nbsp;{{ tag.tag }}
            </div>
          </div>
        </div>
      </div>
      <div class='row py-2 align-content-center header-background'>
        <div class='col-12'>
          <button type='submit' class='btn btn-primary w-100 btn-tag-submit' (click)='submit()'>
            {{ 'TAG_SEARCH.SUBMIT_BTN' | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
