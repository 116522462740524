import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldDTO, RadiogroupFieldDTO } from '@next/shared/common';
import { state, trigger, style, animate, transition } from '@angular/animations';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-radiogroup',
  templateUrl: './radiogroup.component.html',
  styleUrls: ['./radiogroup.component.css'],
  animations: [
    trigger('subField', [
      state('in', style({
        opacity: 1
      })),
      transition('void => in', [
        style({ opacity: 0.25, transform: 'translateY(1rem)' }),
        animate('300ms ease-in-out')
      ]),
    ]),
  ],
})

export class RadiogroupComponent extends FieldBaseComponent implements OnInit, AfterViewInit {

  @Input() form: FormGroup;
  @Input() field: RadiogroupFieldDTO;
  @Input() initialState: any;
  @Input() attachments: any;
  @Input() needsValidation: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.processOnInit(this.field);
  }

  ngAfterViewInit(): void {
    super.processAfterViewInit(this.field);
    this.valueChangeSub();
  }

  onChange() {
    if (this.field.switch.length) {
      this.resetDynamicChildren(this.field);
    }
  }

  getSwitchFields(key: string): FieldDTO[] {
    const switchFields = this.field.switch.find(s => s.when === key);
    if (switchFields) {
      return switchFields.fields;
    }
  }
}
