import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ErrorRibbonService } from '../state/error-ribbon.service';

@Component({
  selector: 'next-viewer-warning-ribbon',
  templateUrl: './viewer-warning-ribbon.component.html',
  styleUrls: ['./viewer-warning-ribbon.component.css'],
  animations: [
    trigger('errorRibbon', [
      state('in', style({ transform: 'translateY(0px)' })),
      state('out', style({ transform: 'translateY(-68px)' })),
      transition('* => *', animate('900ms ease-in-out'))
    ])
  ]
})

export class ViewerWarningRibbonComponent implements OnInit {
  private display: string;

  constructor(public errorSvc: ErrorRibbonService) { }

  ngOnInit() {
    this.display = 'out';
  }

  showWarning() {
    this.display = 'in';
  }

  closeWarning() {
    this.display = 'out';
  }
}
