/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TagService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GuidedExperienceDTO, Tag, TagJunction, TagMode } from '@next/shared/common';
import { of } from 'rxjs';
import { ConfirmationDialogComponent } from '@next/shared/ui';

@Component({
  selector: 'next-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})

export class ManageTagsComponent implements OnInit {
  TagMode: typeof TagMode = TagMode;  // Allow this enum to be used in the template

  @Input() experiences: GuidedExperienceDTO[];
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter<boolean>();

  mode: TagMode = TagMode.search;
  addTagContext = false;
  allTags: Tag[] = [];
  tags: Tag[] = [];

  manageTag: FormGroup = new FormGroup({
    newtag: new FormControl('', { validators: [Validators.required] }),
    edittag: new FormControl(''),
    search: new FormControl('')
  });

  formArray: FormArray = new FormArray([]);
  modalRef: BsModalRef;

  pattern: any = {
    'Z' : { pattern : /^(?!.*[\/{}=$%@"<>`~+]).*/ }
  };

  constructor (
    private tagService: TagService,
    private fb: FormBuilder,
    private translateSvc: TranslateService,
    private modalSvc: BsModalService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.tagService.getAllWithActiveTagsIndicatedMultiple(this.experiences.map(e => e.id)).subscribe((tags: Tag[]) => {
      this.tags = this.allTags = tags;
      this.formArray = new FormArray([]);
      for (const tag of tags) {
        const record: FormGroup = this.fb.group({
          name: [tag.tag || '', Validators.required],
          id: tag.id,
          count: tag.count,
          currentreferences: tag.currentreferences,
          active: tag.active
        });
        this.formArray.push(record);
      }
    });
  }

  toggleActiveTag(tag: Tag): void {
    const num: number = parseFloat(this.experiences.length + '');

    tag.active = !tag.active;
    if (!tag.active) {
      tag.count = parseFloat(tag.count + '') - parseFloat(num + '');
      tag.currentreferences = parseFloat(tag.currentreferences) - num + '';
    }
    else {
      tag.count = parseFloat(tag.count + '') + (parseFloat(num + '') - parseFloat(tag.currentreferences));
      tag.currentreferences = num + '';
    }
    this.formArray.markAsDirty();
  }

  submit(): void {
    if (this.formArray.dirty) {
      const tagData = this.formArray.value;
      const expData = this.experiences.map(e => e.id);
      const payload = [];
      for (const id of expData) {
        for (const tag of tagData) {
          payload.push({
            tagid: tag.active ? tag.id : null,
            elementid: id,
            elementtype: tag.active ? 'forms' : 'marked-for-delete'
          } as TagJunction);
        }
      }

      this.tagService.applyTag(payload).subscribe(res => {
        this.mode = TagMode.search;
        this.closeWindow.emit(true);
      });
    }
    else {
      this.closeWindow.emit(false);
    }
  }

  saveTag(field): void {
    this.tagService.editTag(field.value.id, {
      id: null,
      tag: field.value.name,
      count: null,
      active: null
    }).subscribe({
      next: (res) => {
        this.toastr.success('Tag updated');
        field.markAsPristine();
      },
      error: (err) => { this.toastr.error(err.message) }
    });
  }

  deleteTag(field: Tag, i: number): void {
    let delMessage: string = this.translateSvc.instant('TAGS.DELETE.MSG_DELETEWARNING')
    delMessage = delMessage.replace('$tagReferenceCount', field.count.toString());

    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, {
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        title: this.translateSvc.get('TAGS.DELETE.CONFIRM_DEL_TITLE'),
        message: of(delMessage),
        cancelButton: this.translateSvc.get('TAGS.DELETE.BTN_CANCEL'),
        confirmButton: this.translateSvc.get('TAGS.DELETE.BTN_DELETE')
      }
    });

    this.modalRef.content.onClose.subscribe(result => {
      if (result && field.id) {
        this.tagService.deleteTag(field.id).subscribe(res => {
          this.loadData();
          this.toastr.success(this.translateSvc.instant('TAGS.DELETE.MSG_DELETESUCCESS'));
        }, (err) => this.toastr.error(err.message))
      }
    });
  }

  addTag(): void {
    const tag = this.manageTag.value;
    const formData: Tag = {
      id: null,
      tag: tag.newtag,
      count: null,
      active: null
    };

    if (!this.tagNameExists(formData.tag)) {
      this.tagService.createTag(formData).subscribe(x => {
        this.manageTag.patchValue({['newtag']: '' });
        this.manageTag.markAsPristine();
        this.loadData();
      });
    }
    else {
      this.toastr.error(this.translateSvc.instant('TAGS.CREATE.MSG_TAG_ALREADY_EXIST'));
    }
  }

  tagNameExists(searchValue: string): boolean {
    const data = this.tags.filter(t => t.tag.toLowerCase() === searchValue.toLowerCase());
    return data.length > 0;
  }

  onManageTagsExit(): void {
    this.closeWindow.emit(this.formArray.dirty);
  }
}
