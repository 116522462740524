import { Component, Input, OnInit } from '@angular/core';
import { GridColumn } from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-grid-column-modal',
  templateUrl: './grid-column-modal.component.html',
  styleUrls: ['./grid-column-modal.component.scss']
})
export class GridColumnModalComponent implements OnInit {

  @Input() displaycolumn: GridColumn[];

  sourceData: GridColumn[] = [];
  targetData: GridColumn[] = [];
  allColumns: GridColumn[] = [];

  onClose: Subject<any> = new Subject<any>();
  notification = '';
  activeColumn: Column [] = [];

  @Input() screen: string;

  constructor(
    public stateSvc: StoreService,
    public modalRef: BsModalRef,
    private adminSvc: NextAdminService,
  ) {  }

  ngOnInit() {
    this.getData();
  }

  close() {
    this.onClose.next(null);
    this.modalRef.hide();
  }

  save() {
    this.onClose.next(this.targetData);
    this.modalRef.hide();
  }

  getData() {
    this.adminSvc.getGridColumn(this.screen).subscribe(res => {
      this.allColumns = res;

      if (this.displaycolumn) {
        const idsToDelete = this.displaycolumn.map(function(elt) {return elt.id;});
        this.sourceData = this.allColumns.filter(function(elt) {return idsToDelete.indexOf(elt.id) === -1;});
        this.targetData = this.displaycolumn;
      } else {
        this.sourceData = this.allColumns.filter(item => item.isdefault === false);
        this.targetData = this.allColumns.filter(item => item.isdefault === true);
      }
    });
  }
}

interface Column {
  name: string
}
