<div class="form-group rounded" [ngClass]="{ 'd-none' : field.hidden }" [formGroup]="valueFormGroup">
  <label for="{{ field.name }}" class="m-0 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required bold">&nbsp;*</span>
  </label>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <fa-icon [icon]="['fas', 'id-card']" class="margin-center"></fa-icon>
      </span>
    </div>
    <input
      class="form-control"
      formControlName="Text"
      autocomplete="off"
      id="{{ field.name }}"
      name="{{ field.name }}"
      type="text"
      inputmode="decimal"
      [placeholder]="field.placeholder || 'xxx-xx-xxxx'"
      [readonly]="field.readonly"
      [required]="field.required"
      [ngClass]="{ 'invalid' : valueFormGroup.get('Text')?.invalid && (!newField || needsValidation) }"
      [pattern]="SSNPattern"
      [mask]="field?.maskSocial ? 'XXX-XX-0000' : 'XXX-XX-XXXX'"
      [hiddenInput]="hiddenText"
      [specialCharacters]="['-']"
      [dropSpecialCharacters]="false">
    <div id="{{ field.name + '_show' }}" class="input-group-append" (focusout)="hiddenText = true">
      <button
        id="{{ field.name + '_show_btn' }}"
        class="btn btn-outline-primary input-group-append-width"
        type="button"
        (click)="toggleHiddenText()">
        {{ hiddenText ? DisplaySSN.SHOW : DisplaySSN.HIDE }}
      </button>
    </div>
  </div>
  <!------------------------
  Validation Error Message
  ------------------------->
  <ng-container *ngIf="valueFormGroup?.get('Text')">
    <span *ngIf="valueFormGroup?.get('Text').invalid && (!newField || needsValidation)" class="float-right text-sizing color-danger">
      {{ getFormattingError(valueFormGroup?.get('Text')) | translate:getFormattingErrorParam(valueFormGroup?.get('Text')) }}
    </span>
  </ng-container>
</div>
