import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  CollectionType,
  GuidedExperienceDTO,
  GxProcessing,
  Patient,
  TaskDTO,
  TaskStatus,
  PatientFormStateDisplay,
  TaskType,
  FormStatus
} from '@next/shared/common';
import {
  ArchiveService,
  NextExperienceService,
  NextFormService,
  PatientService,
  TagService,
  UserResolverService
} from '@next/shared/next-services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { StateViewerService } from '../state/state-viewer.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@next/shared/ui';
import { SelectAppointmentComponent } from '../select-appointment-modal/select-appointment-modal.component';
// noinspection ES6PreferShortImport
import { AddFormsModalComponent } from '../add-forms-modal/add-forms-modal.component';
import { StoreService } from  '../state/store.service';
import { tap } from 'rxjs/operators';
import { FormViewComponent } from './components/form-view/form-view.component';

@Component({
  selector: 'next-forms-table',
  templateUrl: './forms-table.component.html',
  styleUrls: ['./forms-table.component.scss']
})
export class FormsTableComponent implements OnInit, OnChanges {

  @Input() prefill: any;
  @Input() assignToId: string;
  @Input() patientData: Patient;
  @Input() assignType: TaskType;
  @Input() enterpriseAppointmentName: string;

  prefillFormatted: any;

  patientName: string;
  fillOutDisabled = true;
  shareDisabled = true;
  printDisabled = true;
  columnHeaders: any[] = [];   // primeNG Table config
  menuItems: MenuItem[] = [];  // primeNG Menu config

  dataset: TaskDTO[] = [];       // primeNG Table data source
  selectedRows: TaskDTO[] = [];  // primeNG Table selected rows

  publishedExperiences: GuidedExperienceDTO[] = [];
  modalRef: BsModalRef;
  shareMessage: string;
  tasks: TaskDTO[] = [];
  archiveLabel: string;
  viewLabel: string;

  constructor(
    private userSvc: UserResolverService,
    private tagSvc: TagService,
    private modalSvc: BsModalService,
    private experienceSvc: NextExperienceService,
    private translateSvc: TranslateService,
    private formSvc: NextFormService,
    private stateViewerSvc: StateViewerService,
    private router: Router,
    private toastrSvc: ToastrService,
    private patientSvc: PatientService,
    private archiveSvc: ArchiveService,
    private stateSvc: StoreService,
    private gxProcessing: GxProcessing
  ) { }

  ngOnInit() {
    this.patientName = `${this.patientData.firstname} ${this.patientData.lastname}`;
    this.translateSvc.get('PATIENT_FORMS.SELECT_APPOINTMENT.MESSAGE', this.appointmentName).subscribe(t=>this.shareMessage = t);
    this.translateSvc.get(['PATIENT_FORMS.COLUMN_HEADERS', 'PATIENT_FORMS.TABLE_MENU']).subscribe(val => {
      const headers = val['PATIENT_FORMS.COLUMN_HEADERS'];
      const menu = val['PATIENT_FORMS.TABLE_MENU'];
      this.archiveLabel = menu.ARCHIVE;
      this.viewLabel = menu.VIEW;
      this.columnHeaders = [
        { field: "name", header: headers.NAME },
        { field: "status", header: headers.STATUS },
        { field: "lastarchivedon", header: headers.ARCHIVED },
        { field: "issuedon", header: headers.MODIFIED },
        { field: "tags", header: headers.TAG }
      ];

      // Setup ellipsis column actions
      if (this.userSvc.hasDemonstrationLicense) this.menuItems.push({label: menu.TO_TABLET_VIEW, command: () => { this.commandTransferTablet() }});
      this.menuItems.push({label: menu.VIEW, command: () => { this.commandView() }});
      this.menuItems.push({label: menu.DELETE, command: () => { this.commandDelete() }});
      this.menuItems.push({label: menu.SIGN, command: () => { this.commandSign() }});
      this.menuItems.push({label: menu.PRINT_FORM, command: () => { this.commandPrint() }});
      if (this.userSvc.hasDemonstrationLicense) this.menuItems.push({label: menu.EMAIL_PATIENT, command: () => { this.commandEmail() }});
      this.menuItems.push({label: menu.ARCHIVE, command: () => { this.commandArchive() }});

    });
    this.printDisabled = true;
  }

  ngOnChanges() {
    this.selectedRows = [];
    this.setHeaderButtonsDisabled(true);

    this.loadData();

    switch (this.assignType) {
      case TaskType.Appointment:
        this.prefillFormatted = {
          IHOP_Patient: this.prefill.patientdata,
          IHOP_Appointment: this.prefill
        };
        break;
      case TaskType.Patient:
        this.prefillFormatted = {
          IHOP_Patient: this.prefill,
          IHOP_Appointment: { }
        };
        break;
    }
  }

  ellipsisClick(dataRow: TaskDTO): void {
    const isDataRowSelected = !!this.selectedRows.find(row => row.id === dataRow.id);
    if (!isDataRowSelected) {
      this.selectedRows = [ dataRow ];
      this.setHeaderButtonsDisabled(false);
    }
    this.menuItems.find(x => x.label === this.archiveLabel).disabled = dataRow.status.toLowerCase() === TaskStatus.NotStarted;

     // disable view option when multi forms is selected
     this.menuItems.find(x => x.label === this.viewLabel).disabled = this.selectedRows.length > 1;
  }

  async showAddForm(): Promise<void> {
    this.modalRef = this.modalSvc.show(AddFormsModalComponent, {
      ignoreBackdropClick: false,
      keyboard: true,
      initialState: {
        treeMode: true
      }
    });
    this.modalRef.content.modalClose.pipe(
    ).subscribe(() => this.modalRef.hide());
    this.modalRef.content.modalSubmit.pipe(
    ).subscribe((selected) => {
      if (selected.length) {
        this.submitCallback(selected);
        this.modalRef.hide();
      }
    });
  }

  submitCallback(items: any[]): void {
    const tasks: TaskDTO[] = [];
    for (const element of items) {
      switch (element.type) {
        case CollectionType.Packet:
          // eslint-disable-next-line no-case-declarations
          const packetContent = element.children.map(el => {
            return {
              name: el.name,
              assignto: this.assignToId,
              type: this.assignType,
              experienceid: el.id,
              experienceversionid: el.vid,
              status: TaskStatus.NotStarted,
              formid: null
            } as TaskDTO;
          });
          tasks.push(...packetContent);
          break;
        case CollectionType.Form:
          tasks.push({
            name: element.name,
            assignto: this.assignToId,
            type: this.assignType,
            experienceid: element.id,
            experienceversionid: element.vid,
            status: TaskStatus.NotStarted,
            formid: null
          } as TaskDTO);
      }
    }

    this.formSvc.addPatientForms(tasks).subscribe(async (res: TaskDTO[]) => {
      this.stateViewerSvc.selectedTasks = res;
      this.tasks.push(...res);
      this.loadData();
    }, (err) => { this.toastrSvc.error(err.message) });
  }

  modalExit(): void {
    this.modalRef.hide();
  }

  fillOutForm(viewMode=false): void {
    this.stateViewerSvc.viewMode = viewMode;
    this.stateViewerSvc.selectedTasks = this.selectedRows;
    this.router.navigate(['form/viewer'], { state: { route: this.router.url }  });
  }

  setHeaderButtonsDisabled(flag : boolean): void {
    this.shareDisabled = flag;
    this.fillOutDisabled = flag;
    this.printDisabled = flag;
  }

  shareForm(): void {
    if (this.assignType === TaskType.Appointment) {
       this.shareToPatient().catch(error => this.toastrSvc.error(error));
    }
    else {
      this.shareToAppointment().catch(error => this.toastrSvc.error(error));
    }
  }

  private async shareToPatient(): Promise<any> {
    const config  = {
      initialState: {
        title: this.translateSvc.get('CONFIRMATION_SHARE.TITLE'),
        message: this.translateSvc.get('CONFIRMATION_SHARE.MESSAGE'),
        cancelButton: this.translateSvc.get('CONFIRMATION_SHARE.CANCEL'),
        confirmButton: this.translateSvc.get('CONFIRMATION_SHARE.CONFIRM')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    };

    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.creatFormTasks(TaskType.Patient, this.patientData.id);
      }
    });
  }

  private async shareToAppointment(): Promise<any> {
    this.patientSvc.getPatientAppointments(this.patientData.id).subscribe((appointments) => {
      if (appointments.length === 1) {
        return this.creatFormTasks(TaskType.Appointment, appointments[0].id);
      }
      else if (appointments.length) {
        const modal: BsModalRef = this.getAppointmentModal();
        modal.content.cancel.subscribe(() => modal.hide());
        modal.content.appointmentSelected.subscribe(r => {
          modal.hide();
          return this.creatFormTasks(TaskType.Appointment, r.id)
        });
      }
    }, (error) => {
      if (error.status === 404) {
         this.toastrSvc.error((this.translateSvc.instant('PATIENT_FORMS.NO_APPOINTMENT', { appointment : this.stateSvc.enterpriseSettings.appointments.toLowerCase()})));
      }
    });
  }

  private getAppointmentModal(): BsModalRef {
    return this.modalSvc.show(SelectAppointmentComponent, {
      class: 'modal-narrow', backdrop: true, keyboard: false, ignoreBackdropClick: true,
      initialState: { personId: this.patientData.id, patientName: this.patientName, message: this.shareMessage,enterpriseAppointmentName:this.enterpriseAppointmentName }
    });
  }

  private creatFormTasks(taskType: TaskType, assignToId: string): TaskDTO[] {
    const newTasks: TaskDTO[] = [];
    for (const row of this.selectedRows) {
      newTasks.push({
        assignto: assignToId,
        name: row.name,
        status: TaskStatus.NotStarted,
        type: taskType,
        experienceid: row.experienceid,
        experienceversionid: row.experienceversionid
      } as TaskDTO)
    }
    this.formSvc.addPatientForms(newTasks).subscribe(
      () => { this.toastrSvc.success(this.translateSvc.instant('CONFIRMATION_SHARE.MESSAGE_SUCCESS')) },
      err => { throw err }
    )
    return newTasks;
  }

  formatStatus(statusString: string): string {
    return PatientFormStateDisplay[statusString.toUpperCase()];
  }

  commandTransferTablet(): void {
    console.log('TransferTable()');
  }

  commandView(): void {
    const modal = this.modalSvc.show(FormViewComponent, {
      ignoreBackdropClick: false,
      keyboard: true,
      class: 'modal-form-view h-100',
      initialState: {
        target: this.selectedRows.pop()
      }
    });
    modal.content.closeModal.subscribe(() => { modal.hide() });
  }

  commandDelete(): void {
    const config  = {
      initialState: {
        title: this.translateSvc.get('CONFIRMATION_DEL.TITLE'),
        message: this.translateSvc.get('CONFIRMATION_DEL.TASK'),
        cancelButton: this.translateSvc.get('CONFIRMATION_DEL.CANCEL'),
        confirmButton: this.translateSvc.get('CONFIRMATION_DEL.CONFIRM')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    };
    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.setHeaderButtonsDisabled(true);
        const taskIDs: string[] = this.selectedRows.map(r => r.id);
        this.formSvc.deletePatientForms(taskIDs).subscribe(() => {
          this.tasks = this.tasks.filter(t => !taskIDs.includes(t.id))
          this.stateViewerSvc.selectedTasks = this.tasks;
        });
        this.selectedRows = [];
      }
    }, (err) => { this.toastrSvc.error(err.message) });
  }

  commandSign(): void {
    this.fillOutForm();
  }

  commandEmail(): void {
    console.log('Email');
  }

  commandArchive() : void {
    const archivalObservables: Array<Observable<any>> = [];
    this.selectedRows.forEach(form => {
      archivalObservables.push(this.archiveSvc.archive(form.formid, this.patientData.id));
    });

    forkJoin(archivalObservables).subscribe({
      next: () => { 
        this.loadData();
        this.toastrSvc.success(this.translateSvc.instant('TOASTR_MESSAGE.ARCHIVE_SUCCESS')) 
      },
      error: (err) => { this.toastrSvc.error(err.message) }
    });
  }

  async commandPrint(): Promise<void> {
    const formtasks = this.selectedRows;
    const prefill = this.prefillFormatted;
    const payload = [];
    for (const ft of formtasks) {
      try {
        const exp = await this.experienceSvc.getExperience(ft.experienceid).toPromise();
        const submission = (ft.status.toLowerCase() as any === (FormStatus.NotStarted.toLowerCase()) || !ft.status)
          ? (await this.gxProcessing.getViewerDataAndConfig(exp, { }, prefill)).data
          : null;
        payload.push({
          pdftemplateid: ft.pdftemplateid,
          formid: ft.formid,
          status: ft.status || FormStatus.NotStarted,
          submissionData: submission
        });
      }
      catch (e) {
        this.toastrSvc.error(e.message);
        throw e;
      }
    }
    this.generateForm(payload);
  }

  generateForm(payload): void {
    this.formSvc.generateForm(payload).subscribe({
      next: async (dataURI) => {
        if (dataURI && dataURI.length) {
          const blob = await (await fetch(dataURI)).blob();
          const data = window.URL.createObjectURL(blob);
          const i = document.createElement('iframe');
          document.body.appendChild(i);
          i.style.display = 'none';
          i.src = data;
          i.onload = () => i.contentWindow.print();
          i.contentWindow.onbeforeunload = () => document.body.removeChild(i);
          i.contentWindow.onafterprint = () => document.body.removeChild(i);
        }
        else {
          this.toastrSvc.error(this.translateSvc.instant('PATIENT_FORMS.TABLE_MENU.PRINT_FORM_ERR'));
        }
      },
      error: (error) => {
        this.toastrSvc.error(error.message);
      }
    });
  }

  loadData() {
    this.formSvc.getFormTasksAssignTo(this.assignToId).pipe(
      tap(response => {
        this.tasks = response;
    })).subscribe();
  }

  get appointmentName(): { appointment: string } {
    return { appointment: this.enterpriseAppointmentName };
  }

}
