import { TextboxFieldDTO, TextboxInputType } from '@next/shared/common';
import { AbstractControl } from '@angular/forms';

export const DateRangeValidatorFn = (field: TextboxFieldDTO) => {
  return (control: AbstractControl) => {

    if (field.inputType === TextboxInputType.DATE && control.value) {

      /* Require yyyy-MM-dd format string */
      if (control.value.length !== 10) {
        return { 'Pattern' : true };
      }
      const delimiter = '-';
      const data = control.value.split(delimiter);
      if (+data[0] < 1900) {
        return { 'DateRange' : true };
      }

      try {
        const valueDate: Date = new Date(+data[0], +data[1] - 1, +data[2]);
        const todayDate: Date = new Date();
        valueDate.setHours(0,0,0,0);
        todayDate.setHours(0, 0, 0, 0);

        if (!field.pastDates && (valueDate < todayDate)) {
          return { 'DateRange' : true };
        }
        if (!field.futureDates && (valueDate > todayDate)) {
          return { 'DateRange' : true };
        }
      }
      catch {
        return { 'Pattern' : true };
      }

    }

    else if (field.inputType === TextboxInputType.SHORTDATE4 && control.value) {
      const valueString: string = control.value;
      if (valueString.length === 7) {

        let valueDate: Date;
        let todayDate: Date;

        try {
          valueDate = new Date(valueString.substr(0,2) + '/1/' + valueString.substr(3,4));
        }
        catch {
          return { 'Pattern' : true };
        }
        // eslint-disable-next-line prefer-const
        todayDate = new Date();

        if (!field.futureDates) {
          if (valueDate > todayDate) {
            return { 'DateRange': true };
          }
        }

        if (!field.pastDates) {
          if (valueDate < todayDate) {
            return { 'DateRange': true };
          }
        }
      }
    }

    return null;
  }
}
