export interface ExternalConnection {
  data: any,
  label: string,
  type: string,
  provider: string,
  id: string,
  updatedOn: string,
  createdOn: string
}

export enum ExternalConnectionTypes{
    PaymentGateway = "paymentgateway",
    UrlShortening="urlshortening",
    SMSGateway="smsgateway"
}

export enum ExternalConnectionProviders{
  Sphere ="sphere"
}

export enum ExternalConnectionUrlShorteningProviders
{
  Blink="blink"
}

export enum ExternalConnectionSMSGatewayProviders
{
  Plivo="plivo"
}

export interface SphereConnection {
   sphereCustomerId: string,
   sphereLicenseKey: string,
   sphereUrl: string,
   sphereResponseUrl: string
}
