import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Form } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextFormService } from '../form.service';

@Injectable({
  providedIn: 'root',
})
export class NextFormResolverService {
  constructor(private formSvc: NextFormService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Form[]> | Observable<never> {
    return this.formSvc.getForms();
  }
}
