import { Component, OnInit } from '@angular/core';
import { ConfigType } from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  notification = '';

  constructor(
    public stateSvc: StoreService,
    public modalRef: BsModalRef,
    private nextAdminService: NextAdminService,
  ) {  }

  ngOnInit() {
    this.checkForNotificationsOnLoad();
    this.checkForNotificationOnSave();
  }

  checkForNotificationsOnLoad() {
    this.nextAdminService.getConfigsByType(ConfigType.Notification).subscribe(result => {
      if (result.length > 0 && result[0]) {
        this.notification = result[0].data.inapptext;
      }
    });
  }

  checkForNotificationOnSave() {
    this.stateSvc.notificationSetting$.subscribe(() => {
      this.notification = this.stateSvc.notificationSetting.inapptext;
    });
  }

  close() {
    this.modalRef.hide();
  }

}
