import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  Image,
  TokenService,
  UploadFile
} from '@next/shared/common';
import { map } from 'rxjs/operators';

@Injectable()

export class NextImageService extends ApiService {
  validImageTypes: string[] = ['image/bmp', 'image/gif', 'image/jpg', 'image/jpeg', 'image/png'];

  constructor(@Inject(FormFastConfigService) config: FormFastConfig,
              tokenService: TokenService, private http: HttpClient) { super(config, tokenService); }

  isValidFileType(file: File): boolean {
    return this.validImageTypes.includes(file.type.toString());
  }

  getHttpRequest(file: UploadFile): any {
    const formData: FormData = new FormData();
    formData.append('name', file.file.name.split('.').slice(0, -1).join('.'));
    formData.append('description', file.file.name);
    formData.append('image-form', file.file, file.file.name);
    if (file.config) {
      formData.append('config', JSON.stringify(file.config));
    }

    return new HttpRequest('POST', `${this.config.apiUrl}image`, formData, this.getUploadHeaders());
  }

  uploadImage(file: UploadFile): any {
    const req = this.getHttpRequest(file);
    return this.protectedEndpoint<any>(this.http.request(req));
  }

  upload(files: UploadFile[]): void {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.progress = 0;
      if (!this.isValidFileType(file.file)) {
        file.status = -1;
      } else {
        const req = this.getHttpRequest(file);
        this.protectedEndpoint<any>(this.http.request(req)).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              files[i].progress = Math.ceil(+(event.loaded / event.total).toFixed(2) * 100);
            }
          },
          err => {
            files[i].status = 0;
            files[i].progress = 0;
          },
          () => {
            files[i].status = 1;
            files[i].progress = 0;
          }
        );
      }
    }
  }

  getImages(): Observable<Image[]> {
    return this.protectedEndpoint(this.http.get<any[]>(`${this.config.apiUrl}image`, this.getHeaders())).pipe(
      map(images => {
        return images.map(image => {
          const imageObject: Image = {
            id: image.id,
            name: image.name,
            url: image.imageUrl,
            secureUrl: image.url
          };

          if (image.config.source) {
            imageObject.imageSource = image.config.source;
          }

          return imageObject;
        })
      })
    );
  }
}
