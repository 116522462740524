import { Annotation, TaskDTO } from './form.model';
import { Attachment, FormSubmission } from './submit.model';

export interface GuidedExperienceDTO {
  id: string;
  vid: string;
  configversion: number;
  formId: string;
  name: string;
  description: string;
  logoUrl: string;
  logoHeight: number;
  logoAlignment: string;
  pages: PageDTO[];
  pdfPages: PageDTO[];
  annotations: GuidedExperienceAnnotation[];
  pdftemplateid: string;
  pdftemplatedescription?: string;
  pdftemplate: FileDTO;
  onLoad: string;
  onSave: string;
  onSubmit: string;
  embedAttachments: boolean,
  sendToSuccessPage: boolean,
  signAllStaff: boolean,
  metadata: NextGuidedExperienceMetaData;
  publishedon?: string;
  status?: string;
  tags?: string;
  version?: string;
  createdon?: string;
  updatedon?: string;
  designerversionid?: string
}

export interface FileDTO {
  id: string;
  name: string;
  description: string;
  createdon: string;
  config: any;
  url: string;
  isdeleted: boolean
}

export interface GuidedExperienceInstanceDTO {
  experience: GuidedExperienceDTO,
  submission: FormSubmission,
  attachments: Attachment[],
  prefill: unknown,
  task?: TaskDTO,
}

export interface NextGuidedExperienceMetaData {
  sources: NextGuidedExperienceSource[];
}

export interface NextGuidedExperienceSource {
  id: string;
  type: NextGuidedExperienceExternalType;
}

export interface NextGuidedExperienceSourcePDF extends NextGuidedExperienceSource {
  fields: Annotation[];
}

export enum NextGuidedExperienceExternalType {
  PDF = 'pdf'
}

export interface PageDTO {
  name: string;
  title: string;
  titleTag: string;
  condition: string;
  fields: FieldDTO[];
  fieldNames: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  overlayDropdown: {};
  pageType: string;
}

export interface FieldDTO {
  name: string;
  type: string;
  hiddenFromWebView: boolean;
  calculations: FieldCalculationDTO;
}

export interface FieldCalculationDTO {
  onChange: string;
}

export interface TextboxFieldDTO extends FieldDTO {
  maskSocial?: boolean;
  inputType: string;
  readonly: boolean;
  required: boolean;
  hidden: boolean;
  text: string;
  maxChar: number;
  minValue: number;
  maxValue: number;
  placeholder: string;
  FHIRPath: string;
  multiLine: boolean;
  dateFormat?: string;
  futureDates?: boolean;
  pastDates?: boolean;
  regularExpression: string;
}

export interface CheckboxFieldDTO extends FieldDTO {
  text: string;
  required: boolean;
  readonly: boolean;
  hidden: boolean;
  onValue: string;
  trueFields: FieldDTO[];
  falseFields: FieldDTO[];
  FHIRPath: string;
}

export interface DropdownFieldDTO extends FieldDTO {
  text: string;
  options: DropdownOptionDTO[];
  required: boolean;
  readonly: boolean;
  hidden: boolean;
  switch: SwitchDTO[];
  FHIRPath: string;
  dropDownFilter: boolean;
  dropDownMultiSelect: boolean;
  dropDownDelimiter: string;
  dropDownAllowText: boolean;
}

export interface DropdownOptionDTO {
  text: string;
  value: string;
}

export interface RadiogroupFieldDTO extends FieldDTO {
  text: string;
  options: RadiogroupOptionDTO[];
  readonly: boolean;
  required: boolean;
  hidden: boolean;
  switch: SwitchDTO[];
  FHIRPath: string;
}

export interface RadiogroupOptionDTO {
  label: string;
  value: string;
}

export interface SwitchDTO {
  when: string;
  fields: FieldDTO[];
}

export interface WrittenSigDTO extends FieldDTO {
  title: string;
  buttonText: string;
  statementText: string;
  required: boolean;
  disableTypedSignature: boolean;
  signatureFor: string;
  signatureType: string;
  signatureTimeStampEnabled: boolean;
  signatureTimeStampLocation: string;
  signatureTimeStampFieldName: string;
  signatureTimeStampFormat: string;
}

export interface GuidedExperienceAnnotation {
  name: string;
  width: number;
  height: number;
  annotationData: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FieldDivider extends FieldDTO { }

export interface FieldSpacer extends FieldDTO {
  size: number;
}

export interface Link extends FieldDTO {
  text: string;
  value: string;
}

export interface Header extends FieldDTO {
  text: string;
  headerTag: string;
  underline: boolean;
  italics: boolean;
  bold: boolean;
  alignment: string;
}

export interface TextLabel extends FieldDTO {
  text: string;
  size: number;
  underline: boolean;
  italics: boolean;
  bold: boolean;
  alignment: string;
}

export interface Popup extends FieldDTO {
  text: string;
  class: any;
  popupField: PopupField;
}

export interface PopupField extends FieldDTO {
  type: string;
  name: string;
  title: string;
  validateOneField: boolean;
  fields: FieldDTO[];
}

export interface Section extends FieldDTO {
  title: string;
  fields: any;
}

export interface PhotoDTO extends FieldDTO {
  title: string;
  preButtonText: string;
  postButtonText: string;
  preHeaderText: string;
  postHeaderText: string;
  required: boolean;
}

export interface AnnotationDTO extends FieldDTO {
  attachmentName: string;
  imageUrl: string;
  required: boolean;
  text: string;
  imageSource?: string;
  pdfBacked: boolean;
}

export interface RichTextDTO extends FieldDTO {
  text: string;
  format: string;
}

export interface ColumnLayout extends FieldDTO {
  columns: ColumnLayoutColumn[];
}

export interface ColumnLayoutColumn {
  width: number;
  fields: FieldDTO[];
}

export enum InputType {
  AlphaNumeric = 'alphanumeric',
  Alpha = 'alpha',
  Numeric = 'numeric',
  Date = 'date',
  Currency = 'currency',
  Social = 'ssn',
  Phone = 'phone',
  Email = 'email',
  PostalCode = 'postalcode',
  CreditCardDigits = 'ccdigits',
  RegularExpression = 'regularexpression',
  ShortDate2 = 'shortdate2',
  ShortDate4 = 'shortdate4'
}

export enum DateFormat {
  MMDDYYYY = 'MM/dd/yyyy',
  DDMMYYYY = 'dd/MM/yyyy',
  YYYYMMDD = 'yyyy-MM-dd',
}

export enum WindowMessageEventName {
  ExperienceSubmit = 'Submit',
  ExperienceSave = 'Save',
  ExperienceCanceled = 'Cancel',
  ExperienceEdited = 'ExperienceEdited',
  FieldChanged = 'FieldChanged',
  RefreshPreviewWindow = 'refresh',
  RenderedPDF = 'rendered',
  Signature = 'signature',
  SubmitError = 'SubmitError',
  OverlayEdited = 'OverlayEdited',
  ViewerInitialized = 'viewerInitialized',
  EmbeddedPrefill = 'embeddedPrefill'
}

export enum IhopPrefill {
  IHOP_Patient = 'IHOP_Patient',
  IHOP_Appointment = 'IHOP_Appointment',
  IHOP_Metadata = 'metadata'
}

export const PrefillIHOPPatient = `${IhopPrefill.IHOP_Patient}.`;
export const PrefillIHOPAppointment = `${IhopPrefill.IHOP_Appointment}.`;
export const PrefillIHOPMetadata = `${IhopPrefill.IHOP_Metadata}.`;
