import { Directive, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[nextDropZone]'
})
export class DropZoneDirective {

  @Input() nextDropZone: boolean;
  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesHover = new EventEmitter();

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    if (this.nextDropZone) {
      const transfer = $event.dataTransfer;
      this.filesDropped.emit(transfer.files);
      this.filesHover.emit(false);
    }
  }

  @HostListener('dragover', ['$event'])
  onHover($event) {
    $event.preventDefault();
    if (this.nextDropZone) {
      this.filesHover.emit(true);
    }
  }

  @HostListener('dragleave', ['$event'])
  onHoverLeave($event) {
    $event.preventDefault();
    if (this.nextDropZone) {
      this.filesHover.emit(false);
    }
  }
}
