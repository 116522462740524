import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '../state/store.service';
import { CollectionDTO, CollectionJunctionDTO, CollectionType, GuidedExperienceDTO, Tag } from '@next/shared/common';
import { TagSearchComponent } from '../tag-components/tag-search/tag-search.component';
import { NextExperienceService } from '@next/shared/next-services';
import { map } from 'rxjs/operators';

export interface TreeNode {
  name: string;
  label: string;
  id: string;
  type: string;
  versionHistory: any[];
  parent: string;
  children?: TreeNode[]
}

@Component({
  selector: 'next-add-forms-modal',
  templateUrl: './add-forms-modal.component.html',
  styleUrls: ['./add-forms-modal.component.scss']
})
/**
 * The behavior of this component will change based on the provided parameters
 *
 * - treeMode <boolean> - Present a tree navigation or a flat list
 * - showPackets <boolean> - Present Packets in the library
 */
export class AddFormsModalComponent implements OnInit {
  @ViewChild('tagSearch') tagSearch: TagSearchComponent;

  @Output() modalClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() modalSubmit: EventEmitter<any> = new EventEmitter<any[]>();

  active: any[] = [];          // Selected Entries
  treeExperiences = [];        // All experiences, in tree format
  listExperiences = [];        // All experiences, in list format
  @Input() treeMode = false;   // Render tree mode navigation if true
  @Input() showPackets = true; // Present Packets in the tree view

  previous: TreeNode[][] = [];      // Array of previous node trees
  previousLabel: string[] = [''];   // Array of folder names

  checkboxSelect = false;  // Multi-select mode
  filter = '';              // Search-box Value
  tags: Tag[] = [];                 // Tags in use
  root: any[] = [];                 // Backup of original source (list or tree)

  constructor (
    private expSvc: NextExperienceService,
    private stateSvc: StoreService,
    private translateSvc: TranslateService
  ) { }

  /**
   * Constructs the node tree traversable
   * in menu from flattened experiences &
   * categories collection
   *
   * @param nodes {Object[]} - An array of flattened experiencesDTOs
   *                          and categories to assemble tree with.
   * @private
   */
  private static buildTree(nodes: any[]): TreeNode[] {

    /**
     * recursive func utility
     * @param itemArray - array of objects to transform
     * @param { idKey, pKey, cKey } - node properties:
     *                                id, parent, children
     */
    const nestUtility = ({ idKey='id', pKey='parent', cKey='children'}, itemArray = []) => {
      const tree = [];
      const childrenOf = { };
      for (const item of itemArray) {
        const { [idKey]: id, [pKey]: parentId = 0 } = item;
        childrenOf[id] = childrenOf[id] || [];
        item[cKey] = childrenOf[id];
        if (item.type === CollectionType.Packet || item.collectionType === CollectionType.Packet) {
          item.children = item.experiences;
        }
        if (parentId) {
          if (Array.isArray(parentId)) {
            for (const parent of parentId) {
              if (!childrenOf[parent]) childrenOf[parent] = []; // replace undefined with empty array
              if (parent === '') tree.push(item);               // if parent is empty string add item to root
              else childrenOf[parent].push(item);               // push item to parent's children array
            }
          }
          else {
            if (!childrenOf[parentId]) childrenOf[parentId] = []; // replace undefined with empty array
            if (!parent) tree.push(item);                         // if parent is empty string add item to root
            else childrenOf[parentId].push(item);                 // push item to parent's children array
          }
        }
        else {
          tree.push(item);  // if no parent add item to root
        }
      }
      return tree;
    }
    /** end func utility */
    return nestUtility({ idKey: 'id', pKey: 'parent', cKey: 'children' }, nodes);
  }

  mapExperiences(experiences: GuidedExperienceDTO[], junctions: CollectionJunctionDTO[] = [], collections: CollectionDTO[] = []) {
    return experiences.map((exp: GuidedExperienceDTO) => {
      const iCollections: any[] = this.getExperienceCollections(exp, junctions, collections);
      const parentProperty = iCollections.map(col => col.id);
      if (!iCollections.some(el => el.type === CollectionType.Folder)) {
        parentProperty.push('');
      }
      return {
        id: exp.id,
        vid: exp.vid,
        name: exp.name,
        status: exp.status,
        version: exp.version,
        parent: parentProperty,
        category: iCollections.filter(col => col.type === CollectionType.Folder).map(folder => folder.name).join('/') || '',
        tags: exp.tags,
        type: this.translateSvc.instant('MANAGE_FORMS.FORM_LABEL'),       // hard-coded
        language: this.translateSvc.instant('MANAGE_FORMS.LANGUAGE_EN'),  // hard-coded
        pdftemplateid: exp.pdftemplateid,
      } as any;
    });
  }

  async ngOnInit() {
    const dataset = await this.expSvc.getCollectionBundle().pipe(
      map((response: any) => {
        const [
          collectionDTOs,
          junctionDTOs,
          publishedDTOs
        ] = response;
        const experiences: any[] = this.mapExperiences(publishedDTOs, junctionDTOs, collectionDTOs);
        const collections: any[] = this.mapCollections(publishedDTOs, junctionDTOs, collectionDTOs);
        const folders: any[] = collections.filter(coll => coll.type === CollectionType.Folder);
        const packets: any[] = collections.filter(coll => coll.type === CollectionType.Packet);
        return [ ...folders, ...packets, ...experiences ].sort((a, b) => (a.name > b.name) ? 1 : -1);
      })).toPromise();

    if (this.treeMode) {
      this.treeExperiences = this.root = (
        this.showPackets
          ? AddFormsModalComponent.buildTree(dataset)
          : AddFormsModalComponent.buildTree(dataset.filter(el => el.type !== CollectionType.Packet))
      );
      this.previous = [this.root];
      this.previousLabel = [''];
    }
    else {
      this.listExperiences = this.root = (
        this.showPackets
          ? dataset.filter(element => element.type !== CollectionType.Folder)
          : dataset.filter(element => ![CollectionType.Folder, CollectionType.Packet].includes(element.type))
      );
    }
  }

  mapCollections(experiences: GuidedExperienceDTO[], junctions: CollectionJunctionDTO[], collections: CollectionDTO[]) {
    return collections.map((collection: CollectionDTO) => {
      switch (collection.type) {
        case CollectionType.Folder:
          return {
            id: collection.id,
            name: collection.name,
            parent: collection.parent,
            type: collection.type,
            expanded: collection.expanded,
          } as any;
        case CollectionType.Packet:
          // eslint-disable-next-line no-case-declarations
          const iCollections = this.getCollectionCollections(collection, junctions, collections);
          // eslint-disable-next-line no-case-declarations
          const iExperiences = this.getPacketExperiences(collection, junctions, experiences);
          return {
            id: collection.id,
            name: collection.name,
            type: collection.type,
            parent: iCollections.length ? iCollections.map(col => col.id) : [''],
            experiences: iExperiences,
            category: iCollections.filter(col => col.type === CollectionType.Folder).map(folder => folder.name).join('/') || '',
            tags: collection.tags,
            status: 'active', // hard-coded, packets can only contain published forms
            language: this.translateSvc.instant('MANAGE_FORMS.LANGUAGE_EN'),  // hard-coded
          } as any;
      }
    });
  }

  getPacketExperiences(packet, junctions, experiences) {
    const iJunctions = junctions.filter(j => j.collection === packet.id);
    const iExperiences = iJunctions.map(j => experiences.find(ex => ex.id === j.element));
    return iExperiences.length
      ? iExperiences.map(exp => Object.assign(exp, { type : CollectionType.Form }))
      : [];
  }

  getExperienceCollections(experience: GuidedExperienceDTO, junctions: CollectionJunctionDTO[] = [], collections: CollectionDTO[] = []) {
    const iJunctions = junctions.filter(j => j.element === experience.id);
    const iCollections = iJunctions.map(j => collections.find(collection => collection.id === j.collection));
    const iFolders = iCollections.filter(collection => collection.type === CollectionType.Folder);
    return iFolders.length
      ? iFolders
      : [];
  }

  getCollectionCollections(collection: CollectionDTO, junctions: CollectionJunctionDTO[], collections: CollectionDTO[]) {
    const iCollections = junctions.filter(j => j.element === collection.id).map(iJunctions => collections.find(col => col.id === iJunctions.collection));
    return iCollections.length
      ? iCollections
      : [];
  }

  /**
   * Pop previous array, setting the result
   * as the current node tree
   */
  openPrevious(): void {
    if (this.previous.length > 1) {
      this.treeExperiences = this.previous.pop();
      this.previousLabel.pop();
    }
  }

  /**
   * Prune the previous arrays
   * and revert to the root tree
   */
  openRoot(): void {
    this.previous = [this.root];
    this.previousLabel = [''];
    this.treeExperiences = this.root;
  }

  /**
   * Opens a category on click.
   * Push current tree structure to previous[] array
   * then set current tree to the former's children nodes
   *
   * @param element {any[]} - The current tree node array
   */
  openFolder(element): void {
    this.previous.push(this.treeExperiences);
    this.previousLabel.push(element.name);
    this.treeExperiences = element.children;
  }

  selectExperienceCheckbox(node, click: boolean = false): void {
    if (this.active.some(el => el.id === node.id)) {
      const iNode: any = this.active.find(el => el.id === node.id);
      this.active.splice(this.active.indexOf(iNode), 1);
      if (!this.active.length) {
        this.checkboxSelect = false;
      }
    }
    else {
      this.active.push(node);
      this.checkboxSelect = true;
    }
    if (click) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  selectExperienceButton(node, click: boolean = false): void {
    this.checkboxSelect = false;
    if (this.active.length === 1 && this.active.includes(node)) {
      this.active = [];
    } else {
      this.active = [node];
    }
    if (click) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  isSelected(node): boolean {
    return this.active.map(a => a.id).includes(node.id);
  }

  /**
   * From a set of experience ids, filter
   * the visible published experiences by experience id
   *
   * If no tags selected, show root
   *
   * If tags and experience ids, filter tree by experience ids
   *
   * If tags and no experience ids, show empty tree
   *
   * @param event {Object} - Object containing tags and experience ids
   * @property event.activeTags {Object} - The tags that are active after tag search
   * @property event.experienceIds {string[]} - Ids of experiences to show
   */
  onTagFilter(event: { activeTags: Tag[], experienceIds: Set<string> }): void {
    this.active = [];
    this.stateSvc.clearExperiencesStore();
    this.tags = event.activeTags;

    if (!this.tags.length) {
      this.treeExperiences = this.treeMode ? this.root : [];
      this.listExperiences = this.treeMode ? [] : this.root;
    }
    else {
      this.treeExperiences = this.treeMode ? this.root.filter(e => event.experienceIds.has(e.id)) : [];
      this.listExperiences = this.treeMode ? [] : this.root.filter(e => event.experienceIds.has(e.id));
    }
  }

  /**
   * Remove an active tag filter and update
   * the menu list
   *
   * After removing a tag, if no tag filters remain
   * revert the tree list to root
   * @param e
   */
  async removeActiveTag(e: Tag): Promise<void> {
    this.tags.splice(this.tags.indexOf(e), 1);
    if (!this.tags.length) {
      // Revert to root
      if (this.treeMode) {
        this.treeExperiences = this.root;
      }
      else {
        this.listExperiences = this.root;
      }
    }
    else {
      await this.tagSearch.submit();
    }
  }

  clearFilter(): void {
    this.filter = '';
  }

  onCancel(): void {
    this.modalClose.emit();
  }

  onSubmit(): void {
    this.modalSubmit.emit(this.active);
  }

  get formLabel(): { name: string } {
    return { name: this.stateSvc.enterpriseSettings.forms || this.translateSvc.instant('MANAGE_FORMS.FORM_LABEL')}
  }

  get enterpriseName(): { name: string } {
    return { name: this.stateSvc.enterpriseSettings.forms || this.translateSvc.instant('MANAGE_FORMS.MANAGE_FORMS_TITLE_DEFAULT')}
  }
}
