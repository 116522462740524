<div class="form-group rounded" [ngClass]="{ 'd-none' : field.hidden }" [formGroup]="valueFormGroup">
  <label for="{{ field.name }}" class="m-0 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required bold">&nbsp;*</span>
  </label>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <fa-icon [icon]="['fas', 'credit-card']" class="margin-center"></fa-icon>
      </span>
    </div>
    <input
      class="form-control"
      formControlName="Text"
      autocomplete="off"
      id="{{ 'GX-Viewer-Field-TextBox' + field.name }}"
      name="{{ 'GX-Viewer-Field-TextBox' + field.name }}"
      type="text"
      inputmode="decimal"
      [placeholder]="field.placeholder || 'xxxx'"
      [readonly]="field.readonly"
      [required]="field.required"
      [maxlength]="'4'"
      [minlength]="'4'"
      [pattern]="CCPattern"
      [mask]="'XXXX'"
      [dropSpecialCharacters]="true"
      [allowNegativeNumbers]="false"
      [hiddenInput]="hiddenText"
      [ngClass]="{ 'invalid' : valueFormGroup.get('Text')?.invalid && !newField && needsValidation }">
    <div id="{{ field.name + '_showCC' }}" class="input-group-append" tabindex="-1" (focusout)="hiddenText = true">
      <button
        id="{{ field.name + '_showCC_btn' }}"
        type="button"
        tabindex="-1"
        class="btn btn-outline-primary input-group-append-width overflow-hidden"
        title="{{ 'TEXTBOX.TOOLTIP_SHOWCCDIGITS' | translate }}"
        (click)="toggleHiddenText()">
        {{ hiddenText ? DisplayCC.SHOW : DisplayCC.HIDE }}
      </button>
    </div>
  </div>
  <!------------------------
  Validation Error Message
  ------------------------->
  <ng-container *ngIf="valueFormGroup?.get('Text')">
    <span *ngIf="valueFormGroup?.get('Text').invalid && needsValidation && !newField" class="float-right text-sizing color-danger">
      {{ getFormattingError(valueFormGroup?.get('Text')) | translate:getFormattingErrorParam(valueFormGroup?.get('Text')) }}
    </span>
  </ng-container>
</div>
