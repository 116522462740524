<ng-container *ngIf="!field.hiddenFromWebView">
  <next-textbox
    *ngIf="field.type === ViewerFieldType.TEXTBOX"
    [form]="form"
    [field]="field"
    [initialState]="initialState"
    [needsValidation]="needsValidation"
    (statusChange)="statusChangeReceived($event)"
    (valueChanged)="emitValueChanged($event)">
  </next-textbox>
  <next-checkbox
    *ngIf="field.type === ViewerFieldType.CHECKBOX"
    [form]="form"
    [field]="field"
    [attachments]="attachments"
    [initialState]="initialState"
    [needsValidation]="needsValidation"
    (statusChange)="statusChangeReceived($event)"
    (valueChanged)="emitValueChanged($event)">
  </next-checkbox>
  <next-dropdown *ngIf="field.type === ViewerFieldType.DROPDOWN" [form]="form" [field]="field" [attachments]="attachments" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)"></next-dropdown>
  <next-radiogroup *ngIf="field.type === ViewerFieldType.RADIOGROUP" [form]="form" [field]="field" [attachments]="attachments" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)"></next-radiogroup>
  <next-popup *ngIf="field.type === ViewerFieldType.POPUP" [form]="form" [field]="field" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)"></next-popup>
  <next-annotation *ngIf="field.type === ViewerFieldType.ANNOTATION" [form]="form" [field]="field" [initialState]="initialState" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)"></next-annotation>
  <next-written-sig *ngIf="field.type === ViewerFieldType.WRITTENSIG" [form]="form" [field]="field" [initialState]="initialState" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)"></next-written-sig>
  <next-section *ngIf="field.type === ViewerFieldType.SECTION" [form]="form" [field]="field" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)"></next-section>
  <next-field-divider *ngIf="field.type === ViewerFieldType.FIELDDIVIDER" [field]="field"></next-field-divider>
  <next-field-spacer *ngIf="field.type === ViewerFieldType.FIELDSPACER" [field]="field"></next-field-spacer>
  <next-link *ngIf="field.type === ViewerFieldType.LINK" [field]="field"></next-link>
  <next-header *ngIf="field.type === ViewerFieldType.HEADER" [field]="field"></next-header>
  <next-text-label *ngIf="field.type === ViewerFieldType.TEXTLABEL" [field]="field"></next-text-label>
  <next-photo
    *ngIf="field.type === ViewerFieldType.PHOTO"
    [form]="form"
    [formId]='formId'
    [field]="field"
    (valueChanged)="emitValueChanged($event)">
  </next-photo>
  <next-rich-text
    *ngIf="field.type === ViewerFieldType.RICHTEXT"
    [field]="field">
  </next-rich-text>
  <next-payment-gateway
    *ngIf="field.type === ViewerFieldType.PAYMENTGATEWAY"
    [form]="form"
    [field]="field">
  </next-payment-gateway>
  <next-column-layout
    *ngIf="field.type === ViewerFieldType.COLUMNLAYOUT"
    [form]="form"
    [field]="field"
    [initialState]="initialState"
    [needsValidation]="needsValidation"
    (statusChange)="statusChangeReceived($event)"
    (valueChanged)="emitValueChanged($event)">
  </next-column-layout>

</ng-container>
