import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {
  AuthInterceptor,
  NextClinicalService,
  NextExperienceService, NextImageService,
  NextSubmissionService,
  OverlayService,
  CacheInterceptor,
  HttpProgressInterceptor
} from '@next/shared/next-services';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { ExperienceService, ImageService } from '@next/shared/web-services';
import { ErrorComponent } from './error/error.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FormSubmissionService } from '@next/next-clinical/feature-shared';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.baseHref + 'assets/i18n/');
}

const childRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@next/next-clinical/feature-shell').then(m => m.NextClinicalFeatureShellModule)
  },
  {
    path: 'error',
    component: ErrorComponent
  }
];

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(childRoutes, { relativeLinkResolution: 'legacy',  onSameUrlNavigation: 'reload' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot()
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true },
    { provide: ExperienceService, useClass: NextExperienceService },
    { provide: ImageService, useClass: NextImageService },
    NextExperienceService,
    NextImageService,
    NextSubmissionService,

    NextClinicalService,
    OverlayService,
    FormSubmissionService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
