import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ColumnLayout } from '@next/shared/common';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.css']
})
export class ColumnLayoutComponent extends FieldBaseComponent {

  @Input() form: FormGroup;
  @Input() field: ColumnLayout;
  @Input() initialState: any;
  @Input() needsValidation: boolean;

  constructor() { super(); }

}
