import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskDTO } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextClinicalService } from '../clinical.service';

@Injectable({
  providedIn: 'root',
})
export class NextPatientFormResolverService {
  constructor(private clinicalSvc: NextClinicalService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaskDTO[]> | Observable<never> {
    const patientId = route.paramMap.get('patientId');
    return this.clinicalSvc.getTasksForUser(patientId);
  }
}
