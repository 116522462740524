import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ViewerComponent } from './viewer/viewer.component';
import { ViewerFooterComponent } from './viewer-footer/viewer-footer.component';
import { ViewerMainComponent } from './viewer-main/viewer-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewerPageComponent } from './viewer-page/viewer-page.component';
import { TextboxComponent } from './fields/textbox/textbox.component';
import { CheckboxComponent } from './fields/checkbox/checkbox.component';
import { DropdownComponent } from './fields/dropdown/dropdown.component';
import { RadiogroupComponent } from './fields/radiogroup/radiogroup.component';
import { FieldDividerComponent } from './fields/field-divider/field-divider.component';
import { FieldSpacerComponent } from './fields/field-spacer/field-spacer.component';
import { LinkComponent } from './fields/link/link.component';
import { HeaderComponent } from './fields/header/header.component';
import { TextLabelComponent } from './fields/text-label/text-label.component';
import { WrittenSigComponent } from './fields/written-sig/written-sig.component';
import { SharedUiModule } from '@next/shared/ui';
import { PopupComponent } from './fields/popup/popup.component';
import { ViewerElementComponent } from './viewer-element/viewer-element.component';
import { SectionComponent } from './fields/section/section.component';
import { ViewerPdfComponent } from './viewer-pdf/viewer-pdf.component';
import { ViewerWarningRibbonComponent } from './viewer-warning-ribbon/viewer-warning-ribbon.component';
import { SuccessComponent } from './success/success.component';
import { PhotoComponent } from './fields/photo/photo.component';
import { TranslateModule } from '@ngx-translate/core';
import { AnnotationComponent } from './fields/annotation/annotation.component';
import { FieldBaseComponent } from './fields/field-base/field-base.component';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RichTextComponent } from './fields/rich-text/rich-text.component';
import { QuillModule } from 'ngx-quill';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PaymentGatewayComponent } from './fields/payment-gateway/payment-gateway-component';
import { ColumnLayoutComponent } from './fields/column-layout/column-layout.component';
import { UserResolverService } from '@next/shared/next-services';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SsnComponent } from './fields/textbox/components/ssn/ssn.component';
import { PhoneComponent } from './fields/textbox/components/phone/phone.component';
import { CreditCardComponent } from './fields/textbox/components/creditcard/credit-card.component';
import { MainComponent } from './main/main.component';
import { DateComponent } from './fields/textbox/components/date/date.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MainComponent,
  },
  {
    path: 'success',
    component: SuccessComponent
  },
  {
    path: 'pdf',
    component: MainComponent,
    resolve: {
      user: UserResolverService
    }
  }
];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    ModalModule.forRoot(),
    SharedUiModule,
    TranslateModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule,
    BsDropdownModule.forRoot(),
    FontAwesomeModule,
    QuillModule.forRoot(),
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    ProgressbarModule
  ],
  providers: [
    CurrencyPipe
  ],
  declarations: [
    ViewerComponent,
    ViewerFooterComponent,
    ViewerMainComponent,
    ViewerPageComponent,
    TextboxComponent,
    CheckboxComponent,
    DropdownComponent,
    RadiogroupComponent,
    FieldDividerComponent,
    FieldSpacerComponent,
    LinkComponent,
    HeaderComponent,
    TextLabelComponent,
    WrittenSigComponent,
    PopupComponent,
    ViewerElementComponent,
    SectionComponent,
	  ViewerPdfComponent,
    ViewerWarningRibbonComponent,
    SuccessComponent,
    PhotoComponent,
    AnnotationComponent,
    FieldBaseComponent,
    RichTextComponent,
    PaymentGatewayComponent,
    ColumnLayoutComponent,
    SsnComponent,
    PhoneComponent,
    CreditCardComponent,
    MainComponent,
    DateComponent
  ],
  exports: [
    ViewerComponent,
    ViewerPdfComponent
  ]
})
export class GxViewerFeatureShellModule {}
