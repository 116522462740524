import { Component, Input } from '@angular/core';

@Component({
  selector: 'next-no-selection',
  templateUrl: './no-selection.component.html',
  styleUrls: ['./no-selection.component.scss']
})
export class NoSelectionComponent { 
  @Input() finishedForms: boolean
}
