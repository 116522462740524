<div class='container-fluid h-100'>
  <div class='row mt-5'></div>
  <div class='row justify-content-start'>
    <label for='assign-to-select' class='col-12 p-0 m-0'>
      <h5 class='mb-1'>{{ 'SIGNATURE_PROMPT.ASSIGN_TO.LABEL' | translate }}</h5>
    </label>
    <ng-select
      id='assign-to-select'
      aria-label='assign-to-select'
      class='form-control mw-500-px col-12'
      aria-autocomplete='none'
      [searchable]="true"
      [(ngModel)]="assignTarget"
      [ngModelOptions]='{ standalone : true }'>
      <ng-option *ngFor="let user of usersList" [value]="user.id">
        {{ user.fullName }}
      </ng-option>
    </ng-select>
  </div>
  <div class='row justify-content-between align-content-end h-150-px'>
    <div class='col-6 col-lg-4 col-xl-3 p-0'>
      <button id='btn-assign-to-cancel' type='reset' class='btn btn-secondary w-100 mr-1' (click)='closeAssignTo.emit()'>
        {{ 'SIGNATURE_PROMPT.ASSIGN_TO.CANCEL' | translate }}
      </button>
    </div>
    <div class='col-6 col-lg-4 col-xl-3 p-0'>
      <button id='btn-assign-to-confirm' type='submit' class='btn btn-primary w-100 ml-1' (click)='assignToSubmit()' [disabled]='!assignTarget'>
        {{ 'SIGNATURE_PROMPT.ASSIGN_TO.ASSIGN' | translate }}
      </button>
    </div>
  </div>
</div>

