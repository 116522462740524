import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IDService, SphereConnection } from '@next/shared/common';
import { NextExternalConnectionService } from '@next/shared/next-services';

@Component({
    selector: 'next-payment-gateway',
    templateUrl: './payment-gateway-component.html'
})

export class PaymentGatewayComponent implements OnInit {
    static readonly aggregatorID: string = '1P6BYSZ';
    @Input() field :any;
    @Input() form : any;
    url: SafeResourceUrl;
    
    constructor(private sanitizer: DomSanitizer, private connectionService:  NextExternalConnectionService, private idSvc: IDService) { }
    ngOnInit() {

        const nameOnCard = this.field.nameToFieldMapping==='' ? '': this.form.controls[this.field.nameToFieldMapping]?.value?.Value?.Text ?? '';
        const postalCodeOnCard = this.field.postalCodeToFieldMapping==='' ? '': this.form.controls[this.field.postalCodeToFieldMapping]?.value?.Value?.Text ?? '';
        const emailOnCard = this.field.emailToFieldMapping==='' ? '': this.form.controls[this.field.emailToFieldMapping]?.value?.Value?.Text ?? '';
        const accountNumber = this.field.accountNumberToFieldMapping==='' ? '': this.form.controls[this.field.accountNumberToFieldMapping]?.value?.Value?.Text ?? '';
        let paymentOnCard = this.field.paymentToFieldMapping==='' ? '': this.form.controls[this.field.paymentToFieldMapping]?.value?.Value?.Text ?? '';

        if (paymentOnCard === '')
        {
            paymentOnCard = this.field.paymentAmount;
        }

        this.connectionService.getExternalConnectionById(this.field.externalConnection).subscribe(conn => {
            const sphereData = <SphereConnection> conn.data;
            const ticket = this.idSvc.generate().replace(/-/g, '').slice(0,25);           
            
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${sphereData.sphereUrl}?`+
                `trxcustid=${sphereData.sphereCustomerId}&trxcustid_licensekey=${sphereData.sphereLicenseKey}`+
                `&ticketno=${ticket}&amount=${paymentOnCard}&checking_ach=${this.field.allowAchPayment ? "y" : "n" }&show_email=y`+
                `&storeonly=${this.field.storePaymentOnly ? "y" : "n"}&hide_ticket=y&hide_bottomlogo=y&name_on_card=${nameOnCard}&postal=y`+
                `&amountequalorless=${this.field.allowPaymentEdit ? "y" : "n" }&amountequalorgreater=${this.field.allowPaymentEdit ? "y" : "n" }&baddress_postal=${postalCodeOnCard}`+
                `&email_address=${emailOnCard}&trxcustomfield[1]=${accountNumber}`+
                `&aggregators=${PaymentGatewayComponent.aggregatorID}`                
            );
    
            // When we support the Sphere Webhook, add this back in:
            //&response_url=${connection.sphereResponseUrl}
        });
    }
}
