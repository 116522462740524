import { Component, Input } from '@angular/core';
import { Link } from '@next/shared/common';

@Component({
  selector: 'next-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent {
  @Input() field: Link;

}
