import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownFieldDTO, FieldDTO } from '@next/shared/common';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'next-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  animations: [
    trigger('easeIn', [
      state('in', style({ opacity: 1 })),
      transition('void => in', [
        style({
          opacity: 0,
          transform: 'translateY(1rem)'
        }),
        animate('300ms ease-in-out')
      ])
    ])
  ]
})

export class DropdownComponent extends FieldBaseComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() field: DropdownFieldDTO;
  @Input() initialState: any;
  @Input() attachments: any;
  @Input() needsValidation: boolean;
  items = []
  searchTerm: string;
  label: string;

  @ViewChild('dd') dd: NgSelectComponent;

  constructor(private translateSvc: TranslateService) {
    super();
  }

  ngOnInit(): void {
    const dropdownValue = this.form.get(this.field.name)?.value.Value.Text;
    if (dropdownValue) {
      this.form.get(`${this.field.name}.Value`).patchValue({
        ['Text'] : dropdownValue
      },{
        emitEvent: true,
        selfOnly: false
      });

      if (this.field.dropDownMultiSelect && this.field.dropDownDelimiter !== '') {
        if (Array.isArray(dropdownValue)) {
          this.label = dropdownValue.join(this.field.dropDownDelimiter);
          this.items = dropdownValue;
        }
        else {
          this.items = dropdownValue.split(this.field.dropDownDelimiter);
          this.label = dropdownValue
        }
      }
    }
    if (this.field.options[0]?.value !== '' && !this.field.dropDownMultiSelect) {
      this.translateSvc.get('DROPDOWNLIST.DEFAULT_OPTION').subscribe(res => {
        this.field.options.unshift({text: res, value: ''});
      });
    }
    super.processOnInit(this.field);
  }

  ngAfterViewInit(): void {
    super.processAfterViewInit(this.field);
    this.valueChangeSub();

  }

  getSwitchFields(key: string): FieldDTO[] {
    const switchFields = this.field.switch.find(s => s.when === key);
    if (switchFields) {
      return switchFields.fields;
    }
  }

  onChange(e) {
    if (this.field.switch.length) {
      super.resetDynamicChildren(this.field);
    }

    if(this.field.dropDownMultiSelect) {
      if(e.target?.checked) {
        this.items.push(e.target?.value);
      } else{
        this.items = this.items.filter(x=>x !== e.target?.value)
      }

      this.form.get(`${this.field.name}.Value`).patchValue(
         {
           ['Text']: this.items
         },
         { emitEvent: true, selfOnly: false }
       );
      this.label = this.items.join(this.field.dropDownDelimiter);
    }
  }

  getCheckedStatus(value){
    return this.items.includes(value) ? 'checked' : null;
  }

  onSearch(data) {
    this.searchTerm = data.term;
  }

  onBlur() {
    if (this.searchTerm && this.field.dropDownAllowText) {
      if(!this.field.options.find(x=>x.value === this.searchTerm)) {
        this.field.options = [...this.field.options, { text: this.searchTerm, value: this.searchTerm }];
      }
      this.form.get(`${this.field.name}.Value`).patchValue(
        {
          ['Text']: this.searchTerm,
        },
        { emitEvent: true, selfOnly: false }
      );
    }
  }

  onDone(){
    this.dd.close();
  }
}
