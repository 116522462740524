<div class="container-fluid modal-content" style='max-height: 75vh'>
  <div class="row d-flex align-items-center modal-header">
    <button id="add-form-modal-reset" type="reset" class="float-left w-20 btn-cancel" (click)="onCancel()">
      <span class="p-3">{{ 'PATIENT_FORMS.ADD_FORMS.CANCEL' | translate }}</span>
    </button>
    <div class="float-left w-20">
      {{ 'PATIENT_FORMS.ADD_FORMS.ADD_FORM_TITLE' | translate:enterpriseName }}
    </div>
    <button id="add-form-modal-submit" type="submit" class="float-right w-20 btn-assign" (click)="onSubmit()" [disabled]="!active.length">
      <span class="p-3">{{ 'PATIENT_FORMS.ADD_FORMS.ASSIGN_CONFIRM' | translate }}&nbsp;{{ '(' + active.length + ')'}}</span>
    </button>
  </div>
    <div class="row px-2 pt-1 pb-2 modal-header border-bottom">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text prepend-input">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </div>
        </div>
        <label for="form-search"></label>
        <input
          id="form-search"
          type="text"
          class="form-control"
          autocomplete='off'
          placeholder="{{'PATIENT_FORMS.PLACE_HOLDER' | translate:enterpriseName }}"
          [(ngModel)]="filter"/>
        <div class="input-group-append m-0">
          <span class="input-group-text append-input">
            <fa-icon *ngIf='filter' [icon]="['far', 'times-circle']" class="p-0" (click)="clearFilter()"></fa-icon>
            <next-tag-search #tagSearch *ngIf="!filter" [activeTags]='tags' [appendTo]='"modal-container"' (tagSearch)='onTagFilter($event)'></next-tag-search>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="tags" class='py-2'>
      <next-tag-pill [tags]="tags" (remove)="removeActiveTag($event)"></next-tag-pill>
    </div>

    <div class="row px-2 pb-2 list-container">
      <!--NODE TREE VIEW-->
      <ng-container *ngIf='treeMode'>
        <ng-container *ngIf='previous.length > 1'>
          <div class='col-6 text-muted truncate p-0 text-left'>
            <button id='library-back-button' class='ih-btn-primary-o float-left' (click)='openPrevious()'>
              <fa-icon [icon]='["fas", "chevron-left"]' class='mr-2'></fa-icon>
              {{ 'PATIENT_FORMS.ADD_FORMS.BACK' | translate }}
            </button>
          </div>
          <div class='col-6 text-muted truncate p-0 text-right'>
            <button id='library-home-button' class='ih-btn-primary-o' (click)='openRoot()'>
              <fa-icon [icon]='["fas", "home"]' class='mr-2'></fa-icon>
              {{ 'PATIENT_FORMS.ADD_FORMS.HOME' | translate }}
            </button>
          </div>

          <div class='col-12 pl-3 p-0 mt-2'>
            {{ previousLabel[previousLabel.length - 1 ] }}
          </div>
        </ng-container>
        <div *ngIf='!treeExperiences.length' class='container-fluid list-container p-4 text-center text-muted'>
          {{ 'PATIENT_FORMS.ADD_FORMS.NO_CONTENT' | translate }}
        </div>
        <div *ngIf='treeExperiences.length' class='container-fluid list-container column p-0'>
          <div *ngFor="let item of treeExperiences | filter:'name':filter; index as i; let last = last" class="d-flex p-2 justify-content-center w-100 item">

            <!--FOLDER-->
            <button *ngIf="item.type === 'Folder'" id='{{ "btn-folder-container_" + i }}' class="row cursor-pointer hover-item w-100 py-2" role='button' type="button" (click)='openFolder(item)'>
              <span id='{{ "folder_" + i }}' class="col-2 p-0 align-items-center justify-content-center hover-checkbox left-folder"></span>
              <span class="col-8 p-0 item-content-l">
                {{ item.name }}
                <br>
                <fa-icon [icon]="['fas', 'folder-open']" class="mx-1"></fa-icon>
                {{ 'MANAGE_FORMS.CATEGORY_LABEL' | translate }}
                <span class='float-right'>
                  {{ item.children.length + ('MANAGE_FORMS.CATEGORY_COUNT' | translate) }}
                </span>
              </span>
              <span class="col-2 text-right align-self-center">
                <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
              </span>
            </button>

            <!--EXPERIENCE-->
            <div *ngIf="item.type === 'Form'" class="row cursor-pointer hover-item w-100 py-2" [ngClass]="{ 'active-item' : isSelected(item), 'mb-1' : last }">
              <div id='{{ "checkbox-exp-list_" + i }}'
               class="col-2 p-0 align-items-center justify-content-center hover-checkbox"
               role='button'
               tabindex='0'
               (keyup.enter)='selectExperienceCheckbox(item, false)'
               (keyup.space)='selectExperienceCheckbox(item, false)'
               (click)='selectExperienceCheckbox(item, true)'>
                <fa-icon [ngClass]="checkboxSelect ? 'visible' : ''" [icon]="isSelected(item) ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
              </div>
              <div id='{{ "button-right-exp-list_" + i }}'
                class="col-9 p-0 item-content-l"
                role='button'
                tabindex='0'
                (keyup.enter)='selectExperienceButton(item, false)'
                (keyup.space)='selectExperienceButton(item, false)'
                (click)='selectExperienceButton(item, true)'>
                {{ item.name }}
                <br>
                <fa-icon [icon]="['fas', 'file-alt']" class='mx-1'></fa-icon>
                {{ 'MANAGE_FORMS.FORM_LABEL' | translate:formLabel }}
              </div>
            </div>

            <!--PACKET-->
            <div *ngIf="item.type === 'Packet'" class="row cursor-pointer hover-item w-100 py-2" [ngClass]="{ 'active-item' : isSelected(item), 'mb-1' : last }">
              <div id='{{ "checkbox-packet-list_" + i }}'
                   class="col-2 p-0 align-items-center justify-content-center hover-checkbox"
                   role='button'
                   tabindex='0'
                   (keyup.enter)='selectExperienceCheckbox(item, false)'
                   (keyup.space)='selectExperienceCheckbox(item, false)'
                   (click)='selectExperienceCheckbox(item, true)'>
                <fa-icon [ngClass]="checkboxSelect ? 'visible' : '' " [icon]="isSelected(item) ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
              </div>
              <div id='{{ "button-packet-list_" + i }}'
                   class='col-10 p-0 item-content-l'
                   role='button'
                   tabindex='0'
                   (keyup.enter)='selectExperienceButton(item, false)'
                   (keyup.space)='selectExperienceButton(item, false)'
                   (click)='selectExperienceButton(item, true)'>
                {{ item.name }}
                <br>
                <fa-icon [icon]="['fas', 'copy']" class='mx-1'></fa-icon>
                {{ 'MANAGE_FORMS.PACKET_LABEL' | translate }}
              </div>
            </div>
            <br>
          </div>
        </div>
      </ng-container>


      <!--FLAT LIST VIEW-->
      <div *ngIf='listExperiences.length && !treeExperiences.length' class='container-fluid list-container column p-0'>
        <ng-container *ngFor="let item of listExperiences | sortBy: 'ASC':'name' | filter:'name':filter; index as i; let last = last">
          <div class='d-flex p-2 justify-content-center w-100 item'>
            <button id='{{ "btn-form-flat-container_" + i }}' class='row cursor-pointer hover-item w-100 py-2' role='button' [ngClass]="{ 'active-item' : isSelected(item), 'mb-1' : last }">
              <span id='{{ "checkbox-form-flat-list_" + i }}'
                class="col-2 p-0 align-items-center justify-content-center hover-checkbox"
                role='button'
                tabindex='0'
                (keyup.enter)='selectExperienceCheckbox(item, false)'
                (keyup.space)='selectExperienceCheckbox(item, false)'
                (click)='selectExperienceCheckbox(item, true)'>
                <fa-icon *ngIf="!checkboxSelect" [icon]="!isSelected(item) ? ['far', 'circle'] : ['fas', 'check-circle']"></fa-icon>
                <fa-icon *ngIf="checkboxSelect" class='visible' [icon]="isSelected(item) ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
              </span>
              <span id='{{ "button-right-form-flat-list_" + i }}'
                class="col-9 p-0 item-content-l"
                role='button'
                tabindex='0'
                (keyup.enter)='selectExperienceButton(item, false)'
                (keyup.space)='selectExperienceButton(item, false)'
                (click)='selectExperienceButton(item, true)'>
                {{ item.name }}
                <br>
                <ng-container *ngIf='item.type && item.type === "Packet"'>
                  <fa-icon [icon]="['fas', 'copy']" size='lg' class='mr-2'></fa-icon>{{ 'MANAGE_FORMS.PACKET_LABEL' | translate }}
                </ng-container>
                <ng-container *ngIf='!item.type || item.type !== "Packet"'>
                  <fa-icon [icon]="['fas', 'file-alt']" size='lg' class='mr-2'></fa-icon>{{ 'MANAGE_FORMS.FORM_LABEL' | translate:formLabel }}
                </ng-container>
                </span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>


