<div class="d-flex">
  <div class="p-2 ml-auto">
    <button type="button" class="rounded-pill shadow-sm mr-2" (click)="shareForm()" [disabled]="shareDisabled" [ngClass]="{ 'btn-enabled': !shareDisabled, 'btn' : shareDisabled  }">{{ 'PATIENT_FORMS.ADD_FORMS.SHARE' | translate }}</button>
    <button type="button" class="rounded-pill shadow-sm mr-2" (click)="commandPrint()" [disabled]="printDisabled" [ngClass]="{ 'btn-enabled': !printDisabled, 'btn' : printDisabled  }">{{ 'PATIENT_FORMS.ADD_FORMS.PRINT' | translate }}</button>
    <button type="button" class="rounded-pill shadow-sm mr-2" (click)="fillOutForm()" [disabled]="fillOutDisabled" [ngClass]="{ 'btn-enabled': !fillOutDisabled, 'btn' : fillOutDisabled  }">{{ 'PATIENT_FORMS.ADD_FORMS.FILL_OUT' | translate }}</button>
    <button class="d-inline-block p-0 add-form-btn" id="add-form"  (click)="showAddForm()">
      <fa-icon [icon]="['fas', 'plus']" style="font-size: 1rem;"></fa-icon><span style="font-size: 1rem;">&nbsp;{{ 'PATIENT_FORMS.ADD_FORMS.ADD_FORM' | translate }}</span>
    </button>
  </div>
</div>

<div class='height-remaining'>
  <p-table
    [selectionMode]="'multiple'"
    [value]="tasks"
    [columns]="columnHeaders"
    [rowHover]="true"
    [scrollable]="true"
    [scrollHeight]="'flex'"
    [dataKey]="'id'"
    [(selection)]='selectedRows'
    (selectionChange)='setHeaderButtonsDisabled(!$event.length)'>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class='min-btn-width' id="header-checkbox">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns" [id]="col.field" class='text-overflow'>
          <div class="d-flex justify-content-between align-items-center">
            {{ col.header }}
            <p-columnFilter type="text" [field]="col.field" display="menu"></p-columnFilter>
          </div>
        </th>
        <th id="additional">
          {{ 'PATIENT_FORMS.COLUMN_HEADERS.ADDITIONAL' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
      <ng-container >
        <tr>
          <td class='min-btn-width'>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" pEditableColumn class='text-overflow align-middle'>
            <ng-container *ngIf="col.field !== 'issuedon' && col.field !== 'lastarchivedon' && col.field !== 'formType'">
              {{col.field === 'status' ? formatStatus(rowData[col.field]): rowData[col.field]}}
            </ng-container>
            <ng-container *ngIf="col.field === 'lastarchivedon'">
              {{ rowData["lastarchivedon"] | date:'M/d/yy h:mm a'}}
            </ng-container>
            <ng-container *ngIf="col.field === 'issuedon'">
              {{(rowData["completedon"] | date:'M/d/yy h:mm a') || rowData["issuedon"] | date:'M/d/yy h:mm a'}}
            </ng-container>
            <ng-container *ngIf="col.field === 'formtype'">
              IH Platform
            </ng-container>
          </td>
          <td>
            <button class="d-inline-block p-0 options" (click)="ellipsisClick(rowData) || menu.toggle($event)">
              <fa-icon [icon]="['fas', 'ellipsis-h']" style="font-size: 2rem;"></fa-icon>
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
</div>

<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
