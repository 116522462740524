<div class="float-right d-flex">
<button *ngIf="isDefault"  class="btn ih-btn-primary-o d-inline-block py-0 my-0">
    <fa-icon [icon]="['far', 'heart']" class="p-0 fa-icon" size="lg" (click)="onGridSettingModal()"></fa-icon>
  </button>
  <button *ngIf="!isDefault" class="btn ih-btn-primary-o d-inline-block py-0 my-0">
    <fa-icon [icon]="['fas', 'heart']" class="p-0 fa-icon" size="lg" (click)="onGridSettingModal()"></fa-icon>
  </button>
  <ng-select id="select-saved-grid-view" class='d-inline-block w-75 py-0 my-0' (change)="applySavedSettings($event)"
    [(ngModel)]="selectedMySavedGridView" 
    [items]="HardCodeColumn" 
    bindValue="id" 
    bindLabel="name"></ng-select>
  <button class="btn ih-btn-primary-o d-inline-block py-0 my-0">
    <fa-icon [icon]="['fas', 'cog']" class="p-0 fa-icon" size="lg" (click)="onGridColumnModal()"></fa-icon>
  </button>
</div>