import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FieldDTO, Popup, DisplayStatus } from '@next/shared/common';
import { FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
  animations: [
    trigger('subFieldIn', [
      state(DisplayStatus.CLOSED, style({ transform: 'translateY(0)'})),
      state(DisplayStatus.OPENED, style({ transform: 'translateY(-100%)' })),
      transition('* => *', animate('800ms ease-in-out'))
    ])
  ]
})

export class PopupComponent extends FieldBaseComponent implements OnInit, AfterViewInit {

  @Input() field: Popup;
  @Input() form: FormGroup;
  @Input() initialState: any;
  @Input() needsValidation: boolean;

  private dynamicChildren: FieldDTO[];

  display: string = DisplayStatus.CLOSED;
  isValid = false;
  requiresValidation = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.dynamicChildren = this.getAllSubFields(this.field.popupField.fields);
    this.dynamicChildren.forEach(dynamicChild => {
      this.setTempValueControlSwitching(dynamicChild.name);
    });
    this.setupInitialForm(this.initialState, this.dynamicChildren);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.processPopup());
  }

  openPopup() {
    this.display = DisplayStatus.OPENED;
  }

  closePopup() {
    this.processPopup();
    this.updateStatus(this.isValid ? 'Valid' : 'Invalid');
    this.display = DisplayStatus.CLOSED;
  }

  isOpened() {
      return this.display === DisplayStatus.OPENED;
  }

  private processPopup(): void {
    this.getRequiresValidation(this.dynamicChildren);
    if (this.requiresValidation) {
      this.validatePopup(this.dynamicChildren);
    }
  }

  private getRequiresValidation(fields: any[]) {
    if (this.field.popupField.validateOneField) {
      this.requiresValidation = true;
    }
    else {
      this.requiresValidation = fields.some(f => f.required) || fields.some(f => this.form.contains(f.name) && this.form.get(f.name).invalid);
    }
  }

  private validatePopup(fields: any[]) {
    if (this.field.popupField.validateOneField) {
      this.isValid = this.hasAtLeastOneValidField(fields);
    }
    else {
      this.isValid = !(fields.some(f => this.form.get(f.name) && this.form.get(f.name).invalid) || (fields.some(f => !this.form.contains(f.name) && f.required)));
    }
  }
}
