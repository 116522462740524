import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiService, FormFastConfig, FormFastConfigService, Overlay, OverlayTemplate, TokenService } from "@next/shared/common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class OverlayService extends ApiService {

    constructor(@Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService, private http: HttpClient) { 
        super(config, tokenService); 
    }

    getOverlays(): Observable<Overlay[]> {
        return this.protectedEndpoint<Overlay[]>(
          this.http.get<Overlay[]>(`${this.config.apiUrl}overlay/`, this.getHeaders())
    )}

    getOverlay(id: string): Observable<Overlay> {
        return this.protectedEndpoint<Overlay>(
          this.http.get<Overlay>(`${this.config.apiUrl}overlay/${id}`, this.getHeaders())
    )}

    createOverlay(overlay: Overlay): Observable<Overlay> {
        return this.protectedEndpoint<Overlay>(
          this.http.post<Overlay>(`${this.config.apiUrl}overlay/`, overlay, this.getHeaders())
    )}

    updateOverlay(overlay: Overlay): Observable<Overlay> {
        return this.protectedEndpoint<Overlay>(
          this.http.put<Overlay>(`${this.config.apiUrl}overlay/${overlay.id}`, overlay, this.getHeaders())
    )}

    deleteOverlay(id: string): Observable<Overlay> {
        return this.protectedEndpoint<Overlay>(
          this.http.delete<Overlay>(`${this.config.apiUrl}overlay/${id}`, this.getHeaders())
    )}

    getOverlayPdf(overlayTemplate:OverlayTemplate){
      return this.protectedEndpoint<any>(
        this.http.post<any>(`${this.config.apiUrl}overlay/pdftemplate`, overlayTemplate, 
        {
          responseType: 'blob' as 'json',
          headers: this.getHeaders().headers
        }).pipe(map((res: any) => {
          return new Blob([res], { type: 'application/pdf', endings: 'native' });
        }))
      )}
}