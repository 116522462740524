<div class="container">
    <div class="row">
        <div class="card d-flex align-items-center mt-5">
          <div class="col-sm-12 d-flex align-items-center">
            <img class="w-25 mx-auto" src="../../assets/images/interlace.png" alt="interlacehealth_logo" />
          </div>
          <div class="col-sm-12 d-flex align-items-center">
            <h2 class="mx-auto">Forbidden</h2>
          </div>
          <h5 class="ml-5">{{ 'ERRORS.REDIRECT_ERROR' | translate }}</h5>
        </div>
    </div>
  </div>
  