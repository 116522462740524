import { Component, Input } from '@angular/core';
import { FieldSpacer } from '@next/shared/common';

@Component({
  selector: 'next-field-spacer',
  templateUrl: './field-spacer.component.html',
  styleUrls: ['./field-spacer.component.css']
})
export class FieldSpacerComponent {
  @Input() field: FieldSpacer;

}
