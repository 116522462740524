import { Inject, Injectable } from '@angular/core';
import {
    ApiService, AssignedSignature, FormFastConfig, FormFastConfigService, Signature, TokenService
  } from '@next/shared/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
  })
  export class SignatureService extends ApiService {

    constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
        super(config, tokenSvc);
      }

    upsertSignature(signature): Observable<Signature> {
        return this.protectedEndpoint<Signature>(
          this.http.post<Signature>(`${this.config.apiUrl}signature`, signature ,this.getHeaders())
        );
    }

    getAssignedSignatures(experienceversionid, formid): Observable<AssignedSignature[]> {
        return this.protectedEndpoint<AssignedSignature[]>(
            this.http.get<AssignedSignature[]>(`${this.config.apiUrl}signature/experienceversion/${experienceversionid}/form/${formid}` ,this.getHeaders())
          );
    }
  }