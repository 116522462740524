import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { FilterPipe } from './pipes/filter.pipe';
import { DisplayHtmlPipe } from './pipes/display-html.pipe';
import { FilterBoxComponent } from './components/filter-box/filter-box.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { FormSwitchComponent } from './components/form-switch/form-switch.component';
import { SecurePipe } from './pipes/secure.pipe';
import { PdfPageComponent } from './components/pdf-page/pdf-page.component';
import { PdfCropToolComponent } from './components/pdf-crop-tool/pdf-crop-tool.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SortByPipe } from './pipes/sortby.pipe';
import { LogoutComponent } from './components/logout/logout.component';
import { GenerateComponentDirective } from './directives/generate-component.directive';
import { SvgEmbeddedComponent } from './components/svg-embedded/svg-embedded.component';
import { ReplaceLineBreaksPipe } from './pipes/replaceLineBreaks.pipe';
import { PdfRendererComponent } from './components/pdf-crop-tool/pdf-renderer/pdf-renderer.component';

const components = [
  FilterPipe,
  DisplayHtmlPipe,
  SecurePipe,
  SortByPipe,
  ReplaceLineBreaksPipe,
  ConfirmationDialogComponent,
  FilterBoxComponent,
  FormSwitchComponent,
  LogoutComponent,
  UploadDialogComponent,
  PdfPageComponent,
  PdfCropToolComponent,
  PdfRendererComponent,
  SvgEmbeddedComponent
];

const directives = [
  DropZoneDirective,
  GenerateComponentDirective
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  exports: [
    ...components,
    ...directives,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    UploadDialogComponent,
    PdfCropToolComponent,
    PdfPageComponent
  ]
})
export class SharedUiModule {}
