import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { FormFastConfigService } from '@next/shared/common';

fetch(environment.baseHref + 'assets/config/config.json')
  .then((response) => response.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic(
      [{ provide: FormFastConfigService, useValue: config }]
    )
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err))
  });
