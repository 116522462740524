import { Component, Input } from '@angular/core';
import { TextLabel } from '@next/shared/common';

@Component({
  selector: 'next-text-label',
  templateUrl: './text-label.component.html',
  styleUrls: ['./text-label.component.css']
})
export class TextLabelComponent {

  @Input() field: TextLabel;

}
