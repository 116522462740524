<div class="container-fluid pdf-viewer-container ml-0">
  <ng-template nextGenerateComponent></ng-template>
</div>

<!-- footer -->
<div class="container-fluid footer-section">
    <div class="d-flex flex-row align-items-center w-100"> 
      <div class="next-form-btn p-1">
        <button class="btn btn-primary p-0 w-100" (click)="closeViewer()" >
          {{ "FORM_VIEWER.VIEW_MODE.CLOSE_BUTTON" | translate }}
        </button>
      </div>
      <div class="next-page-section d-flex flex-row justify-content-center align-items-center">
        
        <div class="container-fluid text-left px-0">
          <button class="btn-previous pl-3" (click)="previousPage()" [disabled]="(currentPage === 1) || loading">
            <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>&nbsp;&nbsp;
            {{ "FORM_VIEWER.FOOTER.PREV_PAGE" | translate }}
          </button>
        </div>
        <div class="container-fluid text-center p-2">
          <span *ngIf="!loading">
            {{ "FORM_VIEWER.PAGES" | translate:pages }}
          </span>
          <span *ngIf="loading">
            <fa-icon [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon>
          </span>
          <br/>
        </div>
        <div class="container-fluid text-right px-0">
          <button class="btn-previous pr-3" (click)="nextPage()" [disabled]="currentPage === totalPages || loading">
            {{ "FORM_VIEWER.FOOTER.NEXT_PAGE" | translate }}
            &nbsp;&nbsp;<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
          </button>
        </div>
      </div>
      <div class="next-form-btn p-1">
        <button class="btn btn-primary p-0 w-100" (click)="nextForm()" [disabled]="isSaveDisabled()">
          <span *ngIf="!loading">
            {{ this.getFinishButtonLabel() }}
          </span>
          <span *ngIf="loading">
            <fa-icon [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon>
          </span>
        </button>
      </div>
    </div>
</div>

<ng-template #viewModePrompt>
  <div class='p-2 text-center'>
    <h4 class='font-weight-bold pt-2'>{{ "FORM_VIEWER.VIEW_MODE.TITLE" | translate }}</h4>
    <div class='pt-3'>
      {{ "FORM_VIEWER.VIEW_MODE.CONTENT" | translate }}
    </div>
    <div class='pt-3'>
      <div class='d-flex justify-content-center'>
        <button role='button' type='reset' id='cancel' class='btn-ih px-1' (click)="exitViewModeModal()">{{ "FORM_VIEWER.VIEW_MODE.CLOSE_BUTTON" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>
