import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  Tag, TagJunction,
  TokenService
} from '@next/shared/common';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class TagService extends ApiService {

  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenSvc: TokenService,
    private http: HttpClient
  ) { super(config, tokenSvc); }


  getAllTags(): Observable<Tag[]> {
    return this.protectedEndpoint(
      this.http.get<Tag[]>(`${this.config.apiUrl}tag/`, this.getHeaders())
    );
  }

  getAllActiveTags(elementid): Observable<Tag[]> {
    return this.protectedEndpoint(
      this.http.get<Tag[]>(`${this.config.apiUrl}tag/${elementid}/active`, this.getHeaders())
    );
  }

  getAllWithActiveTagsIndicatedMultiple(elementids: string[]): Observable<any[]> {
    return this.protectedEndpoint(
      this.http.post<Tag[]>(`${this.config.apiUrl}tag/getall`, elementids, this.getHeaders())
    );
  }

  getTag(id: string): Observable<Tag> {
    return this.protectedEndpoint(
      this.http.get<Tag>(`${this.config.apiUrl}tag/${id}`, this.getHeaders())
    );
  }

  createTag(body): Observable<Tag> {
    return this.protectedEndpoint(
      this.http.post<any>(`${this.config.apiUrl}tag/`, body, this.getHeaders())
    );
  }

  deleteTag(id: string) {
    return this.protectedEndpoint(
      this.http.delete<void>(`${this.config.apiUrl}tag/${id}`, this.getHeaders())
    );
  }

  editTag(id, body) {
    return this.protectedEndpoint(
      this.http.put<void>(`${this.config.apiUrl}tag/${id}`, body, this.getHeaders())
    );
  }

  applyTag(body) {
    return this.protectedEndpoint(
      this.http.put<void>(`${this.config.apiUrl}tag/elementref`, body, this.getHeaders())
    );
  }

  getTagToElement(id: string): Observable<TagJunction[]> {
    return this.protectedEndpoint(
      this.http.get<any>(`${this.config.apiUrl}tag/${id}/elementref`, this.getHeaders())
    );
  }

  async getFormTags(experienceId: string): Promise<string> {
    let tags: Tag[] = await this.getAllActiveTags(experienceId).toPromise();
    tags = tags.filter(tag => tag.active && tag.active !== "0");
    return tags.map(o => o.tag).join(', ');
  }
}
