<div class='container-fluid h-100 '>
  <div class="row modal-header bg-light" >
    <div class="col-4 float-left cancel" (click)='cancelClick()' role="button" >
      {{ 'PATIENT_FORMS.SELECT_APPOINTMENT.CANCEL' | translate }}
    </div>
    <div class='col-8 float-left h5'>
    {{ enterpriseAppointmentName }} {{ 'PATIENT_FORMS.SELECT_APPOINTMENT.FOUND' | translate }}
    </div>
    <div class='row w-100 pl-5'>
      <span class='justify-content-center pl-5' style="font-size: 17px">
     {{message}}
      </span>
    </div>
  </div>

  <div class="appointment-row">
    <div class="container-fluid appointment-container" [ngStyle]= "{'overflow-y': appointments?.length > 3 ? 'scroll' : 'auto' }">
      <div *ngFor="let appointment of appointments"  class="item p-1">
        <div class="row  w-100 m-1 btn">
          <div class="col-12 py-2 px-0 text-left overflow-hidden btn-add cursor-pointer font-styling "
               role='button'
               (click)='appointmentIsSelected(appointment)'>
               <div class="row">
                <div class="col-12">{{ "APPOINTMENT_SELECT_MODAL.ACCOUNT_NUMBER" | translate  }}: {{appointment.accountnumber}}</div>
               </div>
              <div class="row">
                <div class="col-6">{{ "APPOINTMENT_SELECT_MODAL.DATE" | translate  }}: {{ appointment.starttime | date: 'MM/dd/yyyy' }}</div>
                <div class="col-6">{{ "APPOINTMENT_SELECT_MODAL.LOCATION" | translate  }}: {{appointment.location}}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ "APPOINTMENT_SELECT_MODAL.STATUS" | translate  }}: {{appointment.formstatus | titlecase}}</div>
                <div class="col-6">{{ "APPOINTMENT_SELECT_MODAL.HOSPITAL_SERVICE" | translate  }}: {{appointment.hospitalservice}}</div>
              </div>
              
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

