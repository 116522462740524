import { Component, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'next-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

  @Input() title: Observable<string>;
  @Input() message: Observable<string>;
  @Input() cancelButton: Observable<string>;
  @Input() confirmButton: Observable<string>;

  onClose: Subject<boolean> = new Subject<boolean>();
  constructor(public modalRef: BsModalRef) { }

  confirm(): void {
    this.onClose.next(true);
    this.modalRef.hide();
  }

  decline(): void {
    this.onClose.next(false);
    this.modalRef.hide();
  }
}
