import {Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateViewerService } from '../state/state-viewer.service';
import { NextAdminService } from '@next/shared/next-services';
import { FormControl, FormGroup } from "@angular/forms";
import { Subject } from 'rxjs';
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: 'next-access-code-prompt',
  templateUrl: './access-code-prompt.component.html',
  styleUrls: ['./access-code-prompt.component.scss']
})
export class AccessCodePromptComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('codeValue') codeValue: ElementRef;
  cleanup: Subject<void> = new Subject<void>();

  userId = '';
  attempts = 0;
  display = false;
  invalidAccessCode = false;

  accessCode: FormGroup = new FormGroup({
    ['code']: new FormControl('', { updateOn: 'change'})
  });

  // Access Code Masking Properties
  hideCode = true;
  masking = 'ZZZZZZZZ';
  pattern: any = {
    'Z' : { pattern : /\w/, symbol : '•' },
    'Y' : { pattern : /\w/ }
  };

  constructor(
    private stateViewerSvc: StateViewerService,
    private router: Router,
    private route: ActivatedRoute,
    private adminSvc: NextAdminService
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(
      takeUntil(this.cleanup),
      tap(data => {
        this.userId = data.user.oid;
      })
    ).subscribe();

    this.stateViewerSvc.promptAccessCode$.pipe(
      takeUntil(this.cleanup),
      tap(showPrompt => {
        this.display = showPrompt;
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.accessCode.valueChanges.subscribe(value => {
      setTimeout(() => {
        if (!value.code) this.invalidAccessCode = false;
        this.masking = 'Z'.repeat(Math.max(value.code.length - 1, 0)) + 'YY';
      });
    });
  }

  ngOnDestroy(): void {
    this.closeDisplay();

    this.cleanup.next();
    this.cleanup.complete();
  }

  validateCode(): void {
    const codeValue = this.accessCode.value.code;
    this.attempts += 1;
    if (this.attempts >= 10) {
      sessionStorage.clear();
      this.router.navigate(['logout/auto'], {
        queryParams: {
          currentUrl: window.location.pathname,
          logoutInactivity: true,
          isMainComponent: false
        }
      });
      return;
    }

    this.adminSvc.getPreference(this.userId, 'ACCESSCODE').subscribe(res => {
      if (codeValue === res[0].data.ACCESSCODE) {
        this.stateViewerSvc.lockedMode = false;
        this.stateViewerSvc.promptAccessCode = false;
        this.stateViewerSvc.isPatientView.next(false);
        this.closeDisplay();
      }
      else {
        this.invalidAccessCode = true;
      }
    });
  }

  toggleHiddenCode(): void {
    this.hideCode = !this.hideCode;
    this.masking = 'ZZZZZZZZ';
    if (this.hideCode) {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : '•' },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
    else {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : null },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
  }

  tryAgain(): void {
    this.invalidAccessCode = false;
  }

  closeDisplay(): void {
    this.display = false;
    this.attempts = 0;
    this.display = false;
    this.invalidAccessCode = false;
    this.accessCode.patchValue({['code']: ''}, {
      emitEvent: false,
      onlySelf: true
    });
    this.hideCode = true;
    this.masking = 'ZZZZZZZZ';
  }
}
