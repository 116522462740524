import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { FormFastConfig, FormFastConfigService, TokenService } from '@next/shared/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(@Inject(FormFastConfigService) private config: FormFastConfig, private tokenService: TokenService) { }

  // allow activation if token is set, or if the TokenService already has a token.  If there is a token in the url, update the TokenService
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.tokenService.hasAccessToken()) {
      return true;
    } else if (!this.config.production) { // ignore for development environments
      return true;
    }

    this.notAuthorizedRedirect();
    return false;
  }

  // don't allow any lazy loading of modules if there is no token.
  canLoad(route: Route): boolean {
    if (this.tokenService.hasAccessToken()) {
      return true;
    } else if (!this.config.production) { // ignore for development environments
      return true;
    }

    this.notAuthorizedRedirect();
    return false;
  }

  notAuthorizedRedirect() {
    // redirect to fastflow
    // window.location.href = environment.baseHref + '../';
  }
}
