import { Inject, Injectable } from '@angular/core';
import {
  ApiService, FormFastConfig, FormFastConfigService, TokenService
} from '@next/shared/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class NextHl7Service extends ApiService {

  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService,
    private http: HttpClient)
  {
    super(config, tokenService);
  }

  /**
   * POST hl7 message to msvc-hl7 api, returns ack string
   *
   * @param  {Object} messageObject - Message wrapper object
   * @param {string} messageObject.message - The Hl7 Message
   */
  sendMessage(messageObject: { message: string }): Observable<string> {
    return this.protectedEndpoint<string>(
      this.http.post<string>(`${this.config.apiUrl}hl7/`, messageObject, this.getHeaders())
    );
  }

}
