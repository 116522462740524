import { Component } from '@angular/core';
import { TextboxComponent } from '../../textbox.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateFormat } from '@next/shared/common';

@Component({
  selector: 'next-date',
  templateUrl: './date.component.html',
  styleUrls: ['./../../textbox.component.css'],
})
export class DateComponent extends TextboxComponent {

  datePickerValue: any;
  displayDate: string;
  pickerOpened: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateMask: string = 'M0/d0/0000';

  constructor(currencyPipe: CurrencyPipe, datePipe: DatePipe) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dateMask = this.field.dateFormat === DateFormat.MMDDYYYY ? 'M0/d0/0000' : 'd0/M0/0000';
    this.datePickerConfig = {
      ...{
        minDate: this.field.pastDates ? new Date(1900, 1, 1) : new Date(),
        maxDate: this.field.futureDates ? null : new Date(),
        showWeekNumbers: false,
        dateInputFormat: this.field.dateFormat
      }
    };

    try {
      this.displayDate = this.datePipe.transform(this.valueFormGroup.controls.Text.value, this.field.dateFormat);
    }
    catch {
      this.displayDate = null;
      this.valueFormGroup.controls.Text.setErrors({ isInvalid : true })
    }
    this.valueFormGroup.controls.Text.valueChanges.subscribe((value) => {
      this.displayDate = this.datePipe.transform(value, this.field.dateFormat) ?? '';
    });
  }

  datePickerSetDate(date: Date) {
    if (date && date instanceof Date && !isNaN(date.getDate())) {
      date.setHours(0, 0, 0, 0);
      this.valueFormGroup.controls.Text.patchValue(date.toISOString().split('T')[0]);
      if (this.pickerOpened) {
        this.valueFormGroup.controls.Text.markAsDirty();
      }
    }
  }

  textInputSetDate(e: Event): void {
    let date: Date;
    const data: string[] = (e.target as HTMLInputElement).value.split('/');

    if (this.field.dateFormat === DateFormat.MMDDYYYY) {
      date = new Date(+data[2], +data[0] - 1, +data[1]);
    }
    if (this.field.dateFormat === DateFormat.DDMMYYYY) {
      date = new Date(+data[2], +data[1] - 1, +data[0]);
    }
    date.setHours(0,0,0,0);

    /* If valid date from text, set datePicker value, which will then
     * patch the value to the form control */
    if (date && date instanceof Date && !isNaN(date.getDate())) {
      this.datePickerValue = date;
    }
    else {
      /* If invalid date, patch string literal and expect invalid errors */
      this.valueFormGroup.patchValue( {['Text']: (e.target as HTMLInputElement).value });
    }
  }
}
