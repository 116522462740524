import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PatientService } from '@next/shared/next-services';
import { Appointment, AppointmentStatus, Patient } from '@next/shared/common';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'next-select-appointment-modal',
  templateUrl: './select-appointment-modal.component.html',
  styleUrls: ['./select-appointment-modal.component.scss']
})
export class SelectAppointmentComponent implements OnInit {
  @Input() personId: any;
  @Input() message: string;
  @Input() patientName: string;
  @Input() enterpriseAppointmentName: string; 

  @Output() appointmentSelected: EventEmitter<Appointment> = new EventEmitter<Appointment>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  ApptStatus: typeof AppointmentStatus = AppointmentStatus;
  appointments: Array<Appointment>;
  patient: Patient

  constructor(
    private modalSvc: BsModalService,
    private patientSvc: PatientService,
    private toastSvc: ToastrService) { }

  ngOnInit(): void {
      this.patientSvc.getPatientAppointments(this.personId).subscribe(
          appointments=>{
            this.appointments = appointments;
          },
          err=>{
            this.toastSvc.error(err);
          });
  }

  cancelClick(): void {
    this.cancel.emit();
  }

  appointmentIsSelected(appointment: Appointment) {
    this.appointmentSelected.emit(appointment);
  }
}
