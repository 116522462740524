import { Component, Input } from '@angular/core';

@Component({
  selector: 'next-processes-tab',
  templateUrl: './processes-tab.component.html',
  styleUrls: ['./processes-tab.component.scss']
})
export class ProcessesTabComponent {

  @Input() workflows: any;
  @Input() patientId: string;
  
  

}
