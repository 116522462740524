export interface PermissionSet {
  id: string,
  name: string,
  description: string
}
export interface Permission {
  id: string,
  name: string,
  description: string,
  category: string
}
export interface PermissionToPermissionSet {
  permisionid: string,
  permissionsetid: string
}

export enum PermissionCategory {
  uiRoles = 'ui',
  apiRoles = 'api'
}

export enum IhPermissions {
  STANDARD_USER = 'STANDARD_USER',
  DESIGNER = 'DESIGNER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SYSTEM_ADMINISTRATOR = 'SYSTEM_ADMINISTRATOR',
  PATIENT = 'PATIENT'
}
