import { Injectable } from '@angular/core';
import { FormSubmission, GuidedExperienceDTO, Patient, TaskDTO } from '@next/shared/common';
import { BehaviorSubject, Observable } from 'rxjs';

export interface RenderedForm {
  formData: any,
  experience: GuidedExperienceDTO,
  submission: FormSubmission,
  currentTask: TaskDTO
}

@Injectable({
  providedIn: 'root'
})
export class StateViewerService {

  public readonly _selectedTasks = new BehaviorSubject<TaskDTO[]>([]);
  readonly selectedTasks$ = this._selectedTasks.asObservable();

  public selectedTaskCompleted = new BehaviorSubject(false);

  set selectedTasks(val: TaskDTO[]) {
    this._selectedTasks.next(val);
  }

  get selectedTasks(): TaskDTO[] {
    return this._selectedTasks.getValue();
  }

  public isPatientView = new BehaviorSubject(false);

  public RequiredClinicianSignatures = new BehaviorSubject([]);

  public readonly _formIndex = new BehaviorSubject(-1);
  readonly formIndex$ = this._formIndex.asObservable();

  set formIndex(val: any) {
    this._formIndex.next(val);
  }

  get formIndex(): any {
    return this._formIndex.getValue();
  }

  private readonly _patientInfo = new BehaviorSubject<Patient>(null);
  readonly patientInfo$ = this._patientInfo.asObservable();

  set patientInfo(val: Patient) {
    this._patientInfo.next(val);
  }

  get patientInfo(): Patient {
    return this._patientInfo.getValue();
  }

  set lockedMode(val: boolean) {
    val ? sessionStorage.setItem('lockedMode', 'true'): sessionStorage.removeItem('lockedMode') ;
  }

  get lockedMode(): boolean {
    return !!sessionStorage.getItem('lockedMode');
  }

  set viewMode(val: boolean) {
    val ? sessionStorage.setItem('viewMode', 'true'): sessionStorage.removeItem('viewMode') ;
  }

  get viewMode(): boolean {
    return !!sessionStorage.getItem('viewMode');
  }


  private readonly _promptAccessCode = new BehaviorSubject(false);
  readonly promptAccessCode$ = this._promptAccessCode.asObservable();

  set promptAccessCode(val: boolean) {
    this._promptAccessCode.next(val);
  }

  get promptAccessCode(): boolean {
    return this._promptAccessCode.getValue();
  }

  private readonly _formConflict: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly formConflict$: Observable<boolean> = this._formConflict.asObservable();

  set formConflict(val: boolean) {
    this._formConflict.next(val);
  }

  get formConflict(): boolean {
    return this._formConflict.getValue();
  }

  public readonly _renderedForm: BehaviorSubject<RenderedForm> = new BehaviorSubject(null);
  readonly renderedForm$: Observable<RenderedForm> = this._renderedForm.asObservable();

  set renderedForm(val: RenderedForm) {
    this._renderedForm.next(val);
  }

  get renderedForm(): RenderedForm {
    return this._renderedForm.getValue();
  }

  clearViewerState() {
    this.selectedTasks = [];
    this.formIndex = -1;
    this.patientInfo = null;
  }
}
