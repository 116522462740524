<div class="d-block mt-70-px">
  <div class="header">
    <h3 class="name pb-1 mb-0">{{ 'PATIENT_SEARCH.SEARCH_LABEL_TITLE' | translate }}</h3>
  </div>
  <div class="pt-5 count-label d-flex flex-column justify-content-center align-items-center text-center">
    <div class="p-2 d-flex flex-row">
      <div class="rectangle rectangle-left"></div>
      <div class="rectangle rectangle-right"></div>
    </div>
    <div class="p-2 ">
      <span *ngIf="finishedForms"> {{ "DASHBOARD.MAIN.ALL_FORMS_COMPLETE"| translate }} </span>
      <br/>
      {{ "DASHBOARD.MAIN.NO_SELECTION_1"| translate }}
      <br/>
      {{ "DASHBOARD.MAIN.NO_SELECTION_2"| translate }}
    </div>
  </div>
</div>
