import { Component, Input } from '@angular/core';
import { RichTextDTO } from '@next/shared/common';

@Component({
  selector: 'next-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.css']
})
export class RichTextComponent {
  @Input() field: RichTextDTO;
}
