export interface Overlay {
    id: string,
    name: string,
    description: string,
    data: OverlayElement[],
    createdon?: string,
    updatedon?: string
}

export enum OverlayType {
    Text = 'text',
    Barcode = 'barcode',
    Graphic = 'graphic'
}

export enum OverlayFont {
    Courier = 'Courier',
    TimesNewRoman = 'TimesNewRoman',
    Helvetica = 'Helvetica'
}

export enum OverlayAlignment {
    Left = 'left',
    Right = 'right',
    Centered = 'centered'
}

export interface OverlayRect {
    x: number,
    y: number, 
    width: number,
    height: number
}

export interface OverlayValue {
    alignment: string,
    font: string,
    value: string
}

export interface OverlayElement {
    type: string,
    rectangle: OverlayRect,
    value: OverlayValue,
    format?: string
}