import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
  ExternalConnection
} from '@next/shared/common';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class NextExternalConnectionService extends ApiService {
  constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
    super(config, tokenSvc);
  }

  getExternalConnectionByProvider(provder: string): Observable<ExternalConnection[]> {
    return this.protectedEndpoint<ExternalConnection[]>(
      this.http.get<ExternalConnection[]>(`${this.config.apiUrl}external/connection/type/${provder.toLocaleLowerCase()}/connection`, this.getHeaders())
    );
  }
  getAllExternalConnections(): Observable<ExternalConnection[]> {
    return this.protectedEndpoint<ExternalConnection[]>(
      this.http.get<ExternalConnection[]>(`${this.config.apiUrl}external/connection`, this.getHeaders())
    );
  }

  getExternalConnectionByType(type: string): Observable<ExternalConnection[]> {
    return this.protectedEndpoint<ExternalConnection[]>(
      this.http.get<ExternalConnection[]>(`${this.config.apiUrl}external/connection/type/${type.toLocaleLowerCase()}/connection`, this.getHeaders())
    );
  }

  getExternalConnectionById(id: string): Observable<ExternalConnection> {
    return this.protectedEndpoint<ExternalConnection>(
      this.http.get<ExternalConnection>(`${this.config.apiUrl}external/connection/${id}`,  this.getHeaders())
    );
  }

  postExternalConnection(data: ExternalConnection): Observable<ExternalConnection> {
    return this.protectedEndpoint<ExternalConnection>(
      this.http.post<ExternalConnection>(`${this.config.apiUrl}external/connection`, data, this.getHeaders())
    );
  }

  putExternalConnection(id: string, data: any): Observable<ExternalConnection> {
    return this.protectedEndpoint<ExternalConnection>(
      this.http.put<ExternalConnection>(`${this.config.apiUrl}external/connection/${id}`, data, this.getHeaders())
    );
  }

  deleteExternalConnection(id: string): Observable<ExternalConnection> {
    return this.protectedEndpoint<ExternalConnection>(
      this.http.delete<ExternalConnection>(`${this.config.apiUrl}external/connection/${id}`, this.getHeaders())
    );
  }
}
