<div id='iop-signature-prompt'>
  <div *ngIf="!batchSign" class="container-fluid w-100">
    <div class='row bg-light border-bottom py-2 mb-2'>
      <div class='col-4'></div>
      <div class='col-4 d-flex justify-content-center align-self-center'>
        <h4 class='my-0'>{{ 'SIGNATURE_PROMPT.TITLE' | translate }}</h4>
      </div>
      <div class='col-4 d-flex justify-content-end align-items-start'>
        <button
          id='cancel-btn-no-batch'
          type='reset'
          class='btn btn-plain rounded-circle pull-right'
          aria-label='close'
          (click)="exitModal.emit()">
          <fa-icon [icon]='["fas", "times"]' size='lg'></fa-icon>
        </button>
      </div>
    </div>
    <div #container class="modal-body minh-300-px w-100">
      <!--ASSIGN-TO VIEW-->
      <ng-container *ngIf='assignToView'>
        <next-view-assign-to
          [task]='task'
          [users]='users'
          [assignSource]='loggedInUUID'
          (submitAssignTo)='assignToCb($event)'
          (closeAssignTo)='assignToView = false'>
        </next-view-assign-to>
      </ng-container>
      <ng-container *ngIf='!assignToView'>
        <!--ACCESS CODE VIEW-->
        <div [hidden]="showCanvas" class="container-fluid">
          <div class='row'>
            <div class='col-12 py-2'>&nbsp;</div>
          </div>
          <div class="row form-group p-0" [formGroup]='accessCode'>
            <div class="col-12 col-lg-8 my-2">
              <label for="user-select" class="mb-0">
                <h5 class='mb-1'>
                  {{ "SIGNATURE_PROMPT.PERSON_SIGN" | translate}}
                </h5>
              </label>
              <ng-select
                id='user-select'
                aria-label='user-select'
                class='form-control mw-500-px'
                aria-autocomplete='none'
                [searchable]="true"
                [(ngModel)]="signerID"
                [ngModelOptions]='{ standalone : true }'>
                <ng-option *ngFor="let user of users | async" [value]="user.id">
                  {{ user.fullName }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-12 col-lg-8 my-2" [formGroup]="accessCode">
              <label for="access-code-input" class='mb-0'>
                <h5 class='mb-1'>
                  {{ "SIGNATURE_PROMPT.CODE" | translate}}
                </h5>
              </label>
              <div class='input-group mw-500-px'>
                <input
                  #codeElement
                  id="access-code-input"
                  type='text'
                  aria-label="access-code-input"
                  autocomplete='off'
                  aria-autocomplete='none'
                  class="form-control"
                  formControlName="code"
                  (blur)='masking="ZZZZZZZZ"'
                  [autofocus]='true'
                  [mask]='masking'
                  [patterns]='pattern'
                  [hiddenInput]='true'
                  [maxLength]='8'
                  [ngClass]='{"is-invalid":invalidMatch}'
                  (keyup.enter)="enterKeyPressed()"
                  />
                <div class='input-group-append'>
                  <button
                    id='toggle-code-mask'
                    type='button'
                    class='btn btn-outline-dark h-auto'
                    style='min-width: 54px'
                    (click)='toggleHiddenCode()'>
                    <fa-icon [icon]='hideCode ? ["fas", "eye"] : ["fas", "eye-slash"]' size='lg'></fa-icon>
                  </button>
                </div>
                <div class="invalid-feedback">
                  {{ "SIGNATURE_PROMPT.INVALID_MATCH" | translate:attemptsObject }}
                </div>
              </div>
            </div>
          </div>
          <div class="row p-0 mt-4">
            <div class='col-12 d-flex p-0 justify-content-between align-content-center'>
              <button id="cancel-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)="cancel()">
                {{"SIGNATURE_PROMPT.CANCEL" | translate }}
              </button>
              <button id="assign-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)='assignTo()'>
                {{"SIGNATURE_PROMPT.ASSIGN" | translate }}
              </button>
              <button id="sign-no-code-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)="signWithoutCode()">
                {{"SIGNATURE_PROMPT.SIGN_NO_CODE" | translate }}
              </button>
              <button id="sign-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)="sign()" [disabled]="accessCodeDisabled">
                {{"SIGNATURE_PROMPT.SIGN" | translate }}
              </button>
            </div>
          </div>
        </div>
        <!--DRAW CANVAS VIEW-->
        <div [hidden]='!showCanvas' class="container-fluid">
          <div class="row mb-2 justify-content-end">
            <button
              id="btn-clear-signature"
              type='reset'
              class="btn btn-primary col-5 col-lg-3 col-xl-2"
              (click)="clearSignature()"
              [disabled]="!sigValue">
              {{"SIGNATURE_PROMPT.CLEAR_SIG" | translate }}
            </button>
          </div>
          <div class="row mb-2 justify-content-center">
            <canvas #drawCanvas class="border rounded draw-canvas"></canvas>
          </div>
          <div class="row mb-2 justify-content-start">
            <div *ngIf='signatureProperties?.signatureFor !== "patient"' class='col-12 col-lg-6 input-group px-0'>
              <div class='input-group-prepend'>
              <span id='signature-type-name-label' class='input-group-text'>
                {{ "SIGNATURE_PROMPT.TYPE_NAME" | translate }}
              </span>
              </div>
              <input
                id='signature-type-name'
                type='text'
                class='form-control'
                autocomplete='off'
                aria-describedby='signature-type-name-label'
                [(ngModel)]='sigName'>
            </div>
          </div>
          <div class='row justify-content-lg-end justify-content-between'>
            <button
              id="cancel-btn-sig-canvas"
              class="btn btn-plain px-4 mr-2 col-5 col-lg-3 col-xl-2"
              type='reset'
              (click)="cancel()">
              {{ "SIGNATURE_PROMPT.CANCEL" | translate }}
            </button>
            <button
              id="apply-btn-sig-canvas"
              class="btn btn-primary px-4 col-5 col-lg-3 col-xl-2"
              type="submit"
              (click)="applySignature()"
              [disabled]="!sigValue || (!sigName && signatureProperties?.signatureFor !== 'patient')">
              {{ "SIGNATURE_PROMPT.APPLY" | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!--BATCH-SIGN VIEW-->
  <div *ngIf="batchSign">
    <next-view-batch-sign
      [codeFG]='accessCode'
      [invalid]='invalidMatch'
      [accessCodeDisabled]='accessCodeDisabled'
      (submitModal)='sign($event)'
      (exitModal)='exitModal.emit()'>
    </next-view-batch-sign>
  </div>
</div>
